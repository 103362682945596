export const CLIENT_LOADING = 'CLIENT_LOADING';
export const CLIENT_ADS = 'CLIENT_ADS';
export const CLIENT_ADS_PAGINATION = 'CLIENT_ADS_PAGINATION';
export const CLIENT_AD_UPDATE = 'CLIENT_AD_UPDATE';
export const CLIENT_DEALS = 'CLIENT_DEALS';
export const CLIENT_DEALS_PAGINATION = 'CLIENT_DEALS_PAGINATION';
export const CLIENT_DETAILS = 'CLIENT_DETAILS';
export const CLIENT_TURNOVER = 'CLIENT_TURNOVER';
export const CLIENT_LOGOUT_CLEAR = 'CLIENT_LOGOUT_CLEAR';


export const CLIENT_REQUEST = 'CLIENT_REQUEST';
export const CLIENT_SUCCESS = 'CLIENT_SUCCESS';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const DETAILS_SUCCESS = 'DETAILS_SUCCESS';
export const ADS_SUCCESS = 'ADS_SUCCESS';
