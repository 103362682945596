import Vue from 'vue';

import {
  DEAL,
  DEAL_ACTION,
  DEAL_CHAT_GET_MESSAGES,
  DEAL_CHAT_MESSAGE,
  DEAL_CHAT_PLACED,
  DEAL_CREATE,
  DEAL_GET_REVIEWS,
  DEAL_LISTEN,
  DEAL_LOGOUT_CLEAR,
  DEAL_SEND_REVIEW,
  DEAL_SET_REVIEW,
  DEAL_STOP_LISTEN,
  DEAL_UPDATE,
  DEAL_UPDATE_REVIEW,
  DEAL_CHANGES_INC,
  DEAL_CHANGES_RESET,
  DEAL_CLAIMS,
  DEALS_FILTER_SET,
} from '../actions/deal';

import {
  GET_INCOMING_CLAIMS,
  GET_OUTGOING_CLAIMS
} from '../actions/claims';

import {
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT,
} from '../actions/events';

const state = {
  status: '',
  deals: {},
  dealsFilter: {
    is_sale: null,
    is_active: null,
    currency_id: 1,
    fiat_amount: null,
    fiat_amount_from: null,
    fiat_amount_to: null,
    crypto_currency_id: null,
    crypto_amount: null,
    crypto_amount_from: null,
    crypto_amount_to: null,
    requisite_text: null,
    login: null,
    created_from: null,
    created_to: null,
    payment_system_id: null,
    finished: null,
    canceled: null,
    per_page: 15,
  },
  chat: {
    total_pages: 1,
  },
  reviews: {},
  dealClaims: [],
};

const getters = {
  status: state => state.status,
  dealsFilter: state => state.dealsFilter,
  chatPagination: state => state.chat,
  dealReview: state => dealId => dealId && state.reviews[dealId] ? state.reviews[dealId] : {},
  dealInfo: state => dealId => dealId && state.deals[dealId] && state.deals[dealId].info ? state.deals[dealId] : {
    info: false,
    messages: []
  },
  dealChangesCount: state => dealId => dealId && state.deals[dealId] ? state.deals[dealId].changesCount : null,
  dealClaims: state => state.dealClaims,
};

const actions = {
  [DEALS_FILTER_SET]: ({commit}, filters) => {
    _.each(filters, (value, key) => {
      commit(DEALS_FILTER_SET, {key, value});
    });
  },
  [DEAL_LOGOUT_CLEAR]: ({commit}) => {
    commit(DEAL_LOGOUT_CLEAR);
  },
  [DEAL]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_GET, { // запрос на сделку
      method: DEAL_LISTEN,
      params: {id},
      data: {},
    });
  },
  [DEAL_CREATE]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: DEAL_CREATE,
      response: DEAL_LISTEN,
      data,
    });
  },
  [DEAL_CHAT_MESSAGE]: ({commit, dispatch}, {dealId, form}) => {
    return dispatch(HTTP_POST, { // отправка сообщения
      method: DEAL_CHAT_MESSAGE,
      response: false,
      params: {dealId: dealId},
      data: form,
    }).then((msg) => {
      let message = msg;
      if (msg.data) { // TODO: выяснить откуда приходит вдруг msg.data
        message = msg.data;
      }
      commit(DEAL_CHAT_MESSAGE, {message: message, dealId});
      commit(DEAL_UPDATE, {dealId, key: 'chat', value: message.chat});
    });
  },
  [DEAL_CHAT_GET_MESSAGES]: ({commit, dispatch}, {chatId, dealId, pagination}) => {
    return dispatch(HTTP_GET, { // получения истории чата
      method: DEAL_CHAT_GET_MESSAGES,
      response: false,
      params: {id: chatId},
      data: {params: pagination}
    }).then((response) => {
      commit(DEAL_CHAT_GET_MESSAGES, {
        dealId,
        chatId,
        messages: response.data,
        pagination: {
          total_pages: response.pagination.total_pages,
        },
      })
    });
  },
  [DEAL_ACTION]: ({commit, dispatch}, {dealId, action, info, google2fa_secret }) => {
    if (['paid', 'cancel', 'finish', 'dispute', 'manualRequisite', 'requisite'].indexOf(action) >= 0) {
      if (action === 'manualRequisite' || action === 'requisite') {
        return dispatch(HTTP_PUT, {
          method: DEAL_ACTION,
          response: false,
          auto: false,
          data: info,
          params: {action, dealId},
        }).then((response) => {
          commit(DEAL_LISTEN, response.data)
        });
      }
      return dispatch(HTTP_PUT, {
        method: DEAL_ACTION,
        response: false,
        auto: false,
        data: { info, google2fa_secret },
        params: {action, dealId, info},
      }).then((response) => {
        commit(DEAL_LISTEN, response.data);
      });
    }
  },
  [DEAL_GET_REVIEWS]: ({commit, dispatch}) => {
    dispatch(HTTP_GET, {
      method: DEAL_GET_REVIEWS,
      params: {},
      data: {},
      auto: false,
    }).then((response) => {
      _.forEach(response.data, (review) => {
        commit(DEAL_SET_REVIEW, review);
      })
    });
  },
  [DEAL_SEND_REVIEW]: ({commit, dispatch}, {dealId, userId, data}) => {
    let data_body = {
      rate: data.rate,
      trust: data.trust,
    };
    if (!!data.text) {
      data_body['message'] = data.text;
    }
    dispatch(HTTP_PUT, {
      method: DEAL_SEND_REVIEW,
      params: {dealId},
      data: data_body,
      auto: false,
    }).then((response) => {
      commit(DEAL_UPDATE_REVIEW, {dealId, userId, data});
      return Promise.resolve();
    });
  },
  [DEAL_CLAIMS]: async ({ dispatch, commit }, { deal_id }) => {
    const DEFAULT_PER_PAGE = 15;

    const loadPage = (method, { page, per_page }) => dispatch(HTTP_GET, {
      method,
      data: {
        params: {
          deal_id,
          page,
          per_page,
        },
      },
    });

    const loadClaims = async method => {
      const claims = [];
      let responsePagination;

      do {
        const requestPagination = responsePagination ? {
          page: responsePagination.current_page + 1,
          per_page: Number(responsePagination.per_page),
        } : {
          page: 1,
          per_page: DEFAULT_PER_PAGE,
        };

        const result = await loadPage(method, requestPagination);
        claims.push(...result.data);
        responsePagination = result.pagination;
      } while (responsePagination && responsePagination.total_pages > responsePagination.current_page);

      return claims;
    }

    const results = await Promise.all([
      loadClaims(GET_INCOMING_CLAIMS),
      loadClaims(GET_OUTGOING_CLAIMS),
    ]);

    commit(DEAL_CLAIMS, results.flat());
  },
};

const mutations = {
  [DEAL_LOGOUT_CLEAR]: (state) => {
    state.status = '';
    state.deals = {};
    state.chat = {
      total_pages: 1,
    };
    state.reviews = {};
  },
  [DEAL_LISTEN]: (state, info) => {
    if (info && info.id) {
      if (state.deals[info.id]) {
        let deal = _.extend(state.deals[info.id].info, info);
        Vue.set(state.deals[info.id], 'info', deal);
      } else {
        Vue.set(state.deals, info.id, {
          info,
          type: '',
          messages: [],
        });
      }
    }
  },
  [DEAL_STOP_LISTEN]: (state, info) => {
    if (state.deals[info.id]) {
      delete state.deals[info.id];
    }
  },
  [DEAL_CHAT_PLACED]: (state, chat) => {
    if (chat.deal_id && state.deals[chat.deal_id] && state.deals[chat.deal_id].info) {
      Vue.set(state.deals[chat.deal_id].info, 'chat', chat);
    }
  },
  [DEAL_UPDATE]: (state, {dealId, key, value}) => {
    if (state.deals[dealId] && state.deals[dealId].info) {
      Vue.set(state.deals[dealId], key, value);
    }
  },
  [DEAL_CHAT_MESSAGE]: (state, {message, dealId}) => {
    if (state.deals[dealId] && state.deals[dealId].info) {
      if (!_.find(state.deals[dealId].messages, msg => msg.id === message.id)) {
        state.deals[dealId].messages.push(message);
      }
    }
  },
  [DEAL_SET_REVIEW]: (state, data) => {
    if (data && data.author_id) {
      Vue.set(state.reviews, data.recipient_id, data)
    }
  },
  [DEAL_UPDATE_REVIEW]: (state, {dealId, userId, data}) => {
    if (state.reviews[userId]) {
      Vue.set(state.reviews[userId], 'message', data.text)
    } else {
      Vue.set(state.reviews, userId, {
        message: data.text,
        trust: data.trust,
        deal_id: dealId,
        offer: {id: userId},
      })
    }
  },
  [DEAL_CHAT_GET_MESSAGES]: (state, {dealId, messages, pagination}) => {
    if (state.deals[dealId]) {
      _.forEach(messages, (message) => {
        if (!_.find(state.deals[dealId].messages, mess => mess.id === message.id)) {
          state.deals[dealId].messages.push(message);
        }
      });
      Vue.set(state, 'chat', {
        total_pages: pagination.total_pages,
      });
    }
  },
  [DEAL_CHANGES_INC]: (state, {dealId}) => {
    Vue.set(
      state.deals[dealId],
      'changesCount',
      state.deals[dealId].changesCount ? state.deals[dealId].changesCount + 1 : 1
    );
  },
  [DEAL_CHANGES_RESET]: (state, {dealId}) => {
    if (state.deals[dealId]) {
      Vue.set(state.deals[dealId], 'changesCount', null);
    }
  },
  [DEALS_FILTER_SET]: (state, { key, value }) => {
    if (!value && value !== false && value !== 0) {
      if (state.dealsFilter[key] || state.dealsFilter[key] === 0) {
        Vue.delete(state.dealsFilter, key);
      }
    } else {
      Vue.set(state.dealsFilter, key, value);
    }
  },
  [DEAL_CLAIMS](state, claims) {
    Vue.set(state, 'dealClaims', claims);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
