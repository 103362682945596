import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isAuthenticated',
    ]),
  },
  methods: {
    sendAnalytics(category, action, auth = true, metricksList) {
      if (auth) {
        action = this.isAuthenticated ? `signed_${action}` : `unsigned_${action}`;
      }
      this.$store.dispatch("sendAnalytics", {category, action, metricksList});
    },
  }
}
