import Vue from "vue";
import {
  CREATE_CLAIM,
  REJECT_CLAIM,
  APPLY_CLAIM,
  GET_OUTGOING_CLAIMS,
  GET_INCOMING_CLAIMS,
  DELETE_CLAIM,
  CHANGE_CLAIMS_SWITCH,
  GET_ACTIVE_CLAIM_COUNT,
  GET_CLAIM_PAGINATION,
  GET_USER_SEND_REQUEST,
  GET_CLAIM_DATA,
} from "../actions/claims";
import {HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT} from '../actions/events';

const state = {
  isClosed: 0,
  outgoingClaims: [],
  incomingClaims: [],
  incomingClaimCount: 0,
  isUserSendRequest: false,
  claimPagination: {},
};

const getters = {
  getOutgoingClaimsList: state => state.outgoingClaims,
  getIncomingClaimsList: state => state.incomingClaims,
  getIncomingClaimCount: state => state.incomingClaimCount,
  getClaimPagination: state => state.claimPagination
};

const actions = {
  [GET_OUTGOING_CLAIMS]: ({dispatch, commit}, params) => {
    return dispatch(HTTP_GET, {
      method: GET_OUTGOING_CLAIMS,
      data: { params }
    }).then((response) => {
      commit(GET_OUTGOING_CLAIMS, response.data);
      commit(GET_CLAIM_PAGINATION, response.pagination);
    });
  },
  [GET_INCOMING_CLAIMS]: ({dispatch, commit}, params) => {
    return dispatch(HTTP_GET, {
      method: GET_INCOMING_CLAIMS,
      data: { params }
    }).then((response) => {
      commit(GET_INCOMING_CLAIMS, response.data);
      commit(GET_CLAIM_PAGINATION, response.pagination);
      if (!params.deal_id && params.closed === 0) {
        commit(GET_ACTIVE_CLAIM_COUNT, response.meta.total);
      }
    });
  },
  [GET_ACTIVE_CLAIM_COUNT]: ({dispatch, commit}, params) => {
    return dispatch(HTTP_GET, {
      method: GET_INCOMING_CLAIMS,
      data: {params}
    }).then((response) => {
      if (params.closed === 0) {
        commit(GET_ACTIVE_CLAIM_COUNT, response.meta.total);
      }
    });
  },
  [GET_CLAIM_DATA]: ({dispatch}, {id}) => {
    return dispatch(HTTP_GET, {
      method: GET_CLAIM_DATA,
      params: {id},
      auto: false,
    })
  },
  [CREATE_CLAIM]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: CREATE_CLAIM,
      response: CREATE_CLAIM,
      data,
      auto: false,
    });
  },
  [REJECT_CLAIM]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_PUT, {
      method: REJECT_CLAIM,
      params: {id},
      auto: false,
    });
  },
  [APPLY_CLAIM]: ({commit, dispatch}, { id, google2fa_secret, otp, fiat_amount_custom }) => {
    return dispatch(HTTP_PUT, {
      method: APPLY_CLAIM,
      params: {id},
      data: { google2fa_secret, fiat_amount_custom, otp },
      auto: false,
    });
  },
  [DELETE_CLAIM]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_DELETE, {
      method: DELETE_CLAIM,
      params: {id},
      auto: false,
    });
  },
};

const mutations = {
  [GET_OUTGOING_CLAIMS]: (state, data) => {
    Vue.set(state, 'outgoingClaims', data);
  },
  [GET_INCOMING_CLAIMS]: (state, data) => {
    Vue.set(state, 'incomingClaims', data);
  },
  [CHANGE_CLAIMS_SWITCH]: (state, data) => {
    Vue.set(state, 'isClosed', data);
  },
  [GET_ACTIVE_CLAIM_COUNT]: (state, data) => {
    Vue.set(state, 'incomingClaimCount', data);
  },
  [GET_CLAIM_PAGINATION]: (state, data) => {
    Vue.set(state, 'claimPagination', data);
  },
  [GET_USER_SEND_REQUEST]: (state, data) => {
    Vue.set(state, 'isUserSendRequest', data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
