export const ADS_CLOSE_MARKET = 'ADS_CLOSE_MARKET';
export const ADS_UPDATE_MARKET = 'ADS_UPDATE_MARKET';

export const ADS = 'ADS';
export const ADS_META = 'ADS_META';
export const ADS_LINKS = 'ADS_LINKS';
export const ADS_FILTER = 'ADS_FILTER';
export const ADS_PAGINATION = 'ADS_PAGINATION';

export const ADS_SELLER = 'ADS_SELLER';
export const ADS_SELLERS = 'ADS_SELLERS';
export const ADS_SELLERS_META = 'ADS_SELLERS_META';
export const ADS_SELLERS_LINKS = 'ADS_SELLERS_LINKS';
export const ADS_SELLERS_PAGINATION = 'ADS_SELLERS_PAGINATION';

export const ADS_BUYER = 'ADS_BUYER';
export const ADS_BUYERS = 'ADS_BUYERS';
export const ADS_BUYERS_META = 'ADS_BUYERS_META';
export const ADS_BUYERS_LINKS = 'ADS_BUYERS_LINKS';
export const ADS_BUYERS_PAGINATION = 'ADS_BUYERS_PAGINATION';

export const ADS_RESET_GEOINFO = 'ADS_RESET_GEOINFO';

export const ADS_PAYMENT_SYSTEMS = 'ADS_PAYMENT_SYSTEMS';
export const ADS_RECOMMENDED_UPDATE = 'ADS_RECOMMENDED_UPDATE';

export const ADS_FILTER_SET = 'ADS_FILTER_SET';
export const ADS_FILTER_ALL_PAYMENTS = 'ADS_FILTER_ALL_PAYMENTS';

export const ADS_RESET_PAGE = 'ADS_RESET_PAGE';

