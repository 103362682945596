export const CREATE_CLAIM = 'CREATE_CLAIM';
export const GET_INCOMING_CLAIMS = 'GET_INCOMING_CLAIMS';
export const GET_OUTGOING_CLAIMS = 'GET_OUTGOING_CLAIMS';
export const GET_ACTIVE_CLAIM_COUNT = 'GET_ACTIVE_CLAIM_COUNT';
export const GET_CLAIM_PAGINATION = 'GET_CLAIM_PAGINATION';
export const GET_USER_SEND_REQUEST = 'GET_USER_SEND_REQUEST';
export const GET_CLAIM_DATA = 'GET_CLAIM_DATA';
export const APPLY_CLAIM = 'APPLY_CLAIM';
export const REJECT_CLAIM = 'REJECT_CLAIM';
export const DELETE_CLAIM = 'DELETE_CLAIM';
export const CHANGE_CLAIMS_SWITCH = 'CHANGE_CLAIMS_SWITCH';
