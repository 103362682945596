export const AD = 'AD';
export const AD_ALL = 'AD_ALL';
export const AD_EDIT = 'AD_EDIT';
export const AD_CREATE = 'AD_CREATE';
export const AD_UPDATE = 'AD_UPDATE';
export const REQUISITE_TYPES = 'REQUISITE_TYPES';
export const REQUISITE_VALIDATE = 'REQUISITE_VALIDATE';
export const REQUISITE_ADD = 'REQUISITE_ADD';
export const REQUISITE_UPDATE = 'REQUISITE_UPDATE';
export const REQUISITE_DELETE = 'REQUISITE_DELETE';
export const AD_ACTIVATE = 'AD_ACTIVATE';
export const AD_DEACTIVATE = 'AD_DEACTIVATE';
export const AD_MARKET = 'AD_MARKET';
export const AD_DELETE = 'AD_DELETE';
export const AD_FORM_DATA = 'AD_FORM_DATA';
export const REQUEST_CRYPTO = 'crypto-currencies';
export const REQUEST_CURRENCIES = 'currencies';
export const REQUEST_COUNTRIES = 'countries';
export const REQUEST_PAYMENTS = 'payment-systems';
export const REQUEST_BANKS = 'banks';
export const AD_USER_REVIEWS = 'AD_USER_REVIEWS';
export const AD_REQUISITES = 'AD_REQUISITES';
export const AD_VIEW_OPEN = 'AD_VIEW_OPEN';
export const AD_VIEW_CLOSE = 'AD_VIEW_CLOSE';
export const DELETED_AD = 'DELETED_AD';
export const AD_LOGOUT_CLEAR = 'AD_LOGOUT_CLEAR';
export const AD_PRICE_TICKER = 'AD_PRICE_TICKER';
export const AD_EVENTS_SUB = 'AD_EVENTS_SUB';
export const AD_EVENTS_UNSUB = 'AD_EVENTS_UNSUB';
export const REQUEST_PRICE = 'REQUEST_PRICE';
export const REQUISITE_DEALS_LIMITS_ADD = 'REQUISITE_DEALS_LIMITS_ADD';
export const REQUISITE_DEALS_LIMITS_UPDATE = 'REQUISITE_DEALS_LIMITS_UPDATE';
export const REQUISITE_DEALS_LIMITS_DELETE = 'REQUISITE_DEALS_LIMITS_DELETE';
export const REQUISITE_DEALS_LIMITS_RESET = 'REQUISITE_DEALS_LIMITS_RESET';
export const RESET_TURNOVER = 'RESET_TURNOVER';
export const SET_STRATEGY = 'SET_STRATEGY';
export const GET_OFFER_TIME = 'GET_OFFER_TIME'
