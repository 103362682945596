export const SET_HEADERS = 'SET_HEADERS';
export const USER_PROFILE = 'USER_PROFILE';
export const USER_LOADED = 'USER_LOADED';

export const USER_NOTIFY_MESSAGE = 'USER_NOTIFY_MESSAGE';
export const USER_NOTIFY_SYSTEM_DELETE = 'USER_NOTIFY_SYSTEM_DELETE';
export const USER_NOTIFY_SYSTEM_UNREAD = 'USER_NOTIFY_SYSTEM_UNREAD';
export const USER_NOTIFY_AD = 'USER_NOTIFY_AD';
export const USER_NOTIFY_DEAL = 'USER_NOTIFY_DEAL';
export const USER_NOTIFY_CLAIM = 'USER_NOTIFY_CLAIM';
export const USER_NOTIFY_ANALYTICS = 'USER_NOTIFY_ANALYTICS';
export const USER_NOTIFY_READ = 'USER_NOTIFY_READ';
export const USER_NOTIFY_BLOCKED = 'USER_NOTIFY_BLOCKED';
export const USER_NOTIFY = 'USER_NOTIFY';
export const USER_NOTIFY_CLEAR = 'USER_NOTIFY_CLEAR';
export const USER_LOGOUT_CLEAR = 'USER_LOGOUT_CLEAR';

export const APPEAL_UPDATE = 'APPEAL_UPDATE';
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const USER_GEOLOCATION = 'USER_GEOLOCATION';
export const EMAIL_UPDATE = 'EMAIL_UPDATE';
export const EMAIL_CONFIRM = 'EMAIL_CONFIRM';
export const EMAIL_START_CONFIRM = 'EMAIL_START_CONFIRM';
export const AVATAR_UPDATE = 'AVATAR_UPDATE';
export const AVATAR_DELETE = 'AVATAR_DELETE';
export const EMAIL_ERROR = 'EMAIL_ERROR';
export const SOCKET_DASHBOARD = 'SOCKET_DASHBOARD';
export const SOCKET_DASHBOARD_OFF = 'SOCKET_DASHBOARD_OFF';


export const SOCIAL_EMAIL_UPDATE = 'SOCIAL_EMAIL_UPDATE';
export const SOCIAL_LOGIN_UPDATE = 'SOCIAL_LOGIN_UPDATE';

export const USER_BALANCE_UPDATE = 'USER_BALANCE_UPDATE';
export const USER_BALANCE = 'USER_BALANCE';
export const USER_BALANCE_FIAT = 'USER_BALANCE_FIAT';
export const UPDATE_SELECTED_CURRENCY = 'UPDATE_SELECTED_CURRENCY';

export const SEARCH_USERS = 'SEARCH_USERS';

export const USER_WALLET_ADDRESS = 'USER_WALLET_ADDRESS';
export const USER_WALLET_WITHDRAW = 'USER_WALLET_WITHDRAW';
export const USER_WALLET_VALIDATE = 'USER_WALLET_VALIDATE';
export const USER_WALLET_WITHDRAW_PAID = 'USER_WALLET_WITHDRAW_PAID';
export const USER_WALLET_WITHDRAW_STANDARD = 'USER_WALLET_WITHDRAW_STANDARD';
export const USER_WALLET_WITHDRAW_AUTO = 'USER_WALLET_WITHDRAW_AUTO';
export const USER_WALLET_WITHDRAW_AUTO_LIST = 'USER_WALLET_WITHDRAW_AUTO_LIST';
export const USER_WALLET_WITHDRAW_AUTO_DELETE = 'USER_WALLET_WITHDRAW_AUTO_DELETE';
export const USER_WALLET_HISTORY = 'USER_WALLET_HISTORY';
export const USER_WALLET_PAYING = 'USER_WALLET_PAYING';
export const USER_WALLET_PAYOUT = 'USER_WALLET_PAYOUT';
export const USER_WALLET_COMISSIONS = 'USER_WALLET_COMISSIONS';
export const USER_WALLET_ADD_PAYOUT = 'USER_WALLET_ADD_PAYOUT';
export const USER_WALLET_DELETE_PAYOUT = 'USER_WALLET_DELETE_PAYOUT';
export const USER_WALLET_PAYOUT_LIST = 'USER_WALLET_PAYOUT_LIST';
export const USER_WALLET_ADDRESS_HISTORY = 'USER_WALLET_ADDRESS_HISTORY';
export const GET_USER_WALLET_HISTORY_PAGINATION = 'GET_USER_WALLET_HISTORY_PAGINATION';
export const USER_SESSIONS_LIST = 'USER_SESSIONS_LIST';
export const USER_SECURITY_LOGS = 'USER_SECURITY_LOGS';
export const USER_PRO_RATING = 'USER_PRO_RATING';
export const USER_SPOOFING_PROTECTION = 'USER_SPOOFING_PROTECTION';

export const IMPORTANT_NOTIFICATION_LIST = 'IMPORTANT_NOTIFICATION_LIST';
export const IMPORTANT_NOTIFICATION_READ = 'IMPORTANT_NOTIFICATION_READ';
export const IMPORTANT_NOTIFICATION_SOCKET = 'IMPORTANT_NOTIFICATION_SOCKET';
export const IMPORTANT_NOTIFICATION_SOCKET_OFF = 'IMPORTANT_NOTIFICATION_SOCKET_OFF';

export const PROFILE_PARTNERSHIP_GET = 'PROFILE_PARTNERSHIP_GET';
export const PROFILE_MAKE_PARTNERSHIP = 'PROFILE_MAKE_PARTNERSHIP';
export const PROFILE_REQUESTS = 'PROFILE_REQUESTS';
export const PROFILE_SET_PARTNER_ID = 'PROFILE_SET_PARTNER_ID';
export const PROFILE_USER_IP_LIST = 'PROFILE_USER_IP_LIST';
export const PROFILE_USER_IP_ADD = 'PROFILE_USER_IP_ADD';
export const PROFILE_USER_IP_DELETE = 'PROFILE_USER_IP_DELETE';
export const PROFILE_KYC = 'PROFILE_KYC';

export const LOAD_DEAL = 'LOAD_DEAL';
export const LOAD_SUMMARY = 'LOAD_SUMMARY';

export const SET_ROUTE_DATA = 'SET_ROUTE_DATA';
export const CHANGE_OPERATIONS_TAB = 'CHANGE_OPERATIONS_TAB';
export const PROFILE_SECURITY_OTP_SEND = 'PROFILE_SECURITY_OTP_SEND';



