export const SOCKET_INIT = 'SOCKET_INIT';
export const SOCKET_CLOSE = 'SOCKET_CLOSE';
export const LOADING = 'LOADING';
export const ERROR_HANDLE = 'ERROR_HANDLE';
export const HTTP_GET = 'HTTP_GET';
export const HTTP_PUT = 'HTTP_PUT';
export const HTTP_POST = 'HTTP_POST';
export const HTTP_DELETE = 'HTTP_DELETE';
export const EVENT_COMMIT = 'EVENT_COMMIT';
export const EVENT_OPEN = 'EVENT_OPEN';
export const EVENT_CLOSE = 'EVENT_CLOSE';
export const EVENT_MARKET_OPEN = 'EVENT_MARKET_OPEN';
export const EVENT_RATES_OPEN = 'EVENT_RATES_OPEN';
export const EVENT_MARKET_CLOSE = 'EVENT_MARKET_CLOSE';
export const DEAL_CHAT_OPEN = 'DEAL_CHAT_OPEN';
export const DEAL_CHAT_CLOSE = 'DEAL_CHAT_CLOSE';
export const HTTP_ERROR = 'HTTP_ERROR';
export const HTTP_ERROR_CLEAR = 'HTTP_ERROR_CLEAR';
export const HTTP_PING = 'HTTP_PING';
export const PAGINATION = 'PAGINATION';
export const RESET_ERRORS = 'RESET_ERRORS';
export const INC_FAILED_HTTP_REQ_COUNTER = 'INC_FAILED_HTTP_REQ_COUNTER';
export const RESET_FAILED_HTTP_REQ_COUNTER = 'RESET_FAILED_HTTP_REQ_COUNTER';
export const INC_FAILED_CONNECT_REQ_COUNTER = 'INC_FAILED_HTTP_REQ_COUNTER';
export const RESET_FAILED_CONNECT_REQ_COUNTER = 'RESET_FAILED_HTTP_REQ_COUNTER';
export const TEST_ERROR_REQUEST = 'TEST_ERROR_REQUEST';
export const EVENT_LOGOUT_CLEAR = 'EVENT_LOGOUT_CLEAR';
