<template lang="pug">
.layout-main__menu
  .desktop-menu(:class="{'active': showMobileMenu}")
    .desktop-menu__header(@click="sendAnalytics('Logo', 'logo_header')")
      router-link.header-logo(to="/", @click.native="$emit('switchMobileMenu', false)")
      span.header-close(@click="$emit('switchMobileMenu', false)")

    // TODO вынести в отдельный компонент
    // TODO убрать из action.USER_PROFILE (user.js) => dispatch(USER_BALANCE ....), это должен делать компонент, так как он всегда на виду
    vue-scroll
      .desktop-menu__body
        section.desktop-menu__section.balance(v-if="isAuthenticated")
          .desktop-menu__section__title
            span баланс
          .desktop-menu__section__content
            .content-amount
              .amount-balance(v-if="showBalance")
                .balance-fiat
                  span {{ `${showBalanceIn.amount} ${showBalanceIn.code}` }}
                  .refresh-button(@click="refreshBalance()")
                    span.refresh-icon
                span.balance-crypto {{ `${getBalance.amount} ${cryptoCode}` }}
              .amount-balance(v-else)
                .balance-loading
                  i.loading-icon(class="el-icon-loading")
                  span.loading-title Обновление баланса...
            el-tooltip(
              v-if="!isKycRequiredAndConfirmed"
              content="Для доступа пройдите верификацию!"
              placement="right"
            )
              button.btn.btn__main.w-100(
                type="button"
                :disabled="!isKycRequiredAndConfirmed"
                @click="goToDeposit()") Пополнить
            button.btn.btn__main.w-100(
              v-else
              type="button"
              :disabled="!isKycRequiredAndConfirmed"
              @click="goToDeposit()") Пополнить

        section.desktop-menu__section.currency-rate(v-if="isAuthenticated")
          //span.desktop-menu__title Курсы
          rates(:currency="selectedCurrency")
        section.desktop-menu__section.finances(v-if="isAuthenticated")
          .desktop-menu__section__title
            span финансы
          el-tooltip(
            v-if="!isKycRequiredAndConfirmed"
            content="Для доступа пройдите верификацию!"
            placement="right"
          )
            ul.menu__list
              component.menu__item(
                :is="isKycRequiredAndConfirmed ? 'router-link' : 'div'"
                tag="li"
                :class="{ 'market-page-active': $route.meta.isMarketPage, disabled: !isKycRequiredAndConfirmed}"
                :key="item.title"
                v-if="item.visible"
                :to="isKycRequiredAndConfirmed ? {name: item.route} : ''"
                v-for="item in menu.finances"
                @click="$emit('switchMobileMenu', false)"
              )
                span.item__icon
                  span(:class="item.icon")
                span.item__text(v-if="item.route === 'Deals'") {{ item.title }} {{ activeDeals }}
                span.item__text(v-else-if="item.route === 'Claims'") {{ item.title }} {{ activeClaims }}
                span.item__text(v-else) {{ item.title }}
          ul.menu__list(v-else)
            component.menu__item(
              :is="isKycRequiredAndConfirmed ? 'router-link' : 'div'"
              tag="li"
              :class="{ 'market-page-active': $route.meta.isMarketPage, disabled: !isKycRequiredAndConfirmed}"
              :key="item.title"
              v-if="item.visible"
              :to="isKycRequiredAndConfirmed ? {name: item.route} : ''"
              v-for="item in menu.finances"
              @click="$emit('switchMobileMenu', false)"
            )
              span.item__icon
                span(:class="item.icon")
              span.item__text(v-if="item.route === 'Deals'") {{ item.title }} {{ activeDeals }}
              span.item__text(v-else-if="item.route === 'Claims'") {{ item.title }} {{ activeClaims }}
              span.item__text(v-else) {{ item.title }}
        section.desktop-menu__section.trade-pro
          .desktop-menu__section__title
            p торговля
             span &nbsp; pro
          .trade-pro__body
            span.icon
            span.text Раздел в процессе разработки
        section.desktop-menu__section.terms
          router-link.terms-link(
            tag="div"
            :to="{name: 'Terms'}"
          )
            span Пользовательское соглашение
</template>
<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { USER_BALANCE_UPDATE, USER_BALANCE } from '@/store/actions/user';
import Rates from './Rates';
import analytics from '@/components/mixins/analytics';

export default {
  components: {
    Rates
  },

  mixins: [analytics],

  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cryptoCode: 'BTC',
      selectedCurrency: '',
    };
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'getActiveDealsCount',
      'getBalance',
      'loading',
      'getSelectedCurrency',
      'getIncomingClaimsList',
      'getIncomingClaimCount',
      'isKycRequiredAndConfirmed'
    ]),
    isLoading () {
      return this.loading(USER_BALANCE) === 'loading';
    },
    showBalance() {
      return this.getBalance && this.getBalance.amount.length > 0 && !this.isLoading;
    },
    showBalanceIn() {
      let rate = this.getBalance.fiat.rates.find(item => {
        return item.code === this.selectedCurrency;
      });
      return {
        amount: (this.getBalance.fiat.amountUsd * rate.rate).toFixed(0),
        code: rate.code.toUpperCase()
      };
    },

    menu: function() {
      return {
        finances: [
          {
            title: 'Объявления',
            route: 'Market',
            icon: 'desktop-menu__icon-squares',
            visible: this.isAuthenticated,
          },
          {
            title: 'Сделки',
            route: 'Deals',
            icon: 'desktop-menu__icon-arrows',
            visible: this.isAuthenticated,
          },
          {
            title: 'Претензии',
            route: 'Claims',
            icon: 'desktop-menu__icon-exclamation',
            visible: this.isAuthenticated,
          },
          {
            title: 'Кошелек',
            route: 'Wallet',
            icon: 'desktop-menu__icon-purse',
            visible: this.isAuthenticated,
          },
        ],
      };
    },
    activeDeals(){
      return this.getActiveDealsCount > 0 ? `(${this.getActiveDealsCount})` : '';
    },
    activeClaims(){
      return this.getIncomingClaimCount > 0 ? `(${this.getIncomingClaimCount})` : '';
    },
  },

  watch: {
    $route(to) {
      if (to.name === 'Logout') {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          this.$router.push('/');
        });
      }
    },
    getSelectedCurrency(val) {
      if (this.selectedCurrency !== val) {
        this.selectedCurrency = this.getSelectedCurrency;
      }
    },
  },
  created() {
    this.selectedCurrency = this.getSelectedCurrency;
  },
  methods: {
    goToDeposit() {
      if (this.$route.name !== 'Deposit' ) {
        this.$router.push({name: 'Deposit'});
      }
    },
    refreshBalance() {
      this.$store.dispatch(USER_BALANCE_UPDATE);
    },
  }
};
</script>

<style lang="scss">
</style>
