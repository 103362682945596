import {
  DASHBOARD_AD_STATISTIC,
  DASHBOARD_AD_UPDATE,
  DASHBOARD_ADS,
  DASHBOARD_ADS_SELL,
  DASHBOARD_ADS_BUY,
  DASHBOARD_ADS_ALL,
  DASHBOARD_ADS_PAGINATION,
  DASHBOARD_DEAL_STATISTIC,
  DASHBOARD_DEALS,
  DASHBOARD_DELETE,
  DASHBOARD_INSERT,
  DASHBOARD_UPDATE,
  DASHBOARD_LOGOUT_CLEAR,
  DASHBOARD_OPEN,
  DASHBOARD_PAGINATION,
  DASHBOARD_UPDATE_ACTIVE_FILTERS,
  DASHBOARD_ADS_FILTER_SET, DASHBOARD_DEAL_UPDATE, DASHBOARD_DEAL_DELETE, GET_ACTIVE_DEALS_COUNT
} from '../actions/dashboard';
import Vue from 'vue';
import { HTTP_GET, HTTP_PUT, HTTP_DELETE } from '../actions/events';
import { DEAL_LISTEN } from '../actions/deal';
import { AD_UPDATE, AD_DELETE } from '../actions/ad';

const state = {
  statistic: {},
  history: [],
  deal_statistic: {},
  deal_list: [],
  ads: {
    all: [],
  },
  opened: [],
  dashboardFilter: {
    per_page: 100,
    page: 1,
    country_id: localStorage.getItem('selected-country') || null,
    currency_id: null,
    crypto_currency_id: null,
    payment_system_id: null,
    bank_id: null,
    price_from: 0,
    price_to: 0,
    min_from: 0,
    max_to: 0,
    reputation_from: 0,
    reputation_to: 100,
  },
  activeDealsCount: 0,
};

const getters = {
  dashboardActiveOptions: state => state.activeOptions,
  getAds: state => type => state.ads[type],
  dashboardFilter: state => state.dashboardFilter,
  allDashboard: state => state.ads.all,
  dealsDashboard: state => state.deal_list,
  getAdStatistic: state => id => state.statistic[id],
  getDealStatistic: state => state.deal_statistic,
  getActiveDealsCount: state => state.activeDealsCount,
  getDashboardPagination: state => state.pagination,
};

const actions = {
  [DASHBOARD_ADS_FILTER_SET]: ({commit}, filters) => {
    _.each(filters, (value, key) => {
      commit(DASHBOARD_ADS_FILTER_SET, {key, value});
    });
  },
  [DASHBOARD_DEAL_UPDATE]: async ({commit, dispatch, rootState}, deal) => {
    const activeFilters = _.pickBy(rootState.deal.dealsFilter, filter => filter !== null);
    const userId = rootState.user.profile.id;
    commit(DASHBOARD_DEAL_UPDATE, {deal, activeFilters, userId})
  },
  [DASHBOARD_ADS_ALL]: async ({commit, dispatch}, {filters}) => {
    await dispatch(DASHBOARD_ADS_FILTER_SET, filters);
    // get data
    const response = await dispatch(HTTP_GET, {
      method: DASHBOARD_ADS,
      params: {},
      response: false,
      data: {params: state.dashboardFilter},
    });
    console.log('DASHBOARD ADS ALL RESPONSE: ', response);
    commit(DASHBOARD_ADS_ALL, response);
  },

  [DASHBOARD_LOGOUT_CLEAR]: ({commit}) => {
    commit(DASHBOARD_LOGOUT_CLEAR);
  },
  [DASHBOARD_UPDATE_ACTIVE_FILTERS]: ({commit, dispatch}, options) => {
    dispatch('DASHBOARD_DEALS', {type: 'history', params: options});
  },
  [DASHBOARD_DEALS]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: DASHBOARD_DEALS,
      response: false,
      params: {},
      data: {params: data.params}
    }).then(response => {
      commit(DASHBOARD_DEALS, response.data);
      commit(DASHBOARD_PAGINATION, response.pagination);
      // Включаем прослушку
      _.each(response.data, deal => dispatch(DEAL_LISTEN, {dealId: deal.id, deal}));
      return response;
    }).catch(response => {
      return response;
    });
  },
  [DASHBOARD_ADS]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: DASHBOARD_ADS,
      response: false,
      params: {},
      data: {params: data.params}
    }).then(response => {
      _.forEach(response.data, (ad) => {
        commit(AD_UPDATE, ad);
      });
      commit(DASHBOARD_ADS, {
        type: data.type,
        list: _.map(response.data, ad => ad.id),
      });
      return response.pagination;
    });
  },
  [DASHBOARD_AD_STATISTIC]: ({commit, dispatch}, id) => {
    return dispatch(HTTP_GET, {
      method: DASHBOARD_AD_STATISTIC,
      params: {id},
      data: {},
      response: false,
    }).then(({data}) => {
      commit(DASHBOARD_AD_STATISTIC, {id, data});
    })
  },
  [DASHBOARD_AD_UPDATE]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_PUT, {
      method: AD_UPDATE,
      params: {id},
      data,
    });
  },
  [DASHBOARD_DEAL_STATISTIC]: ({dispatch}, {params}) => {
    return dispatch(HTTP_GET, {
      method: DASHBOARD_DEAL_STATISTIC,
      data: {
        params,
      }
    })
  },
  [GET_ACTIVE_DEALS_COUNT]: ({dispatch, commit}) => {
    dispatch(HTTP_GET, {
      method: DASHBOARD_DEALS,
      response: false,
      params: {},
      data: {params:{is_active: 1}}
    }).then((response) => {
      commit(GET_ACTIVE_DEALS_COUNT, response.meta.total);
    });
  },

};
const mutations = {
  [DASHBOARD_ADS_FILTER_SET]: (state, { key, value }) => {
    if (!value && value !== false) {
      if (state.dashboardFilter[key]) {
        Vue.set(state.dashboardFilter, key, ''); // чтобы обновились данные
        delete state.dashboardFilter[key];
      }
    } else {
      Vue.set(state.dashboardFilter, key, value);
    }
  },

  [DASHBOARD_ADS_SELL]: (state, response) => {
    Vue.set(state.ads, 'for_sale', response.data);
  },

  [DASHBOARD_ADS_BUY]: (state, response) => {
    Vue.set(state.ads, 'for_buy', response.data);
  },

  [DASHBOARD_ADS_ALL]: (state, response) => {
    Vue.set(state.ads, 'all', response.data);
  },

  [DASHBOARD_LOGOUT_CLEAR]: (state) => {
    state.statistic = {};
    state.history = [];
    state.deal_statistic = {};
    state.ads = {
      for_sale: [],
      for_buy: [],
      // all: [], // @derpacated (probably)
    };
    state.opened = [];
  },
  [DASHBOARD_DEALS]: (state, deals) => {
    Vue.set(state, 'deal_list', deals);
  },
  [DASHBOARD_PAGINATION]: (state, data) => {
    Vue.set(state, 'pagination', data);
  },
  [DASHBOARD_ADS]: (state, {type, list}) => {
    Vue.set(state.ads, type, list);
  },
  [DASHBOARD_AD_STATISTIC]: (state, {id, data}) => {
    Vue.set(state.statistic, id, data);
  },
  [DASHBOARD_DEAL_STATISTIC]: (state, data) => {
    Vue.set(state, 'deal_statistic', data);
  },
  [DASHBOARD_ADS_PAGINATION]: (state, pagination) => {
    Vue.set(state, 'ads_pagination', pagination);
  },

  // @deprecated for ads
  [DASHBOARD_INSERT]: (state, {type, data, ads}) => {
    console.log('!!! DASHBOARD_INSERT is deprecated, use DASHBOARD_UPDATE instead');
    // console.log(type, data, ads);
    // if (ads) {
    //   if (state.ads[type].indexOf(data.id) === -1 && data && data.id) {
    //     state.ads[type].push(data.id);
    //   }
    // } else {
    //   if (state[type].indexOf(data.id) === -1 && data && data.id) {
    //     state[type].push(data.id);
    //   }
    // }
  },

  // updates or inserts Deal in dashboard
  [DASHBOARD_DEAL_UPDATE]: (state, { deal, activeFilters, userId }) => {
    const currentDeal = deal.deal;
    let to_the_beginning = false;
    if(deal.to_top){
      to_the_beginning = true;
    }
    const idx = state.deal_list.findIndex(item => item.id === currentDeal.id);
    if (idx >= 0) {
      Vue.set(state.deal_list, idx, currentDeal);
    } else {
      const filterRules = {
        is_sale: currentDeal.is_sale ? 1 : 0,
        is_active: currentDeal.ad.is_active ? 1 : 0,
        currency_id: currentDeal.currency.id,
        fiat_amount: currentDeal.fiat_amount,
        fiat_amount_from: {
          value: currentDeal.fiat_amount,
          mode: 'more',
        },
        fiat_amount_to: {
          value: currentDeal.fiat_amount,
          mode: 'less',
        },
        crypto_currency_id: currentDeal.crypto_currency.id,
        crypto_amount: currentDeal.crypto_amount,
        crypto_amount_from: {
          value: currentDeal.crypto_amount,
          mode: 'more',
        },
        crypto_amount_to: {
          value: currentDeal.crypto_amount,
          mode: 'less',
        },
        requisite_text: {
          value: currentDeal.ad.requisites,
          mode: 'oneOf'
        },
        login: currentDeal.author.id === userId ? currentDeal.offer.id : currentDeal.author.id,
        created_from: {
          value: currentDeal.ad.created_at,
          mode: 'more',
        },
        created_to: {
          value: currentDeal.ad.created_at,
          mode: 'less',
        },
        payment_system_id: currentDeal.payment_system.id,
        finished: currentDeal.status.title === 'Finished' ? 1 : 0,
        canceled: currentDeal.status.title === 'Canceled' ? 1 : 0,
        per_page: true,
        requisite_exists: currentDeal.requisites ? 1 : 0,
      };
      let isValidFilter = true;
      for (const filterName in activeFilters) {
        if (!isValidFilter) {
          break;
        }
        const currentFilter = activeFilters[filterName];
        const currentRule = filterRules[filterName];
        if (typeof currentRule === 'boolean') {
          if (!currentRule) {
            isValidFilter = false;
          }
        } else if (typeof currentRule === 'object') {
          if (currentRule.mode === 'more' && currentRule.value < currentFilter) {
            isValidFilter = false;
          } else if (currentRule.mode === 'less' && currentRule.value > currentFilter) {
            isValidFilter = false;
          } else if (currentRule.mode === 'oneOf') {
            let haveRequisite = false;
            for (const requisite of currentRule.value) {
              if (requisite.indexOf(currentFilter) !== -1) {
                haveRequisite = true;
                break;
              }
            }
            if (!haveRequisite) {
              isValidFilter = false;
            }
          }
        } else {
          if (currentRule != currentFilter) {
            isValidFilter = false;
          }
        }
      }
      if (isValidFilter) {
        if(to_the_beginning){
          state.deal_list.unshift(currentDeal);
        } else {
          state.deal_list.push(currentDeal);
        }
      }
    }
  },

  // updates or inserts Ad in dashboard
  [DASHBOARD_UPDATE]: (state, ad) => { // TODO переименовать, т.к. тут работа с объявлениями идет
    const idx = state.ads['all'].findIndex(item => item.id === ad.id);
    if (idx >= 0) {
      if (!ad.author) {
        const data = {
          ...state.ads['all'][idx]
        }
        data.is_active = ad.is_active;
        data.is_deleted = ad.is_deleted;
        data.is_sale = ad.is_sale;
        data.max = ad.max;
        data.min = ad.min;
        data.original_max = ad.original_max;
        data.price = ad.price;
        data.turnover = ad.turnover;
        Vue.set(state.ads['all'], idx, data);
      } else {
        Vue.set(state.ads['all'], idx, ad);
      }
    } else {
      state.ads['all'].push(ad);
    }
  },

  [DASHBOARD_OPEN]: (state, id) => {
    if (state.opened.indexOf(id) >= 0) {
      state.opened.splice(state.opened.indexOf(id), 1);
    } else {
      state.opened.push(id);
    }
  },

  [DASHBOARD_DELETE]: (state, ad) => { // TODO переименовать, т.к. тут работа с объявлениями идет
    const idx = state.ads['all'].findIndex(item => item.id === ad.id);
    if (idx >= 0) {
      state.ads['all'].splice(idx, 1)
    }
  },

  [DASHBOARD_DEAL_DELETE]: (state, deal) => {
    const idx = state.deal_list.findIndex(item => item.id === deal.id);
    if (idx >= 0) {
      state.deal_list.splice(idx, 1)
    }
  },

  [GET_ACTIVE_DEALS_COUNT]: (state, deal_count) => {
    Vue.set(state, 'activeDealsCount', deal_count);
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
}
