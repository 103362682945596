export const DASHBOARD_DEALS = 'DASHBOARD_DEALS';
export const DASHBOARD_ADS = 'DASHBOARD_ADS';
export const DASHBOARD_UPDATE_ACTIVE_FILTERS = 'DASHBOARD_UPDATE_ACTIVE_FILTERS';
export const DASHBOARD_AD_UPDATE = 'DASHBOARD_AD_UPDATE';
export const DASHBOARD_PAGINATION = 'DASHBOARD_PAGINATION';
export const DASHBOARD_DELETE = 'DASHBOARD_DELETE';
export const DASHBOARD_INSERT = 'DASHBOARD_INSERT';
export const DASHBOARD_UPDATE = 'DASHBOARD_UPDATE';
export const DASHBOARD_AD_STATISTIC = 'DASHBOARD_AD_STATISTIC';
export const DASHBOARD_DEAL_STATISTIC = 'DASHBOARD_DEAL_STATISTIC';
export const DASHBOARD_ADS_PAGINATION = 'DASHBOARD_ADS_PAGINATION';
export const DASHBOARD_OPEN = 'DASHBOARD_OPEN';
export const DASHBOARD_LOGOUT_CLEAR = 'DASHBOARD_LOGOUT_CLEAR';
export const DASHBOARD_ADS_FILTER_SET = 'DASHBOARD_ADS_FILTER_SET';
export const DASHBOARD_ADS_SELL = 'DASHBOARD_ADS_SELL';
export const DASHBOARD_ADS_BUY = 'DASHBOARD_ADS_BUY';
export const DASHBOARD_ADS_ALL = 'DASHBOARD_ADS_ALL';
export const DASHBOARD_DEAL_UPDATE = 'DASHBOARD_DEAL_UPDATE';
export const DASHBOARD_DEAL_DELETE = 'DASHBOARD_DEAL_DELETE';
export const GET_ACTIVE_DEALS_COUNT = 'GET_ACTIVE_DEALS_COUNT';
export const REQUEST_RATES = 'REQUEST_RATES';
