const DOMAIN_SUBSTRING_TO_REPLACE = 'DOMAIN';

const getDomainWithoutSubdomains = url => {
  return url.split('.').slice(-2).join('.');
}

const domain = process.env.NODE_ENV === 'production' ? getDomainWithoutSubdomains(window.location.hostname) : process.env.VUE_APP_DEV_DOMAIN;
const api = process.env.VUE_APP_API_URL.replace(DOMAIN_SUBSTRING_TO_REPLACE, domain);
const socket_host = process.env.VUE_APP_SOCKET_HOST.replace(DOMAIN_SUBSTRING_TO_REPLACE, domain);

export default {
  api,
  socket_host,
  domain,
  analytics: process.env.VUE_APP_ANALYTICS_ENABLE,
  dealStatusOnTimeCheck: parseInt(process.env.VUE_APP_DEAL_STATUS_ON_TIME_CHECK),
  dealStatusSellerRequisite: parseInt(process.env.VUE_APP_DEAL_STATUS_SELLER_REQUISITE),
  dealDisputeAllowTime: parseInt(process.env.VUE_APP_DEAL_DISPUTE_ALLOW_TIME),
  maxFailedHttpRequests: parseFloat(process.env.VUE_APP_MAX_FAILED_HTTP_REQUESTS),
  connectRequestsTimeout: parseFloat(process.env.VUE_APP_CONNECT_REQUESTS_TIMEOUT),
  maxFailedConnectRequests: parseFloat(process.env.VUE_APP_MAX_FAILED_CONNECT_REQUESTS),
  message_max_filesize: process.env.VUE_APP_MESSAGE_MAX_FILESIZE,
  claim_max_filesize: process.env.VUE_APP_CLAIM_MAX_FILESIZE,
  per_page_min: process.env.VUE_APP_PER_PAGE_MIN,
  per_page_usual: process.env.VUE_APP_PER_PAGE_USUAL,
  per_page_max: process.env.VUE_APP_PER_PAGE_MAX,
  partnership_comission: parseFloat(process.env.VUE_APP_PARTNERSHIP_BASE_COMMISSION),
  crypto_fee_fast: process.env.VUE_APP_CRYPTO_FEE_FAST,
  crypto_fee_standard: process.env.VUE_APP_CRYPTO_FEE_STANDARD,
  crypto_fee_free: process.env.VUE_APP_CRYPTO_FEE_FREE,
  default: {
    defaultFiatIcon: '/icons/default_fiat.svg',
    defaultPaymentIcon: '/img/payment__methods.png',
    defaultCryptoIcon: '/img/bitcoin-icon2.svg',
    defaultCurrencyCode: 'RUB',
    defaultCryptocurrencyCode: 'BTC',
  }
}
