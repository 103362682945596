<template lang="pug">
  #ModalWindow.Modal
    .modal-container
      h4.modal-container__title Важные уведомления:
      vue-scroll.modal-container__inner
        .modal-item(v-for="item in notifications")
          .modal-item-title
            .modal-item-title__text {{ item.title }}
            .modal-item-title__date {{ divideDate(item.date).date }}
          p.modal-item__text {{ item.text }}
      .modal-buttons
        .btn.btn__main.modal-buttons__item(@click="closeConditions") Я прочитал(а)
</template>

<script>
import utils from '../../mixins/utils';
  export default {
    mixins: [utils],
    props: {
      notifications: {
        type: Array,
        default() {
          return [];
        },
      },  
    },
    computed: {
      notificationsId() {
        return this.notifications.map((item) => {
          return item.id;
        });
      },
    },
    methods: {
      closeConditions() {
        this.$emit('closeConditions', false, this.notificationsId);
      },
    }
  }
</script>

<style lang="scss" scoped>
  #ModalWindow {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal-container {
    display: flex;
    background: #fff;
    width: 100%;
    max-width: 800px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 20px;
    max-height: 75%;
    overflow: hidden;
    flex-direction: column;

    &__inner {
      display: flex;
      overflow: hidden;
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-size: 24px;
      padding-bottom: 30px;
    }
  }
  .modal-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 30px;
    margin: 0 20px;

    &-title {
      width: 100%;
      padding: 0px 0px 5px 0px;
      margin: 0;
      border-bottom: 1px solid #2464EB;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      &__text {
        font-size: 18px;
        font-weight: bold;
        overflow-wrap: anywhere;
      }

      &__date {
        color: #fff;
        background: #2464EB;
        padding: 2px 15px;
        font-size: 12px;
        border-radius: 25px;
      }
    }

    &__text {
      background: $blue100;
      padding: 15px;
      width: 100%;
      border: 1px solid $blue300;
      border-top: 0;
      font-size: 14px;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &__item {
      width: 390px;
    }
  }
</style>
