import {
  CHANGEPHONE_LOGOUT_CLEAR,
  PHONE_ADD,
  PHONE_CONFIRM,
  PHONE_DELETE,
  PHONE_DELETE_CONFIRM,
  PHONE_ERROR,
} from '../actions/changePhone'
import Vue from 'vue'
import {HTTP_PUT} from "../actions/events";

const state = {
  phone: {},
  deleteStatus: false,
  addStatus: false,
  errors: {},
};

const getters = {
  getPhone: state => state.phone,
  getDeleteStatus: state => state.deleteStatus,
  getAddStatus: state => state.addStatus,
  getPhoneErrors: state => state.errors,
};

const actions = {
  [CHANGEPHONE_LOGOUT_CLEAR]: ({commit}) => {
    commit(CHANGEPHONE_LOGOUT_CLEAR);
  },
  [PHONE_ADD]: ({commit, dispatch}, {phone, password}) => {
    commit(PHONE_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PHONE_ADD,
      params: {},
      auto: false,
      data: {phone, password},
    }).then((response) => {
      commit(PHONE_CONFIRM, response.data);
      commit(PHONE_ADD, true);
    }).catch((error) => {
      commit(PHONE_ERROR, error);
      return Promise.reject();
    });
  },
  [PHONE_CONFIRM]: ({commit, dispatch}, {token}) => {
    commit(PHONE_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PHONE_CONFIRM,
      params: {},
      auto: false,
      data: {token},
    }).then((response) => {
      commit(PHONE_CONFIRM, response.data);
      commit(PHONE_ADD, false);
    }).catch((error) => {
      commit(PHONE_ERROR, error);
      return Promise.reject();
    });
  },
  [PHONE_DELETE]: ({commit, dispatch}, {password}) => {
    commit(PHONE_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PHONE_DELETE,
      params: {},
      data: {password},
      auto: false,
    }).then((response) => {
      commit(PHONE_CONFIRM, response.data);
      commit(PHONE_DELETE, true);
    }).catch((error) => {
      commit(PHONE_ERROR, error);
      return Promise.reject();
    });
  },
  [PHONE_DELETE_CONFIRM]: ({commit, dispatch}, {token}) => {
    commit(PHONE_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PHONE_DELETE_CONFIRM,
      params: {},
      data: {token},
      auto: false,
    }).then((response) => {
      commit(PHONE_CONFIRM, response.data);
      commit(PHONE_DELETE, false);
    }).catch((error) => {
      commit(PHONE_ERROR, error);
      return Promise.reject();
    });
  },
};

const mutations = {
  [CHANGEPHONE_LOGOUT_CLEAR]: (state) => {
    state.phone = {};
    state.deleteStatus = false;
    state.addStatus = false;
    state.errors = {};
  },
  [PHONE_ADD]: (state, status) => {
    Vue.set(state, 'addStatus', status);
  },
  [PHONE_CONFIRM]: (state, data) => {
    Vue.set(state, 'phone', data);
  },
  [PHONE_ERROR]: (state, err) => {
    Vue.set(state, 'errors', err);
  },
  [PHONE_DELETE]: (state, status) => {
    Vue.set(state, 'deleteStatus', status);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
}
