import {SECURITY_LOGOUT_CLEAR, SECURITY_REQUEST, CRYPTO_SELL_OFF, CRYPTO_SELL_ON} from '@/store/actions/security';
import Vue from 'vue'
import {HTTP_GET, HTTP_PUT} from "../actions/events";
import {PROFILE_SECURITY_OTP_SEND} from "../actions/user";
import {REFRESH_OTP_CODE} from "../actions/security";

const state = {
  security: {},
};

const getters = {
  getSecurity: state => state.security,
  is2FaActive      : state => state.security.google2fa_status === 'activated',
  is2FaPaused      : state => state.security.google2fa_status === 'paused',
  is2FaDeactivated : state => state.security.google2fa_status === 'deactivated',
  isEmailConfirmed : state => !!state.security.email_confirmed,
  isPrivateKey     : state => !!state.security.is_private_key_issued,
  isOtpActive      : state =>
      state.security.email_confirmed && state.security.google2fa_status !== 'activated' && !state.security.is_current_ip_in_white_list,
};

const actions = {
  [SECURITY_LOGOUT_CLEAR]: ({commit}) => {
    commit(SECURITY_LOGOUT_CLEAR);
  },
  [SECURITY_REQUEST]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: SECURITY_REQUEST,
      data,
    });
  },
  [CRYPTO_SELL_ON]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_PUT, {
      method: CRYPTO_SELL_ON,
      response: false,
      data,
    }).then(response => {
      commit(SECURITY_REQUEST, response.data)
    })
  },
  [CRYPTO_SELL_OFF]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_PUT, {
      method: CRYPTO_SELL_OFF,
      response: false,
      data,
    }).then(response => {
      commit(SECURITY_REQUEST, response.data)
    })
  },
  [REFRESH_OTP_CODE]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SECURITY_OTP_SEND,
      response: false,
    })
  }
};

const mutations = {
  [SECURITY_LOGOUT_CLEAR]: (state) => {
    state.security = {};
  },
  [SECURITY_REQUEST]: (state, resp) => {
    Vue.set(state, 'security', resp);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
