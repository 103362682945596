export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const HEADER_MODULE_EDIT = 'HEADER_MODULE_EDIT';
export const PHONE_ON = 'PHONE_ON';
export const PHONE_OFF = 'PHONE_OFF';
export const WEB_ON = 'WEB_ON';
export const WEB_OFF = 'WEB_OFF';
export const MESSAGE_ON = 'MESSAGE_ON';
export const MESSAGE_OFF = 'MESSAGE_OFF';
export const DEAL_ON = 'DEAL_ON';
export const DEAL_OFF = 'DEAL_OFF';
export const DEAL_SALE_ON = 'DEAL_SALE_ON';
export const DEAL_SALE_OFF = 'DEAL_SALE_OFF';
export const DEAL_BUY_ON = 'DEAL_BUY_ON';
export const DEAL_BUY_OFF = 'DEAL_BUY_OFF';
export const TELEGRAM_ON = 'TELEGRAM_ON';
export const TELEGRAM_OFF = 'TELEGRAM_OFF';
export const FAKE_LOGIN_ON = 'FAKE_LOGIN_ON';
export const FAKE_LOGIN_OFF = 'FAKE_LOGIN_OFF';
export const EMAIL_ON = 'EMAIL_ON';
export const EMAIL_OFF = 'EMAIL_OFF';
export const PRO_CLAIM_APPLIED_ON = 'PRO_CLAIM_APPLIED_ON';
export const PRO_CLAIM_APPLIED_OFF = 'PRO_CLAIM_APPLIED_OFF';
export const PROFILE_SETTINGS_UPDATE = 'PROFILE_SETTINGS_UPDATE';
export const USER_WALLET_SETTINGS = 'USER_WALLET_SETTINGS';
export const SETTINGS_LOGOUT_CLEAR = 'SETTINGS_LOGOUT_CLEAR';
export const NOTIFICATION_GROUP_REQUEST = 'NOTIFICATION_GROUP_REQUEST';
export const NOTIFICATION_POSITION_REQUEST = 'NOTIFICATION_POSITION_REQUEST';
