<template lang="pug">
.support-icon(@click="toggleModal")
  .support-popup(:class="{'active':modalVisible}")
    .support-popup__container
      .list
        .link
          a(href="mailto:support@finex.market") Почта поддержки
        //- .link <!-- temporary hidden -->
          a(href="???") Поддержка в Telegram
        .link
          a(href="mailto:lawyer@finex.market") Почта для гос.органов
        .link
          router-link(:to="{ name: 'Terms' }") Пользовательское соглашение
</template>

<script>
import {mapGetters} from 'vuex';
import notifications from '@/components/mixins/notifications';
import analytics from "@/components/mixins/analytics";

export default {
  name: 'SupportPopup',
  mixins: [
    notifications, analytics,
  ],

  data() {
    return {
      modalVisible: false,
      defaultAvatar: '/img/default-avatar.png',
    };
  },
  computed: {
    ...mapGetters({
      'support': 'getProfile',
    }),
  },

  created() {
    let self = this;

    window.addEventListener('click', function(e){
      if (!self.$el.contains(e.target)){
        self.modalVisible = false
      }
    })
  },
  methods: {
    toProfile() {
      this.$router.push({name: 'Profile', params: {id: this.support.id}})
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
