import {
  DEAL_OFF,
  DEAL_ON,
  DEAL_SALE_ON,
  DEAL_SALE_OFF,
  DEAL_BUY_ON,
  DEAL_BUY_OFF,
  EMAIL_OFF,
  EMAIL_ON,
  FAKE_LOGIN_OFF,
  FAKE_LOGIN_ON,
  HEADER_MODULE_EDIT,
  MESSAGE_OFF,
  MESSAGE_ON,
  NOTIFICATION_GROUP_REQUEST,
  NOTIFICATION_POSITION_REQUEST,
  PHONE_OFF,
  PHONE_ON,
  PROFILE_SETTINGS_UPDATE,
  SETTINGS_ERROR,
  SETTINGS_LOGOUT_CLEAR,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  TELEGRAM_OFF,
  TELEGRAM_ON,
  USER_WALLET_SETTINGS,
  WEB_OFF,
  WEB_ON,
  PRO_CLAIM_APPLIED_ON,
  PRO_CLAIM_APPLIED_OFF,
} from '../actions/settings'
import Vue from 'vue'
import {HTTP_GET, HTTP_PUT} from '../actions/events'
import {USER_PROFILE} from '../actions/user'

const state = {
  status: '',
  options: {},
  wallet: {
    hideNull: localStorage.getItem('wallet-hideNull') || false
  },
  headerModules: {
    SpeedCostSwitcher: {
      currentSort: 'price',
    },
  }
};

const getters = {
  getSettings: state => state.options,
  getWalletSettings: state => state.wallet,
  getHeaderModule: state => state.headerModules,
};

const actions = {
  [SETTINGS_LOGOUT_CLEAR]: ({commit}) => {
    commit(SETTINGS_LOGOUT_CLEAR);
  },
  [SETTINGS_REQUEST]: ({commit, dispatch}) => {
    // axios
    return dispatch(HTTP_GET, {
      method: SETTINGS_REQUEST,
      response: false
    }).then(resp => {
      commit(SETTINGS_SUCCESS, resp.data)
    }).catch(resp => {
      commit(SETTINGS_ERROR)
    })
  },
  [HEADER_MODULE_EDIT]: ({commit, dispatch}, data) => {
    commit(HEADER_MODULE_EDIT, data);
  },
  [NOTIFICATION_GROUP_REQUEST]: ({commit, dispatch}, params) => {
    return dispatch(HTTP_PUT, {
      method: NOTIFICATION_GROUP_REQUEST,
      params,
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [NOTIFICATION_POSITION_REQUEST]: ({commit, dispatch }, params) => {
    return dispatch(HTTP_PUT, {
      method: NOTIFICATION_POSITION_REQUEST,
      params,
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [PHONE_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-phone-notifications'},
      response: false
    })
  },
  [PHONE_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-phone-notifications'},
      response: false
    })
  },
  [WEB_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-push-notifications'},
      response: false
    })
  },
  [WEB_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-push-notifications'},
      response: false
    }).then(() => {
      dispatch(MESSAGE_OFF)
      dispatch(DEAL_OFF)
    })
  },
  [MESSAGE_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-message-notifications'},
      response: false
    })
  },
  [MESSAGE_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-message-notifications'},
      response: false
    })
  },
  [DEAL_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-deal-notifications'},
      response: false
    })
  },
  [DEAL_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-deal-notifications'},
      response: false
    })
  },
  [PRO_CLAIM_APPLIED_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-pro-claim-applied-notifications'},
      response: false
    })
  },
  [PRO_CLAIM_APPLIED_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-pro-claim-applied-notifications'},
      response: false
    })
  },
  [DEAL_BUY_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-deal-buy-notifications'},
      response: false
    })
  },
  [DEAL_BUY_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-deal-buy-notifications'},
      response: false
    })
  },
  [DEAL_SALE_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-deal-sale-notifications'},
      response: false
    })
  },
  [DEAL_SALE_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-deal-sale-notifications'},
      response: false
    })
  },
  [TELEGRAM_ON]: ({commit, dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-telegram-notifications'},
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [TELEGRAM_OFF]: ({commit, dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-telegram-notifications'},
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [FAKE_LOGIN_ON]: ({commit, dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: FAKE_LOGIN_ON,
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [FAKE_LOGIN_OFF]: ({commit, dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: FAKE_LOGIN_OFF,
      response: false
    }).then(response => {
      commit(USER_PROFILE, response.data)
    })
  },
  [EMAIL_ON]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'enable-email-notifications'},
      response: false
    })
  },
  [EMAIL_OFF]: ({dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: PROFILE_SETTINGS_UPDATE,
      params: {parameter: 'disable-email-notifications'},
      response: false
    })
  },
  [USER_WALLET_SETTINGS]: ({commit, dispatch}, data) => {
    commit(USER_WALLET_SETTINGS, data)
  }
};

const mutations = {
  [SETTINGS_LOGOUT_CLEAR]: (state) => {
    state.status = '';
    state.options = {};
    state.wallet = {
      hideNull: false
    };
  },
  [SETTINGS_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [SETTINGS_SUCCESS]: (state, resp) => {
    state.status = 'success';
    Vue.set(state, 'options', resp)
  },
  [SETTINGS_ERROR]: (state) => {
    state.status = 'error'
  },
  [USER_WALLET_SETTINGS]: (state, data) => {
    const options = Object.keys(data);
    options.forEach(option => {
      localStorage.setItem('wallet-' + option, data[option]);
      Vue.set(state, 'wallet', data)
    })
  },
  [HEADER_MODULE_EDIT]: (state, data) => {
    Vue.set(state.headerModules, data.module, data.param);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
