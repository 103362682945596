import Vue from 'vue';
import store from './store';
import Router from 'vue-router';

const Market = () => import('./views/Market');
const Deals = () => import('./views/Deals');
const Claims = () => import('./views/Claims');
const CreateClaim = () => import('./views/CreateClaim');
const Wallet = () => import('./views/Wallet');
const PublicProfile = () => import('./views/Profile');
const AwesomeProfile = () => import('./views/AwesomeViews/Profile.vue');
const AwesomeProfileSecurity = () => import('./components/AwesomeComponents/Account/Profile/Security/ProfileSecurity.vue');
const AwesomeProfileNotifications = () => import('./components/AwesomeComponents/Account/Profile/Notifications/ProfileNotifications.vue');
const AwesomeProfileLogs = () => import('./components/AwesomeComponents/Account/Profile/Logs/ProfileLogs.vue');
const Commissions = () => import('./views/Comissions');
const Terms = () => import('./views/TermsOfUse');
const AdCreate = () => import('./views/AdCreate');
const AdEdit = () => import('./views/AdEdit');
const AuthForm = () => import('./views/AuthForm');
const EmailConfirm = () => import('./views/EmailConfirm');
const Deal = () => import('./views/Deal');
const ForgotPassword = () => import('./views/ForgotPassword');
const ResetPassword = () => import('./views/ResetPassword');
const DealCalculator = () => import('./views/DealCalculator');
const Partnership = () => import('./views/Partnership');
const AccountPartnership = () => import('./views/AccountPartnership');
const WhiteList = () => import('./views/WhiteList')
const NotFound = () => import('./views/NotFound');
const NotAccess = () => import('./views/NotAccess');
const ServerError = () => import('./views/ServerError');

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next({ name: 'Login', query: { next: to.fullPath } });
};

const ifUnauthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const beforeLogin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next(to.query.next || '/');
  } else if (to.query.next) {
    next();
  } else {
    next({ ...to, query: { ...to.query, next: from.fullPath } });
  }
};

const redirectIfAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    const routeNames = ['Login', 'LoginReferral', 'Registration', 'RegistrationReferral', 'ForgotPassword', 'ResetPassword', 'Terms']
    if(!routeNames.includes(to.name)) {
      next({ name: 'Login', query: { next: to.fullPath } });
    } else { next() }
  } else { next() }
}

const title = 'Finex — Financial exchange market';

/**
 * Use beforeEnter: ifAuthenticated if you want to hide pages
 * which have to see ONLY AUTHTORIZED user.
 */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Market',
      component: Market,
      meta: {
        title: 'Объявления',
        speedCostSwitch: true,
        adCreateButton: true,
        isMarketPage: true,
      },
    },
    {
      path: '/market',
      redirect: { name: 'Market' },
    },
    {
      path: '/r/:partner_id',
      redirect: { name: 'Market' },
    },
    {
      path: '/deals',
      name: 'Deals',
      component: Deals,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Сделки',
      },
    },
    {
      path: '/claims',
      name: 'Claims',
      component: Claims,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Претензии',
        claimCreateButton: true,
      },
    },
    {
      path: '/create-claim',
      name: 'CreateClaim',
      component: CreateClaim,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Создать претензию',
      },
    },
    {
      path: '/market/sell',
      name: 'MarketSell',
      component: Market,
      meta: {
        title: 'Объявления',
        speedCostSwitch: true,
        adCreateButton: true,
        isMarketPage: true,
      },
      props: {
        isSellTab: true,
      },
    },
    {
      path: '/market/buy',
      name: 'MarketBuy',
      component: Market,
      meta: {
        title: 'Объявления',
        speedCostSwitch: true,
        adCreateButton: true,
        isMarketPage: true,
      },
      props: {
        isSellTab: false,
      },
    },
    {
      path: '/market/deal',
      redirect: { name: 'Deals' },
    },
    {
      path: '/market/my',
      name: 'MarketMy',
      component: Market,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Объявления',
        adCreateButton: true,
        isMarketPage: true,
      },
      props: {
        isDealTab: false,
        isSellTab: false,
        isMyTab: true,
      },
    },
    {
      path: '/wallet',
      name: 'Wallet',
      component: Wallet,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Кошелек',
      },
    },
    {
      path: '/wallet/deposit',
      name: 'Deposit',
      component: Wallet,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Кошелек - пополнение',
        backButton: true,
        content: 'deposit',
        backRouteName: 'Wallet',
      },
    },
    {
      path: '/wallet/withdraw',
      name: 'Withdraw',
      component: Wallet,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Кошелек - вывод',
        backButton: true,
        content: 'withdraw',
        backRouteName: 'Wallet',
      },
    },
    {
      path: '/wallet/whitelist',
      name: 'WhiteList',
      component: WhiteList,
      beforeEnter: ifAuthenticated,
      meta: {
        backButton: true,
        backRouteName: 'Wallet',
      },
    },
    {
      path: '/email/:type/:token',
      name: 'EmailConfirm',
      component: EmailConfirm,
      meta: {
        title: 'Подтверждение email',
        no_kyc: true,
      },
    },
    {
      path: '/terms-of-use',
      name: 'Terms',
      component: Terms,
      meta: {
        title: 'Пользовательское соглашение',
        no_kyc: true,
      },
    },
    {
      path: '/commissions',
      name: 'Commissions',
      component: Commissions,
      meta: {
        no_kyc: true,
        title: 'Комиссии и лимиты',
      },
    },
    {
      path: '/partnership',
      name: 'Partnership',
      component: Partnership,
      meta: {
        title: `Партнерская программа`
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: AuthForm,
      beforeEnter: beforeLogin,
      meta: {
        title: 'Вход',
        tab: 'login',
        isAuthorizationPage: true,
      },
      props({ query }) {
        return {
          nextRoute: query.next || '/',
        };
      },
    },
    {
      path: '/login/:partner_id',
      name: 'LoginReferral',
      component: AuthForm,
      beforeEnter: beforeLogin,
      meta: {
        title: 'Вход',
        tab: 'login',
        isAuthorizationPage: true,
      },
      props({ query }) {
        return {
          nextRoute: query.next || '/',
        };
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Market,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Выход',
      },
    },
    {
      path: '/registration',
      name: 'Registration',
      component: AuthForm,
      beforeEnter: beforeLogin,
      meta: {
        title: 'Регистрация',
        tab: 'register',
        isAuthorizationPage: true,
      },
      props({ query }) {
        return {
          nextRoute: query.next || '/',
        };
      },
    },
    {
      path: '/registration/:partner_id',
      name: 'RegistrationReferral',
      component: AuthForm,
      beforeEnter: beforeLogin,
      meta: {
        title: 'Регистрация',
        tab: 'register',
        isAuthorizationPage: true,
      },
      props({ query }) {
        return {
          nextRoute: query.next || '/',
        };
      },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      beforeEnter: ifUnauthenticated,
      meta: {
        title: 'Сброс пароля',
        no_kyc: true,
      },
    },
    {
      path: '/password/reset/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      beforeEnter: ifUnauthenticated,
      meta: {
        title: 'Сброс пароля',
        no_kyc: true,
      },
    },
    {
      path: '/user/:id',
      name: 'Profile',
      component: PublicProfile,
      meta: {
        title: 'Пользователь',
      },
    },
    {
      path: '/profile',
      name: 'AwesomeProfile',
      component: AwesomeProfile,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Профиль',
      },
      children: [
        {
          path: 'security',
          name: 'AwesomeProfileSecurity',
          component: AwesomeProfileSecurity,
          meta: {
            title: 'Профиль',
          },
        },
        {
          path: 'notifications',
          name: 'AwesomeProfileNotifications',
          component: AwesomeProfileNotifications,
          meta: {
            title: 'Профиль',
          },
        },
        {
          path: 'logs',
          name: 'AwesomeProfileLogs',
          component: AwesomeProfileLogs,
          meta: {
            title: 'Профиль',
          },
        },
      ],
    },
    {
      path: '/account/partnership',
      name: 'AccountPartnership',
      component: AccountPartnership,
      beforeEnter: ifAuthenticated,
      meta: {
        title: 'Партнерская программа'
      }
    },
    {
      path: '/create-offer/:step',
      name: 'AdCreate',
      component: AdCreate,
      beforeEnter: ifAuthenticated,
      meta: {
        backButton: true,
        title: 'Создание объявления',
      },
    },
    {
      path: '/deal/:id',
      name: 'Deal',
      component: Deal,
      beforeEnter: ifAuthenticated,
      meta: {
        backButton: true,
        backButtonTitle: 'Назад к сделкам',
      },
    },
    {
      path: '/edit-offer/:id/:step',
      name: 'AdEdit',
      component: AdEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        backButton: true,
        title: 'Редактирование объявления',
      },
    },
    {
      path: '/deal/:id/preview',
      name: 'DealCalculator',
      component: DealCalculator,
      meta: {
        backButton: true,
        backRouteName: 'Market',
        backButtonTitle: 'Назад к объявлениям',
      },
    },
    {
      //  404
      path: '/*',
      name: 'NotFound',
      component: NotFound,
    },
    {
      // 403, 423
      path: '/no-access',
      name: 'NotAccess',
      component: NotAccess,
    },
    {
      // 500
      path: '/500',
      name: 'ServerError',
      component: ServerError,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.params.saveScrollPosition) {
      return;
    }

    return window.FinexScroll.scrollTo({ y: 0 }, 500);
  }
});

router.beforeEach((to, from, next) => {
  if(!store.getters.isAuthenticated) {
    redirectIfAuthenticated(to, from, next)
    return
  }
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${title}`;
  } else {
    document.title = title;
  }
  if(to.query && to.query.partner_id){
    localStorage.setItem('partner_id', to.query.partner_id);
  }
  next();
});

export default router;
