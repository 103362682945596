export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SOCIAL = 'AUTH_SOCIAL';
export const AUTH_SOCIAL_REQUEST = 'AUTH_SOCIAL_REQUEST';
export const AUTH_SIGN_IN_TOKEN = 'AUTH_SIGN_IN_TOKEN';
export const AUTH_SOCIAL_SET_PARTNER = 'AUTH_SOCIAL_SET_PARTNER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const RESET_EMAIL = 'RESET_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SEND_NEW_IDEA = 'SEND_NEW_IDEA';
export const AUTH_LOGOUT_CLEAR = 'AUTH_LOGOUT_CLEAR';
export const CHECK_LOGIN_UNIQUE = 'CHECK_LOGIN_UNIQUE';
export const CHECK_EMAIL_UNIQUE = 'CHECK_EMAIL_UNIQUE';

