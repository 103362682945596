import {
  FACTOR_CONNECT,
  FACTOR_DISABLE,
  FACTOR_DISCONNECT,
  FACTOR_ENABLE,
  FACTOR_ERROR,
  FACTOR_LOGOUT_CLEAR,
  FACTOR_REQUEST
} from '../actions/twoFactor'
import Vue from 'vue'
import {HTTP_PUT} from "../actions/events";
import {PRIVATE_KEY_CONNECT} from "@/store/actions/twoFactor";


const state = {
  privateKey: '',
  factor: '',
  errors: {},
};

const getters = {
  getFactor: state => state.factor,
  getFactorErrors: state => state.errors,
  getPrivateKey: state => state.privateKey,
};

const actions = {
  [FACTOR_LOGOUT_CLEAR]: ({commit}) => {
    commit(FACTOR_LOGOUT_CLEAR);
  },
  [FACTOR_CONNECT]: ({commit, dispatch}, data) => {
    commit(FACTOR_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: FACTOR_CONNECT,
      params: {},
      auto: false,
      data: data,
    }).then((response) => {
      commit(FACTOR_REQUEST, response.data);
    }).catch((error) => {
      commit(FACTOR_ERROR, error);
      return Promise.reject();
    });
  },
  [PRIVATE_KEY_CONNECT]: ({commit, dispatch}, data) => {
    commit(FACTOR_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PRIVATE_KEY_CONNECT,
      params: {},
      auto: false,
      data: data,
    }).then((response) => {
      commit(PRIVATE_KEY_CONNECT, response.data);
    }).catch((error) => {
      commit(FACTOR_ERROR, error);
      return Promise.reject(error);
    });
  },
  [FACTOR_ENABLE]: ({commit, dispatch}, data) => {
    commit(FACTOR_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: FACTOR_ENABLE,
      params: {},
      auto: false,
      data: data,
    }).then((response) => {
      commit(FACTOR_REQUEST, response.data);
    }).catch((error) => {
      commit(FACTOR_ERROR, error);
      return Promise.reject(error);
    });
  },
  [FACTOR_DISABLE]: ({commit, dispatch}, data) => {
    commit(FACTOR_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: FACTOR_DISABLE,
      params: {},
      auto: false,
      data: data,
    }).then((response) => {
      commit(FACTOR_REQUEST, response.data);
    }).catch((error) => {
      commit(FACTOR_ERROR, error);
      return Promise.reject(error);
    });
  },
  [FACTOR_DISCONNECT]: ({commit, dispatch}, data) => {
    commit(FACTOR_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: FACTOR_DISCONNECT,
      params: {},
      auto: false,
      data: data,
    }).then((response) => {
      commit(FACTOR_REQUEST, response.data);
    }).catch((error) => {
      commit(FACTOR_ERROR, error);
      return Promise.reject(error);
    });
  },
};

const mutations = {
  [FACTOR_LOGOUT_CLEAR]: (state) => {
    state.factor = '';
    state.errors = {};
  },
  [FACTOR_REQUEST]: (state, resp) => {
    Vue.set(state, 'factor', resp)
  },
  [PRIVATE_KEY_CONNECT]: (state, resp) => {
    Vue.set(state, 'privateKey', resp.private_key)
  },
  [FACTOR_ERROR]: (state, error) => {
    Vue.set(state, 'errors', error)
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
