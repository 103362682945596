import {GET_GLOBAL_COMMISSIONS, GET_USER_COMMISSIONS} from '@/store/actions/commission';
import {HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT} from '../actions/events';
import Vue from "vue";

const state = {
  commissions: {
    global: {
      client_commission: 0,
      offer_commission: 0.2
    },
    min_fiat: {
      RUB: 1000,
    },
    pay_out: {
      standard: {
        btc: {
          commission: 0.0004,
          min_amount: 0.0001
        }
      },
      fast: {
        btc: {
          commission: 0.0008
        }
      }
    },
  },
  userCommissions: {
    total_calculated: {
      client_commission: {
        btc: 0
      },
      offer_commission: {
        btc: 0.2
      }
    }
  }
}

const getters = {
  getGlobalCommissions: state => state.commissions,
  getGlobalClientCommission: state => state.commissions.global.client_commission,
  getGlobalOfferCommission: state => state.commissions.global.offer_commission,
  getUserCommissions: state => state.userCommissions,
  getClientCommission: state => state.userCommissions.total_calculated.client_commission.btc,
  getOfferCommission: state => state.userCommissions.total_calculated.offer_commission.btc
}

const actions = {
  [GET_GLOBAL_COMMISSIONS]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: GET_GLOBAL_COMMISSIONS,
    }).then((response) => {
      commit(GET_GLOBAL_COMMISSIONS, response);
    })
  },
  [GET_USER_COMMISSIONS]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: GET_USER_COMMISSIONS,
    }).then((response) => {
      commit(GET_USER_COMMISSIONS, response.data)
    })
  }
}

const mutations = {
  [GET_GLOBAL_COMMISSIONS]: (state, data) => {
    Vue.set(state, 'commissions', data);
  },
  [GET_USER_COMMISSIONS]: (state, data) => {
    Vue.set(state, 'userCommissions', data);
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
};
