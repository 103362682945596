import {NOTE_DELETE_ONE, NOTE_DELETE_USER, NOTE_LIST, NOTE_LOGOUT_CLEAR, NOTE_PUT, NOTE_SET} from '../actions/note';
import Vue from 'vue'
import {HTTP_DELETE, HTTP_GET, HTTP_PUT} from "../actions/events";
import _ from 'lodash';

const state = {
  notes: {},
};

const getters = {
  getNotes: state => state.notes,
};

const actions = {
  [NOTE_LOGOUT_CLEAR]: ({commit}) => {
    commit(NOTE_LOGOUT_CLEAR);
  },
  [NOTE_LIST]: ({commit, dispatch}, data) => {
    dispatch(HTTP_GET, {
      method: NOTE_LIST,
      response: false,
      params: data ? data.params : {},
      data: {},
    }).then((response) => {
      if (response.data.length > 0) {
        _.each(response.data, (note) => {
          commit(NOTE_SET, note);
        });
      }
    });
  },
  [NOTE_PUT]: ({commit, dispatch}, {userId, data}) => {
    return dispatch(HTTP_PUT, {
      method: NOTE_PUT,
      params: {userId},
      response: false,
      data: {note: data},
    }).then((response) => {
      commit(NOTE_SET, response.data);
      return response.data;
    });
  },
  [NOTE_DELETE_ONE]: ({commit, dispatch}, noteId) => {
    dispatch(HTTP_DELETE, {
      method: NOTE_DELETE_ONE,
      params: {noteId},
      data: {},
    });
  },
  [NOTE_DELETE_USER]: ({commit, dispatch}, {userId, noteId}) => {
    dispatch(HTTP_DELETE, {
      method: NOTE_DELETE_USER,
      params: {userId},
      data: {},
      response: false,
    }).then((response) => {
      commit(NOTE_DELETE_ONE, {noteId, userId});
    });
  },
};

const mutations = {
  [NOTE_LOGOUT_CLEAR]: (state) => {
    state.notes = {};
  },
  [NOTE_SET]: (state, note) => {
    if (state.notes[note.id]) {
      Vue.set(state.notes[note.id], 'note', note.note);
      Vue.set(state.notes[note.id], 'updated_at', note.updated_at);
    } else {
      Vue.set(state.notes, note.id, note);
    }
  },
  [NOTE_DELETE_ONE]: (state, {noteId, userId}) => {
    if (!noteId) {
      let note = _.find(state.notes, (note) => {
        return note.recipient.id === userId;
      });
      if (note) {
        noteId = note.id;
      }
    }
    Vue.delete(state.notes, noteId);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
}
