import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import 'lodash';
import moment from 'moment';
import io from 'socket.io-client';
import VeeValidate, {Validator} from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru';
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
import Element from 'element-ui';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import cfg from '../config';
import VueAnalytics from 'vue-analytics';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueTheMask from 'vue-the-mask';


// import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(VueTheMask);

Vue.prototype.$moment = moment;
window.io = io;

Vue.component(VueCountdown.name, VueCountdown);
// Vue.use(TextareaAutosize);

Validator.localize('ru', ru);
Vue.use(VeeValidate, {
  locale: 'ru',
  fieldsBagName: 'fieldBags'
});

locale.use(lang);
Vue.use(Element);


Vue.config.productionTip = false;

// disable console log in production
if (process.env.NODE_ENV !== 'development') {
  console.log = function () {
  }
}

if (cfg.analytics === 'true') {

  // Google Analytics
  Vue.use(VueAnalytics, {
    id: 'UA-128511311-1',
    router
  });

  // Yandex analytic
  (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(52229581, "init", {
    id:52229581,
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  });
  console.log('Analytics enabled');
} else {
  console.log('Analytics disabled.');
}

Vue.component('multiselect', Multiselect);

Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: 'native',

    },
    rail: {
      size: "8px",
    },
    bar: {
      background: "#BCC9EF",
      keepShow: true,
    },
  },
});

new Vue({
  router,
  store,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  render: h => h(App),
  template: '<router-view/>',
}).$mount('#app');
