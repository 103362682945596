import {
  APPEAL_UPDATE,
  AVATAR_DELETE,
  AVATAR_UPDATE,
  EMAIL_CONFIRM,
  EMAIL_ERROR,
  EMAIL_START_CONFIRM,
  EMAIL_UPDATE,
  SOCIAL_EMAIL_UPDATE,
  SOCIAL_LOGIN_UPDATE,
  PASSWORD_ERROR,
  PASSWORD_UPDATE,
  PROFILE_MAKE_PARTNERSHIP,
  PROFILE_PARTNERSHIP_GET,
  PROFILE_SET_PARTNER_ID,
  SEARCH_USERS,
  SET_HEADERS,
  USER_BALANCE,
  USER_BALANCE_UPDATE,
  USER_BALANCE_FIAT,
  UPDATE_SELECTED_CURRENCY,
  USER_GEOLOCATION,
  USER_LOADED,
  USER_LOGOUT_CLEAR,
  USER_NOTIFY,
  USER_NOTIFY_AD,
  USER_NOTIFY_BLOCKED,
  USER_NOTIFY_CLEAR,
  USER_NOTIFY_DEAL,
  USER_NOTIFY_MESSAGE,
  USER_NOTIFY_ANALYTICS,
  USER_NOTIFY_READ,
  USER_NOTIFY_SYSTEM_DELETE,
  USER_NOTIFY_SYSTEM_UNREAD,
  USER_PROFILE,
  USER_PRO_RATING,
  USER_SECURITY_LOGS,
  USER_SESSIONS_LIST,
  USER_SPOOFING_PROTECTION,
  USER_WALLET_ADDRESS,
  USER_WALLET_COMISSIONS,
  USER_WALLET_HISTORY,
  USER_WALLET_WITHDRAW,
  SET_ROUTE_DATA, CHANGE_OPERATIONS_TAB,
  SOCKET_DASHBOARD,
  SOCKET_DASHBOARD_OFF,
  IMPORTANT_NOTIFICATION_LIST,
  IMPORTANT_NOTIFICATION_READ,
  IMPORTANT_NOTIFICATION_SOCKET,
  IMPORTANT_NOTIFICATION_SOCKET_OFF,
  LOAD_DEAL,
  LOAD_SUMMARY,
  PROFILE_REQUESTS,
  USER_WALLET_VALIDATE,
  USER_NOTIFY_CLAIM,
  USER_WALLET_WITHDRAW_AUTO_LIST,
  USER_WALLET_WITHDRAW_AUTO_DELETE,
  PROFILE_USER_IP_LIST,
  PROFILE_USER_IP_ADD,
  PROFILE_USER_IP_DELETE,
  USER_WALLET_ADD_PAYOUT,
  USER_WALLET_DELETE_PAYOUT,
  USER_WALLET_PAYOUT_LIST,
  USER_WALLET_ADDRESS_HISTORY,
  GET_USER_WALLET_HISTORY_PAGINATION,
  PROFILE_KYC,
} from '../actions/user';

import Vue from 'vue';
import {AUTH_LOGOUT, AUTH_REQUEST} from '../actions/auth'
import {DEAL_CHAT_OPEN, EVENT_OPEN, EVENT_CLOSE, HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT, LOADING} from "../actions/events";
import {DEAL_CHAT_PLACED, DEAL_LISTEN, DEAL_CHANGES_INC} from "../actions/deal";
import {
  DASHBOARD_DEAL_DELETE,
  DASHBOARD_DEAL_UPDATE,
  DASHBOARD_DELETE,
  DASHBOARD_UPDATE, GET_ACTIVE_DEALS_COUNT
} from "../actions/dashboard";
import {ADS_FILTER, ADS_RESET_GEOINFO} from "../actions/markets";
import {AD_DELETE, AD_UPDATE} from "../actions/ad";
import {SETTINGS_REQUEST} from "../actions/settings";
import {GET_INCOMING_CLAIMS, GET_OUTGOING_CLAIMS} from "../actions/claims";

const state = {
  profile: {},
  balance: [],
  password: {}, // TODO: удалить
  notifications: {
    system: [],
    messages: [],
  },
  email: '',
  geoinfo: null,
  statuses: {
    password: '',
  },
  defaultAvatar: `/img/default-avatar.png`,
  changeEmailErrors: {},
  changePasswordErrors: {},
  searchUsers: [],
  userWalletAddress: {
    btc: '',
    eth: '',
  },
  partnershipRequests: [],
  userWalletHistory: {},
  userWalletComissions: {
    pay_in: {},
    pay_out: {},
  },
  userAutoWithdrawList: {},
  payOutList: [],
  userWalletAddressHistory: [],
  userWalletAddressHistoryPagination: {},
  userSecurityLogs: [],
  userSessionsList: [],
  userSpoofingData: {},
  importantNotification: [],
  selectedCurrency: 'rub', // по умолчанию рубли
  routeData: {
    title: '',
    path: '',
    analytics: '',
  },
  currentOperationTab: 'activeOperations',
  ipList: [],
};

const getters = {
  getSelectedCurrency: state => state.selectedCurrency,
  getImportantNotification: state => state.importantNotification,
  getAvatar: state => self => {
    if (self === undefined) {
      return state.profile.avatar || state.defaultAvatar;
    }
    if (self === 'default' || self === null || self.avatar === null) {
      return state.defaultAvatar;
    }
    if (self) {
      return self.avatar || self;
    }
  },
  getIsProUser: state => state.profile.pro_trader || state.profile.pro_partner,
  getProfile: state => state.profile,
  getBalance: state => state.balance.find(item => item.amount) ? state.balance.find(item => item.amount) : 0,
  getUserId: state => state.profile.id,
  getUserWalletAddress: state => state.userWalletAddress,
  getUserWalletHistory: state => state.userWalletHistory,
  getUserWalletCommissions: state => state.userWalletComissions,
  getUserAutoWithdrawList: state => state.userAutoWithdrawList,
  getUserSecurityLogs: state => state.userSecurityLogs,
  gerUserSessionsList: state => state.userSessionsList,
  getUserSpoofingData: state => state.userSpoofingData,
  getEmailChangeErrors: (state) => state.changeEmailErrors,
  getchangePasswordErrors: (state) => state.changePasswordErrors,
  getRouteData: (state) => state.routeData,
  getUserIPList: (state) => state.ipList,
  getUserPayOutList: (state) => state.payOutList,
  getUserWalletAddressHistory: (state) => state.userWalletAddressHistory,
  getUserWalletAddressHistoryPagination: (state) => state.userWalletAddressHistoryPagination,
  isKycRequiredAndConfirmed: (state) => !!state.profile?.is_kyc_required && !!state.profile?.is_kyc_confirmed
};

const actions = {
  [USER_LOGOUT_CLEAR]: ({commit}) => {
    commit(USER_LOGOUT_CLEAR);
  },
  [USER_PROFILE]: ({commit, dispatch, state, rootState}) => {
    commit(LOADING, {name: USER_LOADED, value: 'loading'});
    return dispatch(HTTP_GET, {
      method: USER_PROFILE,
    }).then(({data}) => {
      return new Promise(resolve => {
        dispatch(USER_GEOLOCATION)
          .then((response) => {
            commit(ADS_FILTER, {key: 'currency_id', value: response.data.currency.value});
            commit(ADS_RESET_GEOINFO, true);
            resolve({data});
          });
      });
    }).then(({data}) => {
      dispatch(USER_BALANCE, {id: data.id});
      dispatch(IMPORTANT_NOTIFICATION_LIST);
      dispatch(IMPORTANT_NOTIFICATION_SOCKET);
      dispatch(SETTINGS_REQUEST);
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'Chat',
        method(chat) {
          commit(DEAL_CHAT_PLACED, chat);
          dispatch(DEAL_CHAT_OPEN, {
            chatId: chat.id,
            dealId: chat.deal_id,
            pagination: {page: 1},
          });
        },
        event: 'ChatPlaced',
      });
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'Chat',
        event: 'MessagePlaced',
        method(chat) {
          let type = 'deal';
          commit(DEAL_CHANGES_INC, {dealId: chat.deal_id});
          if (rootState.settings.options.web.message_notification) {
            commit(USER_NOTIFY_MESSAGE, {chat, type});
          }
        },
      });
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'User',
        event: 'Locked',
        method(response) {
          commit(USER_NOTIFY_BLOCKED);
        },
      });
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'Deal',
        method(deal, event) {
          if (deal && deal.id) {
            dispatch(HTTP_GET, {
              method: DEAL_LISTEN,
              params: {id: deal.id},
            }).then((response) => {
              let responseDeal = response.data;
              dispatch(GET_ACTIVE_DEALS_COUNT);

              const deal_notification = rootState.settings.options.web.deal_notification;
              const deal_buy_notification = rootState.settings.options.web.deal_buy_notification;
              const deal_sale_notification = rootState.settings.options.web.deal_sale_notification;
              if (deal_notification || deal_buy_notification || deal_sale_notification) {
                const deal_placed = event === 'DealPlaced';

                let cases = {
                  is_sale: deal_sale_notification && responseDeal.is_sale && deal_placed, // Включены уведомления на продажу
                  is_buy: deal_buy_notification && !responseDeal.is_sale && deal_placed, // Включены уведомления на покупку
                  deals: deal_notification, // Включены уведомления сделок
                }

                for (const caseKey in cases) {
                  if (cases[caseKey]) {
                    if (event === 'DealPlaced' && responseDeal.author.login !== rootState.user.profile.login) {
                      commit(USER_NOTIFY_DEAL, {deal: responseDeal, event});
                    } else if (event !== 'DealPlaced') {
                      commit(USER_NOTIFY_DEAL, {deal: responseDeal, event});
                    }
                    break;
                  } else if (
                    caseKey === 'is_buy' // На последней итерации перед deals проверяем
                    && (cases.deals && (!cases.is_sale || !cases.is_buy)) // Включены deals и есть выключенные другие
                    && deal_placed // Создание сделки
                    && (deal_buy_notification || deal_sale_notification) // Есть включенный хотя бы один другой, кроме deals
                  ) { // Отключаем уведомление о создании сделки, если выключены настройки либо is_sale, либо is_buy
                    break;
                  }
                }
              }
              if(state.currentOperationTab === 'activeOperations'){
                if(responseDeal.status.title === 'Finished'
                  || responseDeal.status.title === 'Canceled'
                  || responseDeal.status.title === 'On time check'
                ){
                  commit(DASHBOARD_DEAL_DELETE, responseDeal);
                } else {
                  dispatch(DASHBOARD_DEAL_UPDATE, { deal: responseDeal, to_top: true });
                }
              } else {
                if(responseDeal.status.title === 'Finished'
                  || responseDeal.status.title === 'Canceled'
                  || responseDeal.status.title === 'On time check'
                ){
                  dispatch(DASHBOARD_DEAL_UPDATE, { deal: responseDeal, to_top: true });
                }
              }
              const changesEvents = [
                'DealCanceled',
                'DealDisputed',
                'DealPaid',
                'DealSellerRequisite',
              ];
              if (changesEvents.indexOf(event) !== -1) {
                commit(DEAL_CHANGES_INC, {dealId: responseDeal.id});
              }
            });
          }
        },
        event: [
          'DealCanceled',
          'DealCancellation',
          'DealDisputed',
          'DealFinishing',
          'DealFinished',
          'DealVerified',
          'DealPlaced',
          'DealPaid',
          'DealOnTimeCheck',
          'DealFinishedFinal',
          'DealCanceledFinal',
          'DealSellerRequisite',
        ],
      });
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'Claim',
        event: [
          'ClaimOpened',
          'ClaimApplying',
          'ClaimApplied',
          'ClaimCanceled',
          'ClaimDeleted',
          'ClaimRejected',
          'ClaimPending',
          'ProClaimAutoApplying',
        ],
        method(claim, event) {
          if (rootState.settings.options.web.claim_notification) {
            commit(USER_NOTIFY_CLAIM, {claim, event});
          }
          if (rootState.settings.options.web.pro_claim_applied_notification && event === 'ProClaimAutoApplying') {
            commit(USER_NOTIFY_CLAIM, {claim, event});
          }
          if (!rootState.claims.isUserSendRequest) {
            if (claim.indicted.login === state.profile.login) {
              dispatch(GET_INCOMING_CLAIMS, {closed: rootState.claims.isClosed, page: 1, per_page: 20})
            } else {
              dispatch(GET_OUTGOING_CLAIMS, {closed: rootState.claims.isClosed, page: 1, per_page: 20})
            }
          }
        },
      });
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-users.${data.id}`,
        group: 'Statistics',
        method(data, event) {
          if (rootState.settings.options.web.security_notification) {
            commit(USER_NOTIFY_ANALYTICS, {event})
          }
        },
        event: [
          'SimpleDealsStatsExported',
          'SummaryDealsStatsExported'
        ],
      });
      dispatch(SOCKET_DASHBOARD, {id: data.id});
      commit(LOADING, {name: USER_LOADED, value: 'loaded'});
      return Promise.resolve();
    }).catch((e) => {
      commit(LOADING, {name: USER_LOADED, value: 'error'});
      console.log('AUTH_LOGOUT ERROR', e);
    });
  },
  [SOCKET_DASHBOARD]: ({commit, dispatch}, {id}) => {
    dispatch(EVENT_OPEN, {
      type: 'private',
      channel: `private-users.${id}`,
      group: 'Ad',
      async method(ad) {
        if (ad && ad.id) {
          if (ad.is_deleted) {
            commit(AD_DELETE, ad);
            commit(DASHBOARD_DELETE, ad);
          } else {
            commit(AD_UPDATE, ad);
            commit(DASHBOARD_UPDATE, ad);
          }
        }
      },
      event: [
        'AdActivated',
        'AdDeactivated',
        'AdDeleted',
        'AdPlaced',
        'AdUpdated',
      ],
    });
  },
  [SOCKET_DASHBOARD_OFF]: ({commit, dispatch}, {id}) => {
    dispatch(EVENT_CLOSE, {channel: `users.${id}`});
  },
  [USER_GEOLOCATION]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: USER_GEOLOCATION,
    });
  },
  [USER_BALANCE_UPDATE]:({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: USER_BALANCE,
    }).then((data) => {
      commit(USER_BALANCE, data.data)
    });
  },
  [USER_BALANCE]: ({commit, dispatch, rootState}, data) => {
    return dispatch(HTTP_GET, {
      method: USER_BALANCE,
    }).then(() => {
      dispatch(EVENT_OPEN, {
        type: 'private',
        channel: `private-balance.${data.id}`,
        group: 'Balance',
        method() {
          if (rootState.events.loading[USER_BALANCE] !== 'loading') {
            dispatch(USER_BALANCE_UPDATE)
          }
        },
        event: [
          'CryptoAmountChanged',
          'TotalFiatAmountChanged'
        ],
      });
    });
  },
  [APPEAL_UPDATE]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_PUT, {
      method: APPEAL_UPDATE,
      data,
    });
  },
  [PASSWORD_UPDATE]: ({commit, dispatch}, data) => {
    commit(PASSWORD_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: PASSWORD_UPDATE,
      data,
    }).then((response) => {
      commit(SET_HEADERS, {
        key: 'Authorization',
        value: 'Bearer ' + response.data.token,
      });
      commit(AUTH_REQUEST, {token: response.data.token});
      return response;
    }).catch((error) => {
      commit(PASSWORD_ERROR, error);
      return Promise.reject();
    });
  },
  [EMAIL_UPDATE]: ({ commit, dispatch }, data) => {
    commit(EMAIL_ERROR, {});
    return dispatch(HTTP_PUT, {
      method: EMAIL_UPDATE,
      data,
    })
      .then(response => {
        dispatch(USER_PROFILE);
        return response;
      })
      .catch((error) => {
        commit(EMAIL_ERROR, error);
        return Promise.reject(error);
      });
  },
  [EMAIL_CONFIRM]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_PUT, {
      method: EMAIL_CONFIRM,
      params: data.params,
      data: {
        token: data.token,
      },
      response: false,
    }).then((response) => {
      if (response.data && response.data.api_token) {
        commit(SET_HEADERS, {
          key: 'Authorization',
          value: 'Bearer ' + response.data.api_token,
        });
        commit(AUTH_REQUEST, {token: response.data.api_token});
      }
      return Promise.resolve();
    })
  },
  [EMAIL_START_CONFIRM]: ({commit, dispatch}) => {
    return dispatch(HTTP_PUT, {
      method: EMAIL_START_CONFIRM,
      response: false,
    });
  },
  [AVATAR_UPDATE]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: AVATAR_UPDATE,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data.img,
    }).then(response => {
      dispatch(USER_PROFILE);
      return response;
    });
  },
  [AVATAR_DELETE]: ({commit, dispatch}) => {
    return dispatch(HTTP_DELETE, {
      method: AVATAR_DELETE,
    }).then(response => {
      dispatch(USER_PROFILE);
      return response;
    });
  },
  [SEARCH_USERS]: ({commit, dispatch}, login) => {
    return dispatch(HTTP_GET, {
      method: SEARCH_USERS,
      data: {
        params: {login},
      },
      response: false,
    }).then(response => {
      return response.data
    })
  },
  [USER_WALLET_ADDRESS]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      params: {cryptoCode: data.cryptoCode},
      method: USER_WALLET_ADDRESS,
      response: false
    }).then((response) => {
      commit(USER_WALLET_ADDRESS, {cryptoCode: data.cryptoCode, wallet: response.data.wallet_id})
    });
  },
  [USER_WALLET_VALIDATE]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: USER_WALLET_VALIDATE,
      response: false,
      data,
    }).catch((error) => {
      return error;
    });
  },
  [USER_WALLET_WITHDRAW]: ({dispatch, rootState}, data) => {
    return dispatch(HTTP_POST, {
      data: data.data,
      method: data.paid || USER_WALLET_WITHDRAW,
      response: false,
    }).then((response) => {
      return response;
    }).finally(() => {
      if (rootState.events.loading[USER_BALANCE] !== 'loading') {
        dispatch(USER_BALANCE_UPDATE)
      }
    });
  },
  [USER_WALLET_HISTORY]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      params: {cryptoCode: data.cryptoCode},
      data: {params: data.filter},
      method: data.method,
      response: false
    }).then((response) => {
      commit(USER_WALLET_HISTORY, response)
    });
  },
  [USER_WALLET_COMISSIONS]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: USER_WALLET_COMISSIONS,
      data,
    });
  },
  [USER_WALLET_WITHDRAW_AUTO_LIST]: ({commit, dispatch}, { cryptoCode }) => {
    return dispatch(HTTP_GET, {
      params: { cryptoCode },
      method: USER_WALLET_WITHDRAW_AUTO_LIST,
    });
  },
  [USER_WALLET_WITHDRAW_AUTO_DELETE]: ({dispatch}, { google2fa_secret, otp }) => {
    return dispatch(HTTP_PUT, {
      method: USER_WALLET_WITHDRAW_AUTO_DELETE,
      data: { google2fa_secret, otp },
      response: false,
    })
  },
  [USER_WALLET_PAYOUT_LIST]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: USER_WALLET_PAYOUT_LIST,
      data,
    });
  },
  [USER_WALLET_ADD_PAYOUT]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: USER_WALLET_ADD_PAYOUT,
      auto: false,
      response: false,
      data,
    })
  },
  [USER_WALLET_DELETE_PAYOUT]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_DELETE, {
      method: USER_WALLET_DELETE_PAYOUT,
      auto: false,
      response: false,
      params: {id},
      body: {data}
    })
  },
  [USER_WALLET_ADDRESS_HISTORY]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: USER_WALLET_ADDRESS_HISTORY,
      data: {params: data},
    }).then((response) => {
      commit(USER_WALLET_ADDRESS_HISTORY, response.wallets)
      commit(GET_USER_WALLET_HISTORY_PAGINATION, response);
    });
  },
  [USER_PRO_RATING]: ({dispatch}) => {
    return dispatch(HTTP_GET, {
      method: USER_PRO_RATING,
    })
  },
  [USER_SECURITY_LOGS]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      data: {params: data.filter},
      method: USER_SECURITY_LOGS,
      response: false
    }).then((response) => {
      commit(USER_SECURITY_LOGS, response)
    });
  },
  [USER_SESSIONS_LIST]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      data: {params: data.filter},
      method: USER_SESSIONS_LIST,
      response: false
    }).then((response) => {
      commit(USER_SESSIONS_LIST, response)
    });
  },
  [USER_SPOOFING_PROTECTION]: ({dispatch, commit}) => {
    return dispatch(HTTP_GET, {
      method: USER_SPOOFING_PROTECTION,
      response: false
    }).then((response) => {
      commit(USER_SPOOFING_PROTECTION, response);
    }).catch(() => {
      console.log('Ошибка');
    });
  },
  [IMPORTANT_NOTIFICATION_LIST]: ({dispatch, commit}) => {
    return dispatch(HTTP_GET, {
      method: IMPORTANT_NOTIFICATION_LIST,
      response: false
    }).then((response) => {
      commit(IMPORTANT_NOTIFICATION_LIST, response.data);
    });
  },
  [IMPORTANT_NOTIFICATION_READ]: ({dispatch}, data) => {
    return dispatch(HTTP_POST, {
      data: {
        notifications: data,
      },
      method: IMPORTANT_NOTIFICATION_READ,
      response: false
    }).then(() => {
      dispatch(IMPORTANT_NOTIFICATION_LIST);
    })
  },
  [IMPORTANT_NOTIFICATION_SOCKET]: ({commit, dispatch}) => {
    dispatch(EVENT_OPEN, {
      type: 'private',
      channel: `private-notifications`,
      group: 'Notification',
      method(response) {
        let notifications = [];
        notifications.push(response);
        commit(IMPORTANT_NOTIFICATION_LIST, notifications);
      },
      event: [
        'NotificationPlaced',
      ],
    });
  },
  [IMPORTANT_NOTIFICATION_SOCKET_OFF]: ({dispatch}) => {
    dispatch(EVENT_CLOSE, {channel: `notifications`});
  },
  [PROFILE_PARTNERSHIP_GET]: ({dispatch}) => {
    return dispatch(HTTP_GET, {
      method: PROFILE_PARTNERSHIP_GET,
      response: false,
    }).then((response) => {
      if (!_.isEmpty(response)) {
        return response.data;
      } else {
        return false;
      }
    })
  },
  [PROFILE_REQUESTS]: ({dispatch}) => {
    return dispatch(HTTP_GET, {
      method: PROFILE_REQUESTS
    })
  },
  [PROFILE_MAKE_PARTNERSHIP]: ({dispatch}) => {
    return dispatch(HTTP_POST, {
      method: PROFILE_MAKE_PARTNERSHIP,
      response: false,
    }).then((response) => {
      if (!_.isEmpty(response)) {
        return response.data;
      } else {
        return false;
      }
    })
  },
  [SOCIAL_EMAIL_UPDATE]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_PUT, {
      data,
      method: SOCIAL_EMAIL_UPDATE,
      response: false,
    }).then((response) => {
      commit(SOCIAL_EMAIL_UPDATE, data);
      return true;
    }).catch((error) => {
      console.log('Error while updating email in social profile', error);
      return false;
    });
  },
  [SOCIAL_LOGIN_UPDATE]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_PUT, {
      data,
      method: SOCIAL_LOGIN_UPDATE,
      response: false,
    }).then((response) => {
      commit(SOCIAL_LOGIN_UPDATE, data);
      return response;
    }).catch((error) => {
      return error;
    });
  },
  [UPDATE_SELECTED_CURRENCY]: ({commit}, data) => {
    localStorage.setItem('selectedCurrency', data);
    commit(UPDATE_SELECTED_CURRENCY, data);
  },
  [LOAD_DEAL]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      data: {
        params: {
        startDate: data[0],
        endDate: data[1],
        currency: 'rub',
        crypto: 'btc',
        }
      },
      timeoutOff: true,
      method: LOAD_DEAL,
      auto: false,
    });
  },
  [LOAD_SUMMARY]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_GET, {
      data: {
        params: {
        startDate: data[0],
        endDate: data[1],
        currency: 'rub',
        crypto: 'btc',
        }
      },
      timeoutOff: true,
      method: LOAD_SUMMARY,
      auto: false,
    });
  },
  [PROFILE_USER_IP_LIST]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: PROFILE_USER_IP_LIST,
      data,
    });
  },
  [PROFILE_USER_IP_ADD]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: PROFILE_USER_IP_ADD,
      auto: false,
      response: false,
      data,
    })
  },
  [PROFILE_USER_IP_DELETE]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_DELETE, {
      method: PROFILE_USER_IP_DELETE,
      auto: false,
      response: false,
      params: {id},
      body: {data}
    })
  },

  [PROFILE_KYC]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: PROFILE_KYC,
      response: false,
      data,
    });
  },
};

const mutations = {
  [USER_LOGOUT_CLEAR]: (state) => {
    state.profile = {};
    state.balance = {};
    state.password = {};
    state.notifications = {
      system: [],
    };
    state.email = '';
    state.geoinfo = null;
    state.statuses = {
      password: '',
    };
    state.changeEmailErrors = {};
    state.changePasswordErrors = {};
    state.searchUsers = [];
    state.userWalletAddress = {
      btc: '',
      eth: '',
    };
    state.userWalletHistory = {};
    state.userWalletComissions = {};
    state.userSecurityLogs = [];
    state.userSessionsList = [];
    state.userSpoofingData = {};
  },
  [USER_WALLET_ADDRESS]: (state, data) => {
    Vue.set(state.userWalletAddress, data.cryptoCode, data.wallet);
  },
  [UPDATE_SELECTED_CURRENCY]: (state, data) => {
    Vue.set(state, 'selectedCurrency', data);
  },
  [IMPORTANT_NOTIFICATION_LIST]: (state, data) => {
    Vue.set(state, 'importantNotification', data)
  },
  [USER_WALLET_HISTORY]: (state, data) => {
    Vue.set(state, 'userWalletHistory', data);
  },
  [USER_WALLET_COMISSIONS]: (state, data) => {
    if(data && data.pay_out && data.pay_out.btc) {
      for (const [index, value] of data.pay_out.btc.entries()) {
        data.pay_out.btc[index].commission = parseFloat(value.commission);
        data.pay_out.btc[index].min_amount = parseFloat(value.min_amount);
      }
    }
    Vue.set(state, 'userWalletComissions', data);
  },
  [USER_WALLET_WITHDRAW_AUTO_LIST]: (state, data) => {
    Vue.set(state, 'userAutoWithdrawList', data);
  },
  [USER_SECURITY_LOGS]: (state, data) => {
    Vue.set(state, 'userSecurityLogs', data);
  },
  [USER_SESSIONS_LIST]: (state, data) => {
    Vue.set(state, 'userSessionsList', data);
  },
  [USER_SPOOFING_PROTECTION]: (state, data) => {
    Vue.set(state, 'userSpoofingData', data.data);
  },
  [USER_PROFILE]: (state, data) => {
    Vue.set(state, 'profile', data);
  },
  [USER_NOTIFY_SYSTEM_UNREAD]: (state, message) => {
    let index = state.notifications.system.findIndex((msg) => {
      return msg.message === message;
    });
    Vue.set(state.notifications.system[index], 'read', true);
  },
  [USER_NOTIFY_SYSTEM_DELETE]: (state, message) => { // TODO: объеденить
    let index = state.notifications.system.findIndex((msg) => {
      return msg.message === message;
    });
    if (state.notifications.system[index]) {
      Vue.delete(state.notifications.system, index);
    }
  },
  [USER_NOTIFY_CLEAR]: (state, type) => {
    Vue.set(state.notifications, type, []);
  },
  [USER_NOTIFY_MESSAGE]: (state, {chat, type}) => {
    let user = state.profile.id === chat.chat.recipients[0].id ?
      chat.chat.recipients[1] :
      chat.chat.recipients[0];

    if (state.profile.id !== chat.author_id) {
      state.notifications.messages.push({
        type,
        id: chat.id,
        router: {name: 'Deal', params: {id: chat.deal_id}},
        read: false,
        date: Date.now(),
        message: `Сообщение по сделке № ${chat.deal_id}: ${chat.message}`,
      });
      Vue.set(state.notifications, 'messages', state.notifications.messages);
    }
  },
  [USER_NOTIFY_DEAL]: (state, {deal, event}) => {
    const messages = {
      default: `Произошло событие в сделке между пользователем`,
      DealVerified: `Сделка № ${deal.id} была подтверждена системой`,
      DealOnTimeCheck: `Сделка № ${deal.id} на проверке таймеров`,
      DealCancellation: `Сделка № ${deal.id} отменяется системой`,
      DealCanceled: `Сделка № ${deal.id} была помечена отмененной`,
      DealPaid: `Сделка № ${deal.id} была помечена оплаченной`,
      DealPlaced: `Новая сделка на ${deal.is_sale ? 'продажу' : 'покупку'} № ${deal.id} была создана`,
      DealDisputed: `Сделка № ${deal.id} была оспорена`,
      DealFinished: `Сделка № ${deal.id} была завершена`,
      DealFinishedFinal: `Сделка № ${deal.id} была проверена администратором`,
      DealCanceledFinal: `Сделка № ${deal.id} была проверена администратором`,
    };

    if (messages[event] || messages.default) {
      state.notifications.system.push({
        router: {name: 'Deal', params: {id: deal.id}},
        read: false,
        data: deal,
        date: Date.now(),
        message: messages[event] || messages.default,
      });
    }

    Vue.set(state.notifications, 'system', state.notifications.system);
  },
  [USER_NOTIFY_CLAIM]: (state, { claim, event }) => {
    const messages = {
      default: `Произошло событие в претензиях между пользователем`,
      ClaimOpened: `Претензия № ${claim.id} была открыта`,
      ClaimApplied: `Претензия № ${claim.id} принята`,
      ClaimApplying: `Претензия № ${claim.id} в процессе принятия`,
      ClaimCanceled: `Претензия № ${claim.id} отменена`,
      ClaimDeleted: `Претензия № ${claim.id} удалена`,
      ClaimRejected: `Претензия № ${claim.id} отклонена`,
      ProClaimAutoApplying: `Претензия № ${claim.id} автоматически закрыта`,
    };

    state.notifications.system.push({
      router: {name: 'Claims',},
      read: false,
      data: claim,
      date: Date.now(),
      message: messages[event] || messages.default,
    });

    Vue.set(state.notifications, 'system', state.notifications.system);
  },
  [USER_NOTIFY_ANALYTICS]: (state, { event }) => {
    const messages = {
      SimpleDealsStatsExported: 'Ваш общий отчет сформирован, проверьте Email',
      SummaryDealsStatsExported: 'Ваш полный отчет сформирован, проверьте Email'
    };

    state.notifications.system.push({
      read: false,
      date: Date.now(),
      message: messages[event]
    });

    Vue.set(state.notifications, 'system', state.notifications.system);
  },
  [USER_NOTIFY_AD]: (state, data) => { // TODO: сейчас не вызывается
    state.notifications.system.push({
      router: {name: 'Deal', params: {id: data.id}},
      read: false,
      date: '',
      data,
      message: 'Объявление было изменено', // TODO: message
    });
    Vue.set(state.notifications, 'system', state.notifications.system);
  },
  [USER_NOTIFY_BLOCKED]: (state) => {
    state.notifications.system.push({
      router: false,
      read: false,
      date: Date.now(),
      message: 'Вашу учетную запись заблокировали',
    });
    Vue.set(state.notifications, 'system', state.notifications.system);
  },
  [USER_NOTIFY]: (state, {type, value}) => {
    if (state.notifications[type]) {
      if (!value.read) {
        value.read = false;
      }
      state.notifications[type].push(value);
    }
  },
  [USER_NOTIFY_READ]: (state, {type}) => {
    if (state.notifications[type]) {
      _.each(state.notifications[type], (item) => {
        item.read = true;
      });
    }
  },
  [APPEAL_UPDATE]: (state, data) => {
    Vue.set(state.profile, 'appeal', data.appeal);
  },
  [PASSWORD_UPDATE]: (state, data) => {
    Vue.set(state, 'password', data);
  },
  [EMAIL_UPDATE]: (state, data) => {
    Vue.set(state, 'email', data);
  },
  [AVATAR_UPDATE]: (state, data) => {
    Vue.set(state, 'avatar', data);
  },
  [USER_BALANCE]: (state, data) => {
    Vue.set(state, 'balance', data);
  },
  [SET_ROUTE_DATA]: (state, data) => {
    Vue.set(state, 'routeData', data);
  },
  [USER_BALANCE_FIAT]: (state, data) => {
    Vue.set(state.profile, 'totalBalanceInUSD', data.total_amount);
  },
  [USER_GEOLOCATION]: (state, data) => {
    Vue.set(state, 'geoinfo', data);
  },
  [AUTH_LOGOUT]: (state) => {
    Vue.set(state, 'profile', {});
  },
  [EMAIL_ERROR]: (state, errors) => {
    Vue.set(state, 'changeEmailErrors', errors);
  },
  [PASSWORD_ERROR]: (state, errors) => {
    Vue.set(state, 'changePasswordErrors', errors);
  },
  [PROFILE_SET_PARTNER_ID]: (state, partner_id) => {
    if (partner_id) {
      localStorage.setItem('partner_id', partner_id);
    }
  },
  [SOCIAL_EMAIL_UPDATE]: (state, data) => {
    Vue.set(state.profile, 'email', data.email);
    Vue.set(state.profile, 'can_set_email', false);
  },
  [SOCIAL_LOGIN_UPDATE]: (state, data) => {
    Vue.set(state.profile, 'login', data.login);
    Vue.set(state.profile, 'can_set_login', false);
  },
  [CHANGE_OPERATIONS_TAB]: (state, operation) => {
    Vue.set(state, 'currentOperationTab', operation);
  },
  [PROFILE_REQUESTS]: (state, data) => {
    state.partnershipRequests = data;
  },
  [PROFILE_USER_IP_LIST]: (state, data) => {
    Vue.set(state, 'ipList', data);
  },
  [USER_WALLET_PAYOUT_LIST]: (state, data) => {
    Vue.set(state, 'payOutList', data);
  },
  [USER_WALLET_ADDRESS_HISTORY]: (state, data) => {
    Vue.set(state, 'userWalletAddressHistory', data);
  },
  [GET_USER_WALLET_HISTORY_PAGINATION]: (state, data) => {
    const { page, per_page, total, total_pages } = data
    const pagination = { page, per_page, total, total_pages }
    Vue.set(state, 'userWalletAddressHistoryPagination', pagination);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
