<template lang="pug">
.header-desktop__notifications(@click="toggleModal")
  span(v-if="countAllNotifications") {{countAllNotifications}}

  .notifications-container(:class="{'active':modalVisible}")
    .notifications-container__block(v-if="countAllNotifications===0")
      h5 Уведомлений нет

    button.ml-auto.mr-auto.mb-3.btn.btn__main(@click="clearAllNotifications", v-if="countAllNotifications!==0")
      | Очистить уведомления
    .notifications-container__block(
      v-for='info in system',
      :key='info.id'
    )
      p(@click="goToSystem(info)") {{ info.message }}
    .notifications-container__block(
      v-for='info in messages',
      :key='info.id'
    )
      p(@click="goToChat(info)") {{ info.message }}

</template>

<script>
import {SETTINGS_REQUEST} from '@/store/actions/settings';
import {mapGetters} from 'vuex';
import notifications from '@/components/mixins/notifications';

export default {
  name: 'Notifications',
  mixins: [
    notifications,
  ],
  data() {
    return {
      modalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      'loading': 'loading',
    }),
    isLoading() {
      return this.loading(SETTINGS_REQUEST) === 'loading';
    },
  },
  watch: {
    'countAllNotifications' (newVal, oldVal) {
      if (newVal > oldVal) {
        this.playSound();
      }
    },
  },
  created() {
    let self = this;

    window.addEventListener('click', function(e){
      if (!self.$el.contains(e.target)){
        self.modalVisible = false
      }
    })
  },
  methods: {
    goToSystem(info) {
      if (info.router) {
        this.removeSystem(info);
        this.$router.push(info.router);
      }
    },
    goToChat(info) {
      if (info.router) {
        this.removeMessage(info);
        this.$router.push(info.router);
      }
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
