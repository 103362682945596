export const DEAL = 'DEAL';
export const DEAL_LISTEN = 'DEAL_LISTEN';
export const DEAL_UPDATE = 'DEAL_UPDATE';
export const DEAL_ACTION = 'DEAL_ACTION';
export const DEAL_STOP_LISTEN = 'DEAL_STOP_LISTEN';
export const DEAL_CREATE = 'DEAL_CREATE';
export const DEAL_CHAT_MESSAGE = 'DEAL_CHAT_MESSAGE';
export const DEAL_CHAT_PLACED = 'DEAL_CHAT_PLACED';
export const DEAL_CHAT_GET_MESSAGES = 'DEAL_CHAT_GET_MESSAGES';
export const DEAL_GET_REVIEWS = 'DEAL_GET_REVIEWS';
export const DEAL_SEND_REVIEW = 'DEAL_SEND_REVIEW';
export const DEAL_SET_REVIEW = 'DEAL_SET_REVIEW';
export const DEAL_UPDATE_REVIEW = 'DEAL_UPDATE_REVIEW';
export const DEAL_LOGOUT_CLEAR = 'DEAL_LOGOUT_CLEAR';
export const DEAL_CHANGES_INC = 'DEAL_CHANGES_INC';
export const DEAL_CHANGES_RESET = 'DEAL_CHANGES_RESET';
export const DEAL_CLAIMS = 'DEAL_CLAIMS';
export const DEALS_FILTER_SET = 'DEALS_FILTER_SET';
