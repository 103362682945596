<template lang="pug">
.desktop-menu__section__content
  .rates__item(
    :key="key"
    v-for="(rate, key) in Rates"
    :style="`order: ${rate.name === 'binance'? 1 : 2}`"
  )
    .line
      .exchange {{ rate.name }}
      .rate {{ rate.rate }}&nbsp; {{ currency }}
    span.difference(v-if="rate.name !== 'binance'") {{ getDifference(rate.difference) }}
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {REQUEST_RATES} from "@/store/actions/dashboard";
import {EVENT_RATES_OPEN} from "@/store/actions/events";

export default {
  props: {
    currency: {
      type: String,
      default: 'rub',
    },
    cryptoCurrency: {
      type: String,
      default: 'btc',
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      'getRates',
      'getSelectedCurrency'
    ]),
    Rates() {
      return Object.keys(this.getRates).map(name => {
        let rate = this.getRates[name]?.rate ?? 0;
        let difference = this.getRates[name]?.difference ?? 0;
        return {
          name: name,
          rate: rate.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
          difference: difference.toFixed(2),
        }
      });
    },
  },

  watch: {
    getSelectedCurrency(val) {
      if(val) {
        this.requestRates({
          crypto: this.cryptoCurrency,
          fiat: this.getSelectedCurrency,
        })
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.loadRates();
      this.rateListen();
    }, 500)
  },
  methods: {
    ...mapActions({
      requestRates: REQUEST_RATES,
      rateListen: EVENT_RATES_OPEN
    }),
    loadRates() {
      this.requestRates({
        crypto: this.cryptoCurrency,
        fiat: this.currency,
      })
    },
    getDifference(number) {
      let num = parseFloat(number);
      let symbol = Math.sign(num) === -1 ? '' : '+';
      return `${symbol}${number}% от Binance`;
    }
  }

}
</script>

<style lang="scss" scoped>
.rates__item {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 10px;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
}
.rates__container {
  margin-top: 10px;
  padding-right: 20px;
}
.rates__item--name{
  color: $gray600;
  font-weight: 500;
  text-transform: capitalize;
}
.rates__item--difference {
  font-size: 10px;
  text-align: right;
}
.rates__item--rate {
  font-weight: 600;

  span {
    text-transform: uppercase;
  }
  text-align: right;
  color: $white;
}
</style>
