import {SEND_API_ACCESS_REQUEST} from '../actions/support';
import {HTTP_POST} from '../actions/events';

const state = {};

const getters = {};

const actions = {
  [SEND_API_ACCESS_REQUEST]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: SEND_API_ACCESS_REQUEST,
      response: false,
      data: data,
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
