import {
  AUTH_LOGOUT,
  AUTH_LOGOUT_CLEAR,
  AUTH_REQUEST,
  CHECK_EMAIL_UNIQUE,
  CHECK_LOGIN_UNIQUE,
  REGISTER_REQUEST,
  RESET_EMAIL,
  RESET_PASSWORD,
  SEND_NEW_IDEA,
  AUTH_SOCIAL,
  AUTH_SOCIAL_REQUEST,
  AUTH_SIGN_IN_TOKEN,
  AUTH_SOCIAL_SET_PARTNER,
} from '../actions/auth';
import {SET_HEADERS, USER_LOGOUT_CLEAR, USER_PROFILE} from '../actions/user';
import Vue from 'vue';
import {HTTP_POST, SOCKET_CLOSE, SOCKET_INIT, HTTP_GET, EVENT_LOGOUT_CLEAR, HTTP_PUT} from '../actions/events';
import {SETTINGS_LOGOUT_CLEAR, SETTINGS_REQUEST} from '../actions/settings';
import router from '../../router';
import {AD_LOGOUT_CLEAR} from "../actions/ad";
import {CHANGEPHONE_LOGOUT_CLEAR} from "../actions/changePhone";
import {CLIENT_LOGOUT_CLEAR} from "../actions/client";
import {DASHBOARD_LOGOUT_CLEAR, GET_ACTIVE_DEALS_COUNT} from "../actions/dashboard";
import {DEAL_LOGOUT_CLEAR} from "../actions/deal";
import {EMERGENCY_LOGOUT_CLEAR} from "../actions/emergency";
import {NOTE_LOGOUT_CLEAR} from "../actions/note";
import {SECURITY_LOGOUT_CLEAR, SECURITY_REQUEST} from "../actions/security";
import {FACTOR_LOGOUT_CLEAR} from "../actions/twoFactor";
import cfg from '../../../config';
import {GET_ACTIVE_CLAIM_COUNT, GET_INCOMING_CLAIMS} from "@/store/actions/claims";
import {GET_USER_COMMISSIONS} from "@/store/actions/commission";

const state = {
  token: localStorage.getItem('ersec-token') || '',
  tiles: {
    'simple': 'До легкого пароля необходимо иметь:',
    'medium': 'Вы можете улучшить безопасность вашего пароля до среднего',
    'hard': 'Неужели у вас будет тяжелый пароль ?)',
    'super_hard': 'Хорошо, что вы беспокоитесь о сохранности вашей учетной записи!',
  },
  contents: {
    'simple': [
      {text: ' 1 буква', type: 'letter'},
      {text: ' 1 цифра', type: 'number'},
      {text: ' 7 символов', type: 'count'},
    ],
    'medium': [
      {text: ' 1 прописная буква', type: 'lowercase'},
      {text: ' 1 заглавная буква', type: 'uppercase'},
      {text: ' 1 цифра', type: 'number'},
      {text: ' 9 символов', type: 'count'},
    ],
    'hard': [
      {text: ' 1 прописная буква', type: 'lowercase'},
      {text: ' 1 заглавная буква', type: 'uppercase'},
      {text: ' 1 цифра', type: 'number'},
      {text: ' 1 спецсимвол', type: 'special'},
      {text: ' 13 символов', type: 'count'},
    ],
  },
};

const getters = {
  isAuthenticated: state => !!state.token,
  passTitle: state => state.tiles,
  passContent: state => state.contents,
};

const actions = {
  [AUTH_LOGOUT_CLEAR]: ({commit}) => {
    commit(AUTH_LOGOUT_CLEAR);
  },
  [AUTH_REQUEST]: ({commit, dispatch}, data) => {
    // todo BUG in auth, if you not check "this.loading(AUTH_REQUEST) === 'loading'" it sends request twice
    commit(AUTH_REQUEST, {token: null});
    commit(SET_HEADERS, {
      key: 'Authorization',
      value: null,
    });
    return dispatch(HTTP_POST, {
      method: AUTH_REQUEST,
      data,
    }).then((response) => {
      commit(SET_HEADERS, {
        key: 'Authorization',
        value: 'Bearer ' + response.data.token,
      });
      dispatch(SOCKET_INIT);
      dispatch(USER_PROFILE);
      dispatch(SECURITY_REQUEST);
      dispatch(SETTINGS_REQUEST);
      dispatch(GET_ACTIVE_DEALS_COUNT);
      dispatch(GET_ACTIVE_CLAIM_COUNT, {closed: 0});
      dispatch(GET_USER_COMMISSIONS);
    });
  },
  [AUTH_SOCIAL]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_GET, {
      method: AUTH_SOCIAL,
      params: {provider: data.provider},
      response: false,
    }).then((response) => {
      return response;
    }).catch((e) => {
      console.log('Social auth error: ', e);
    });
  },
  [AUTH_SIGN_IN_TOKEN]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: AUTH_SIGN_IN_TOKEN,
      data: data,
      response: false,
    }).then((response) => {
      if(response && response.data.token){
        commit(AUTH_REQUEST, response.data);
        commit(SET_HEADERS, {
          key: 'Authorization',
          value: 'Bearer ' + response.data.token,
        });
        dispatch(SOCKET_INIT);
        dispatch(USER_PROFILE);
        dispatch(SETTINGS_REQUEST);
        router.push('/');
      }
    })
  },
  [AUTH_SOCIAL_SET_PARTNER]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_PUT, {
      method: AUTH_SOCIAL_SET_PARTNER,
      data: data,
      response: false,
    })
  },
  [AUTH_SOCIAL_REQUEST]: ({commit, dispatch}, data) => {
    if (data.token) {
      if(data.loginToken && data.token === 'false'){
        router.push({name: 'Social2fa', params: {loginToken: data.loginToken}})
      } else {
        commit(AUTH_REQUEST, data);
        commit(SET_HEADERS, {
          key: 'Authorization',
          value: 'Bearer ' + data.token,
        });
        dispatch(SOCKET_INIT);
        dispatch(USER_PROFILE);
        dispatch(SETTINGS_REQUEST);
        if (data.firstLogin === 'true') {
          router.push({name: 'SocialUpdateLogin', params: {firstLogin: true}})
        } else {
          router.push('/');
        }
      }
    }
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    const token = state.token;
    commit(AUTH_REQUEST, {token: null});
    dispatch(SOCKET_CLOSE);
    return dispatch(HTTP_POST, {
      method: AUTH_LOGOUT,
      response: false,
      config: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      commit(SET_HEADERS, {
        key: 'Authorization',
        value: null,
      });
      dispatch(AD_LOGOUT_CLEAR);
      dispatch(AUTH_LOGOUT_CLEAR);
      dispatch(CHANGEPHONE_LOGOUT_CLEAR);
      dispatch(CLIENT_LOGOUT_CLEAR);
      dispatch(DASHBOARD_LOGOUT_CLEAR);
      dispatch(DEAL_LOGOUT_CLEAR);
      dispatch(EMERGENCY_LOGOUT_CLEAR);
      dispatch(EVENT_LOGOUT_CLEAR);
      dispatch(NOTE_LOGOUT_CLEAR);
      dispatch(SECURITY_LOGOUT_CLEAR);
      dispatch(SETTINGS_LOGOUT_CLEAR);
      dispatch(FACTOR_LOGOUT_CLEAR);
      dispatch(USER_LOGOUT_CLEAR);
      window.location.href = '/';
    }).catch(() => {
      commit(AUTH_LOGOUT);
    });
  },

  [CHECK_LOGIN_UNIQUE]: ({commit, dispatch}, login) => {
    console.log(CHECK_LOGIN_UNIQUE, login);
    return dispatch(HTTP_POST, {
      method: CHECK_LOGIN_UNIQUE,
      data: {
        login: login,
      },
    });
  },

  [CHECK_EMAIL_UNIQUE]: ({commit, dispatch}, email) => {
    return dispatch(HTTP_POST, {
      method: CHECK_EMAIL_UNIQUE,
      data: {
        email: email,
      },
    });
  },

  [REGISTER_REQUEST]: ({commit, dispatch}, user) => {
    commit(AUTH_REQUEST, {token: null});
    commit(SET_HEADERS, {
      key: 'Authorization',
      value: null,
    });
    return dispatch(HTTP_POST, {
      method: REGISTER_REQUEST,
      response: AUTH_REQUEST,
      data: {
        login: user.login,
        email: user.email,
        partner_id: user.partnerId,
        password: user.password,
        password_confirmation: user.passwordConfirm,
        telegram: user.telegram,
      },
    }).then((response) => {
      commit(SET_HEADERS, {
        key: 'Authorization',
        value: 'Bearer ' + response.data.token,
      });
      dispatch(USER_PROFILE);
    });
  },

  [RESET_EMAIL]: ({commit, dispatch}, {email}) => {
    return dispatch(HTTP_POST, {
      method: RESET_EMAIL,
      response: false,
      data: {
        email,
      },
    });
  },
  [RESET_PASSWORD]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: RESET_PASSWORD,
      response: false,
      data: data,
    });
  },
  [SEND_NEW_IDEA]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: SEND_NEW_IDEA,
      response: false,
      data: data,
    });
  },
};

const mutations = {
  [AUTH_LOGOUT_CLEAR]: (state) => {
    state.token = '';
  },
  [AUTH_REQUEST]: (state, data) => {
    Vue.set(state, 'token', data.token);
    if (data.token) {
      localStorage.setItem('ersec-token', data.token);
    } else {
      localStorage.removeItem('ersec-token');
    }
  },
  [CHECK_LOGIN_UNIQUE]: (state, data) => {
    Vue.set(state, 'is_login_unique', data);
  },
  [CHECK_EMAIL_UNIQUE]: (state, data) => {
    Vue.set(state, 'is_email_unique', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
