<template lang="pug">
.profile-avatar.relative(@click="toggleModal")
  img(:src='avatar(profile.avatar)' alt='profile_avatar')

  .profile-popup(:class="{'active':modalVisible}")
    .profile-popup__container
      .profile_page-link(@click="toProfile")
        div
          span.title {{profile.login}}
          .star-rating
            span.star-rating__star(v-for="number in 5", :class="{active: profile.trust_coef >= number * 20}")
        i.el-icon-arrow-right

      .popup-list
        router-link.popup-list__item(
          :to="{name: 'Commissions'}"
        )
          i.el-icon-refresh
          span Комиссии и лимиты
        router-link.popup-list__item(
          :to="{name: 'AccountPartnership'}"
        )
          i.el-icon-user
          span Партнерская программа

      router-link.btn.btn__ghost_light(
        :to="{name: 'Logout'}"
      ) Выйти

</template>

<script>
import {mapGetters} from 'vuex';
import notifications from '@/components/mixins/notifications';

export default {
  name: 'ProfilePopup',
  mixins: [
    notifications,
  ],
  data() {
    return {
      modalVisible: false,
      defaultAvatar: '/img/default-avatar.png',
    };
  },
  computed: {
    ...mapGetters({
      'avatar': 'getAvatar',
      'profile': 'getProfile',
    }),
  },

  created() {
    let self = this;

    window.addEventListener('click', function(e){
      if (!self.$el.contains(e.target)){
        self.modalVisible = false
      }
    })
  },
  methods: {
    toProfile() {
      this.$router.push({name: 'AwesomeProfile', params: {id: this.profile.id}})
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    }
  },
};
</script>

<style lang="scss" scoped>
.profile-avatar {
  width: 30px;
  height: 30px;

  img {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}

.relative {
  position: relative;
}

.profile-popup {
  display: none;
  position: absolute;
  top: 55px;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.244565);
  border-radius: 16px;
  z-index: 999;

  &.active {
    display: block;
  }
}

.profile-popup__container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 16px;
  width: 248px;
  height: 235px;

  .profile_page-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 8px 4px 8px 8px;
    border-radius: 10px;
    background-color: #F7FAFF;

    &:hover {
      cursor: pointer;
    }

    .title {
      position: relative;
      top: 1.5px;
      width: 180px;
      height: 18px;
    }
  }

  .popup-list {
    width: 100%;

    .popup-list__item {
      width: 100%;
      height: 40px;
      border-radius: 10px;
      display: flex;
      gap: 8px;
      padding: 8px;
      align-items: center;
      color: #7486B3;
      font-size: 14px;
      line-height: 16px;

      &:hover {
        text-decoration: none;
        background-color: #F7FAFF;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
