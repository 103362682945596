import {
  CLIENT_AD_UPDATE,
  CLIENT_ADS,
  CLIENT_ADS_PAGINATION,
  CLIENT_DEALS,
  CLIENT_DEALS_PAGINATION,
  CLIENT_DETAILS,
  CLIENT_LOADING,
  CLIENT_LOGOUT_CLEAR,
  CLIENT_TURNOVER,
} from '../actions/client'
import Vue from 'vue'
import {HTTP_GET, HTTP_PUT, LOADING} from "../actions/events";
import {DEAL_LISTEN} from "../actions/deal";
import {AD_UPDATE} from "../actions/ad";

const state = {
  pagination: {
    deals: {
      for_sale: {
        per_page: 10,
        page: 1,
        current_page: 1,
      },
      for_buy: {
        per_page: 10,
        page: 1,
        current_page: 1,
      },
    },
    ads: {
      for_sale: {
        per_page: 10,
        page: 1,
        current_page: 1,
      },
      for_buy: {
        per_page: 10,
        page: 1,
        current_page: 1,
      },
    }
  },
  details: {
    statistics: {
      deals_count: 0
    }
  },
  deals: {
    for_sale: [],
    for_buy: [],
  },
  ads: {
    for_sale: [],
    for_buy: [],
  },
  turnover: {},
};

const getters = {
  getDetails: state => state.details,
  getDetailsAds: state => state.ads,
  getPagination: state => state.pagination,
  getTurnover: state => state.turnover,
};

const actions = {
  [CLIENT_LOGOUT_CLEAR]: ({commit}) => {
    commit(CLIENT_LOGOUT_CLEAR);
  },

  [CLIENT_DETAILS]: ({commit, dispatch}, {id}) => {

    commit(LOADING, {name: CLIENT_LOADING, value: 'loading'});

    dispatch(HTTP_GET, {
      method: CLIENT_TURNOVER,
      params: {id},
      response: false,
    }).then((response) => {
      //требуется response для обработки
      commit(CLIENT_TURNOVER, response);
    });

    return dispatch(HTTP_GET, {
      method: CLIENT_DETAILS,
      params: {id},
    }).then(() => {
      commit(LOADING, {name: CLIENT_LOADING, value: 'loaded'});
    }).catch(response => {
      commit(LOADING, {name: CLIENT_LOADING, value: 'error'});
      return Promise.reject(response);
    });
  },

  [CLIENT_DEALS]: ({commit, dispatch}, {id, params, type}) => {
    return dispatch(HTTP_GET, {
      method: CLIENT_DEALS,
      response: false,
      params: {id},
      data: {params}
    }).then(response => {
      commit(CLIENT_DEALS, {
        type,
        list: _.map(response.data, deal => deal.id),
      });
      _.each(response.data, deal => dispatch(DEAL_LISTEN, {dealId: deal.id, deal}));
      commit(CLIENT_DEALS_PAGINATION, {type, pagination: response.pagination});
    });
  },
  [CLIENT_ADS]: ({commit, dispatch}, { id, params, type }) => {
    return dispatch(HTTP_GET, {
      method: CLIENT_ADS,
      response: false,
      params: {id},
      data: {params}
    }).then(response => {
      _.forEach(response.data, (ad) => {
        commit(AD_UPDATE, ad);
      });
      commit(CLIENT_ADS, {
        type,
        list: response.data,
      });
      commit(CLIENT_ADS_PAGINATION, {type, pagination: response.pagination});
    });
  },
  [CLIENT_AD_UPDATE]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_PUT, {
      method: AD_UPDATE,
      params: {id},
      data,
    });
  },
};
const mutations = {
  [CLIENT_LOGOUT_CLEAR]: (state) => {
    state.details = {};
    state.deals = {
      for_sale: [],
      for_buy: [],
    };
    state.turnover = {};
  },
  [CLIENT_DETAILS]: (state, data) => {
    Vue.set(state, 'details', data);
  },
  [CLIENT_ADS]: (state, {type, list}) => {
    Vue.set(state.ads, type, list);
  },
  [CLIENT_ADS_PAGINATION]: (state, {type, pagination}) => {
    Vue.set(state.pagination.ads, type, pagination);
  },
  [CLIENT_DEALS]: (state, {type, list}) => {
    Vue.set(state.deals, type, list); // TODO: validate type
  },
  [CLIENT_DEALS_PAGINATION]: (state, {type, pagination}) => {
    Vue.set(state.pagination.deals, type, pagination);
  },
  [CLIENT_TURNOVER]: (state, data) => {
    Vue.set(state, 'turnover', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
