import {mapGetters} from 'vuex'
import {USER_NOTIFY_CLEAR, USER_NOTIFY_SYSTEM_DELETE} from '@/store/actions/user';

export default {
  data() {
    return {
      sound: '/button_tiny.mp3',
    }
  },
  computed: {
    ...mapGetters({
      'options': 'getSettings',
    }),

    //
    messages() {
        return this.$store.state.user.notifications.messages;
    },

    //
    system() {
       return this.$store.state.user.notifications.system;
    },

    //
    countMessages() {
      let messages = this.messages.filter(item => !item.read).length;

      return messages >= 100 ? '99+' : messages
    },

    //
    countNotifications() {
      let system = this.system.filter(item => !item.read).length;

      return system >= 100 ? '99+' : system
    },

    //
    countAllNotifications() {
      let messages = this.messages.filter(item => !item.read).length;
      let system = this.system.filter(item => !item.read).length;
      let count = messages + system;

      return count >= 100 ? '99+' : count
    }
  },

  //
  methods: {
    //
    removeSystem(info) {
      this.$store.commit(USER_NOTIFY_SYSTEM_DELETE, info.message);
    },

    //
    clearNotifications(type) {
      this.$store.commit(USER_NOTIFY_CLEAR, type);
    },

    clearAllNotifications() {
      this.clearNotifications('messages');
      this.clearNotifications('system');
    },

    //
    clearMessageNotifications() {
      this.messages.map(item => {
        this.removeMessage(item)
      });
      return this;
    },

    //
    clearSystemNotifications() {
      this.system.map(item => {
        this.removeSystem(item)
      });
      return this;
    },

    //
    playSound() {
      let aSound = document.createElement('audio');
      aSound.setAttribute('src', this.sound);
      let promise = aSound.play();
      if (promise !== undefined) {
        promise.then(() => {
          console.log('Sound played');
        }).catch((e) => {
          console.log('!!! Error playing sound', e);
        });
      }
    }
  }
}
