import {
  ADS_SELLER,
  ADS_SELLERS,
  ADS_SELLERS_META,
  ADS_SELLERS_LINKS,
  ADS_SELLERS_PAGINATION,
  ADS_BUYER,
  ADS_BUYERS,
  ADS_BUYERS_META,
  ADS_BUYERS_LINKS,
  ADS_BUYERS_PAGINATION,
  ADS_CLOSE_MARKET,
  ADS,
  ADS_FILTER,
  ADS_LINKS,
  ADS_META,
  ADS_PAGINATION,
  ADS_UPDATE_MARKET,
  ADS_RESET_GEOINFO,
  ADS_PAYMENT_SYSTEMS,
  ADS_RECOMMENDED_UPDATE,
  ADS_FILTER_SET,
  ADS_FILTER_ALL_PAYMENTS, ADS_RESET_PAGE,
} from '../actions/markets'
import {HTTP_GET} from "../actions/events";
import {AD_MARKET} from "../actions/ad";

import Vue from 'vue'

const state = {
  isMarketUpdating: false,
  allPaymentsFilter: false,
  filter: {
    per_page: 9,
    page: 1,
    country_id: null,
    currency_id: null,
    crypto_currency_id: null,
    payment_system_id: null,
    bank_id: null,
    price_from: 0,
    price_to: 0,
    min_from: 0,
    max_to: 0,
    reputation_from: 0,
    reputation_to: 100,
    order: 'speed',
  },
  all: { // TODO: remove
    all: ADS,
    list: [],
    pagination: {},
    meta: {},
    links: {},
  },
  sellers: {
    action: ADS_SELLERS,
    pagination: {},
    meta: {},
    links: {},
    list: [],
  },
  buyers: {
    action: ADS_BUYERS,
    pagination: {},
    meta: {},
    links: {},
    list: [],
  },
  actions: {
  },
  resetGeoinfo: false,
  recommendedPayments: [],
  recommendedAds: {
    buy: {},
    sale: {},
  }
};

const getters = {
  marketFilter: state => state.filter,
  resetGeoinfo: state => state.resetGeoinfo,
  marketInfo: state => name => {
    const types = {
      // all: 'all',
      buy: 'buyers', // TODO: rename buyers and remove types variable
      sale: 'sellers',
    };
    name = types[name] || 'buyers';
    return state[name];
  },
  sale: state => state.sellers.list,
  salePagination: state => state.sellers.pagination,
  buy: state => state.buyers.list,
  buyPagination: state => state.buyers.pagination,
  recommendedPayments: state => state.recommendedPayments,
  recommendedSale: state => state.recommendedAds.sale,
  recommendedBuy: state => state.recommendedAds.buy,
  allPaymentsFilter: state => state.allPaymentsFilter,
};

const actions = {
  [ADS_FILTER_SET]: ({state, commit, dispatch}, filters) => {
    _.each(filters, (value, key) => {
      commit(ADS_FILTER, { key, value });
    });
  },
  [ADS_CLOSE_MARKET]: ({state, commit, dispatch}) => {
    commit(ADS_SELLERS, []);
    commit(ADS_BUYERS, []);
  },
  [ADS_UPDATE_MARKET]: ({state, commit, dispatch}, newAd) => {
    if (state.isMarketUpdating === true) {
      return ;
    }
    state.isMarketUpdating = true;
    const type = (newAd.is_sale ? 'sellers' : 'buyers');
    let prices = state[type].list.map(item => parseFloat(item.price));
    const ad = state[type].list.find(item => item.id === newAd.id);
    if (newAd.is_sale && parseFloat(newAd.price) < Math.max.apply(null, prices) || prices.length <= 20) {
      return dispatch(state[type].action, { filter: {} });
    } else if (!newAd.is_sale && parseFloat(newAd.price) > Math.min.apply(null, prices)) {
      return dispatch(state[type].action, { filter: {} });
    } else if (ad) {
      return dispatch(HTTP_GET, {
        method: AD_MARKET,
        response: newAd.is_sale ? ADS_SELLER : ADS_BUYER,
        params: {id: ad.id},
      });
    }
  },
  [ADS]: ({state, commit, dispatch}, { filter }) => { // TODO: remove
    _.each(filter, (value, key) => {
      commit(ADS_FILTER, { key, value });
    });
    dispatch(HTTP_GET, {
      method: ADS,
      params: {},
      data: { params: state.filter },
    }).then((response) => {
      commit(ADS_LINKS, response.links);
      commit(ADS_META, response.meta);
      commit(ADS_PAGINATION, response.pagination);
    });
  },
  [ADS_SELLERS]: ({state, commit, dispatch}, { filter }) => {
    _.each(filter, (value, key) => {
      commit(ADS_FILTER, { key, value });
    });
    dispatch(HTTP_GET, {
      method: ADS_SELLERS,
      params: {},
      data: { params: state.filter },
    }).then((response) => {
      commit(ADS_SELLERS_LINKS, response.links);
      commit(ADS_SELLERS_META, response.meta);
      commit(ADS_SELLERS_PAGINATION, response.pagination);
    });
  },
  [ADS_BUYERS]: ({state, commit, dispatch}, { filter }) => {
    _.each(filter, (value, key) => {
      commit(ADS_FILTER, { key, value });
    });
    dispatch(HTTP_GET, {
      method: ADS_BUYERS,
      params: {},
      data: { params: state.filter },
    }).then((response) => {
      commit(ADS_BUYERS_LINKS, response.links);
      commit(ADS_BUYERS_META, response.meta);
      commit(ADS_BUYERS_PAGINATION, response.pagination);
    });
  },
  [ADS_PAYMENT_SYSTEMS]: ({state, commit, dispatch}, params) => {
    return dispatch(HTTP_GET, {
      method: ADS_PAYMENT_SYSTEMS,
      response: false,
      data: {
        params,
      },
    }).then((response) => {
      commit(ADS_PAYMENT_SYSTEMS, response.data);
    });
  },
  [ADS_RECOMMENDED_UPDATE]: ({state, commit, dispatch}, {payment, isSale, params}) => {
    const method = isSale ? ADS_SELLERS : ADS_BUYERS;
    if (payment) {
      try {
        dispatch(HTTP_GET, {
          method,
          params: {},
          data: { params: {
              currency_id: payment.currency_id,
              payment_system_id: payment.payment_system_id,
              bank_id: payment.bank_id,
              per_page: 1,
              page: 1,
              only_available: 1,
              ...params,
            },
          },
        }).then((response) => {
          if (response.data) {
            commit(ADS_RECOMMENDED_UPDATE, {result: response.data[0], isSale})
          }
        });
      } catch (e) {
        console.log('Error in updating ads: ', e);
      }
    }
  },
};

const mutations = {
  [ADS_FILTER]: (state, { key, value }) => {
    console.log({mutation: 'ADS_FILTER'}, key, value);
    // TODO: validate
    if (!value && value !== false) {
      if (state.filter[key]) {
        Vue.set(state.filter, key, ''); // чтобы обновились данные
        delete state.filter[key];
      }
    } else {
      Vue.set(state.filter, key, value);
    }
  },

  [ADS_SELLERS_LINKS]: (state, data) => state.sellers.links = data,
  [ADS_SELLERS_META]: (state, data) => state.sellers.meta = data,
  [ADS_SELLERS_PAGINATION]: (state, data) => _.each(data, (value, key) => Vue.set(state.sellers.pagination, key, parseInt(value))),

  [ADS_BUYERS_LINKS]: (state, data) => state.buyers.links = data,
  [ADS_BUYERS_META]: (state, data) => state.buyers.meta = data,
  [ADS_BUYERS_PAGINATION]: (state, data) => _.each(data, (value, key) => Vue.set(state.buyers.pagination, key, parseInt(value))),

  [ADS_RESET_PAGE]: (state) => {
    Vue.set(state.filter, 'page', 1);
  },
  [ADS]: (state, data) => {
    console.log({mutation: 'ADS'}, data);
    Vue.set(state.all, 'list', data);
  },
  [ADS_SELLERS]: (state, data) => {
    console.log({mutation: 'ADS_SELLERS'}, data);
    Vue.set(state.sellers, 'list', data);
    Vue.set(state, 'isMarketUpdating', false);
  },
  [ADS_BUYERS]: (state, data) => {
    console.log({mutation: 'ADS_BUYERS'}, data);
    Vue.set(state.buyers, 'list', data);
    Vue.set(state, 'isMarketUpdating', false);
  },
  [ADS_SELLER]: (state, data) => {
    console.log({mutation: 'ADS_SELLER'}, data);
    if (!data || !data.id) return;
    let offer = _.find(state.sellers.list, (item) => item.id === data.id);
    // нашли уже добавление объвление
    if (offer) {
      Vue.set(state.sellers.list, state.sellers.list.indexOf(offer), data);
      // offer = data;
    } else {
      state.sellers.list.push(data);
    }
    Vue.set(state, 'isMarketUpdating', false);
  },
  [ADS_BUYER]: (state, data) => {
    console.log({mutation: 'ADS_BUYER'}, data);
    if (!data || !data.id) return;
    let offer = _.find(state.buyers.list, (item) => item.id === data.id);
    // нашли уже добавление объвление
    if (offer) {
      // offer = data;
      Vue.set(state.sellers.list, state.sellers.list.indexOf(offer), data);
    } else {
      state.buyers.list.push(data);
    }
    Vue.set(state, 'isMarketUpdating', false);
  },
  [ADS_RESET_GEOINFO]: (state, data) => {
    Vue.set(state, 'resetGeoinfo', data);
  },
  [ADS_PAYMENT_SYSTEMS]: (state, data) => {
    Vue.set(state, 'recommendedPayments', data);
  },
  [ADS_RECOMMENDED_UPDATE]: (state, {result, isSale}) => {
    const saleStatus = isSale ? 'sale' : 'buy';
    Vue.set(state.recommendedAds, saleStatus, result);
  },
  [ADS_FILTER_ALL_PAYMENTS]: (state, filter) => {
    Vue.set(state, 'allPaymentsFilter', filter);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
