<template lang="pug">
  #app(v-loading='!isLoaded')
    .layout-main(:class="{ 'layout-main__hidden-menu': !isAuthenticated }")
      Menu(v-if="isAuthenticated" :showMobileMenu="showMobileMenu" @switchMobileMenu="switchMobileMenu")
      .layout-main__content(v-show="isLoaded")
        Header(v-model="showHeaderBackButton" :backButton="showHeaderBackButton" :backButtonMethod="backButtonMethod" @switchMobileMenu="switchMobileMenu")
        .layout-main__content-container
          vue-scroll(ref="scroll")
            template(v-if="isLoaded")
              router-view
      NotificationsPopup(v-if="NotificationsWindowOpen" :notifications="getImportantNotification" @closeConditions="changeConditionsWindowStatus")
</template>

<script>
import Menu from './components/Layout/Menu';
import Header from './components/Layout/Header';
import NotificationsPopup from './components/Auth/Partials/NotificationsPopup';
import { mapGetters } from 'vuex';
import {
  USER_PROFILE,
  SET_HEADERS,
  USER_GEOLOCATION,
  USER_LOADED,
  UPDATE_SELECTED_CURRENCY,
  IMPORTANT_NOTIFICATION_READ,
} from './store/actions/user';
import { SECURITY_REQUEST } from './store/actions/security';
import { SOCKET_INIT, HTTP_PING } from './store/actions/events';
import { ADS_FILTER, ADS_RESET_GEOINFO } from './store/actions/markets';
import analytics from './components/mixins/analytics';
import {GET_ACTIVE_DEALS_COUNT} from "./store/actions/dashboard";
import {GET_ACTIVE_CLAIM_COUNT} from "./store/actions/claims";
import {GET_USER_COMMISSIONS} from '@/store/actions/commission';

export default {
  components: {
    Menu,
    Header,
    NotificationsPopup,
  },
  mixins: [analytics],
  data() {
    return {
      showMobileMenu: false,
      showHeaderBackButton: false,
      backButtonMethod: () => {return false},
      timeout: false,
      delay: 2000,
      increase: 500,
      login: null,
      id: null,
      avatar: null,
      signature: null,
      goToMarket: false,
      NotificationsWindowOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'isAuthenticated',
      'loading',
      'errorRequest',
      'getUserSpoofingData',
      'getSelectedCurrency',
      'getImportantNotification',
    ]),
    isLoaded() {
      return !this.isAuthenticated || this.loading(USER_LOADED) !== 'loading';
    },
    haveErrors() {
      return Object.keys(this.errorRequest).length > 0;
    },
  },
  watch: {
    haveErrors(val) {
      if (val) {
        this.pingServer();
      }
    },
    getImportantNotification(val) {
      if (val.length > 0) {
        this.NotificationsWindowOpen = true;
      }
    },
    getProfile(val) {
      this.id = val.id;
      this.login = val.login;
      this.avatar = val.avatar;
    },
    '$route'(val) {
      if(val.path !== '/') {
        this.goToMarket = true;
      }
    },
  },
  created() {
    this.$moment.locale('ru');

    this.$store.dispatch(SOCKET_INIT);
    this.$store.commit(SET_HEADERS, {
      key: 'Accept',
      value: 'application/json',
    });

    if (this.isAuthenticated) {
      this.$store.commit(SET_HEADERS, {
        key: 'Authorization',
        value: 'Bearer ' + localStorage.getItem('ersec-token'),
      });
      this.checkSelectedCurrency();
      this.$store.dispatch(USER_PROFILE);
      this.$store.dispatch(SECURITY_REQUEST);
      this.$store.dispatch(GET_ACTIVE_DEALS_COUNT);
      this.$store.dispatch(GET_ACTIVE_CLAIM_COUNT, {closed: 0});
      this.$store.dispatch(GET_USER_COMMISSIONS)
    } else {
      this.$store.dispatch(USER_GEOLOCATION)
      .then((response) => {
        this.$store.commit(ADS_FILTER, {key: 'currency_id', value: response.data.currency.value});
        this.$store.commit(ADS_RESET_GEOINFO, true);
      });
    }

  },
  mounted() {
    window.FinexScroll = this.$refs['scroll'];
  },
  methods: {
    changeConditionsWindowStatus(status, id) {
      this.NotificationsWindowOpen = status;
      this.$store.dispatch(IMPORTANT_NOTIFICATION_READ, id);
    },
    switchMobileMenu(status) {
      this.showMobileMenu = status;
    },
    checkSelectedCurrency() {
      if (localStorage.getItem('selectedCurrency')) {
        this.$store.commit(UPDATE_SELECTED_CURRENCY, localStorage.getItem('selectedCurrency'));
      } else {
        localStorage.setItem('selectedCurrency', this.getSelectedCurrency);
      }
    },
    pingServer() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.$store.dispatch(HTTP_PING).then((response) => {
          if (response === 'pong') {
            this.showButton = true;
          } else {
            this.delay = this.delay + this.increase;
            this.pingServer();
          }
        }).catch(() => {
          this.delay = this.delay + this.increase;
          this.pingServer();
        });
      }, this.delay);
    },
  },
};
</script>

<style lang="scss">
  @import './assets/sass/app.scss';

  #app {
    height: 100vh;
  }

  .webkit-scrollbar {
    width: 0;
  }

  .page {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.148211);
    border-radius: 8px 8px 0 0;
    padding: 30px;
    background: white;
    margin-bottom: 50px;
  }
</style>
