import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import events from './modules/events';
import user from './modules/user';
import auth from './modules/auth';
import markets from './modules/markets';
import ad from './modules/ad';
import changePhone from './modules/changePhone';
import note from './modules/note';
import client from './modules/client';
import dashboard from './modules/dashboard';
import settings from './modules/settings';
import twoFactor from './modules/twoFactor';
import security from './modules/security';
import deal from './modules/deal';
import support from './modules/support';
import emergency from './modules/emergency';
import claims from './modules/claims';
import commission from './modules/commission'

import {SET_HEADERS} from './actions/user';
//Analytics
import {event} from 'vue-analytics';
import cfg from '../../config';
import {REQUEST_RATES} from "@/store/actions/dashboard";
import {HTTP_GET} from "@/store/actions/events";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    events,
    user,
    auth,
    markets,
    ad,
    changePhone,
    settings,
    twoFactor,
    security,
    deal,
    client,
    dashboard,
    note,
    support,
    emergency,
    claims,
    commission
  },
  state: {
    general: {},
    headers: {},
    claimStatuses: [
      {name: 'Opened', title: 'В процессе'},
      {name: 'Rejected', title: 'Отклонена'},
      {name: 'Applied', title: 'Принята'},
      {name: 'Deleted', title: 'Удалена'},
      {name: 'Pending', title: 'Списание'}
    ],
    statuses: [
      {id: 7, name: 'Verification', title: 'Подтверждается'},
      {id: 1, name: 'Verified', title: 'В процессе'},
      {id: cfg.dealStatusSellerRequisite, name: 'Seller requisite', title: 'Ожидает оплаты'},
      {id: 2, name: 'Paid', title: 'Сделка помечена оплаченной'},
      {id: 9, name: 'Finishing', title: 'Завершается'},
      {id: 3, name: 'Finished', title: 'Завершено'},
      {id: 8, name: 'Cancellation', title: 'Отменяется'},
      {id: 4, name: 'Autocancellation', title: 'Сделка отменяется автоматически'},
      {id: 5, name: 'Canceled', title: 'Отмена'},
      {id: 6, name: 'In dispute', title: 'Обсуждается'},
      {id: cfg.dealStatusOnTimeCheck, name: 'On time check', title: 'Проверка таймеров'},
    ],
    crypto_fee: {
      free: cfg.crypto_fee_free,
      fast: cfg.crypto_fee_fast,
      standard: cfg.crypto_fee_standard,
    },
    requisite_types: {
      PHONE: 'phone',
      CARD: 'card',
      OTHER: 'other',
    },
    rates: {},
  },
  mutations: {
    [SET_HEADERS](state, {key, value}) {
      console.log({log: SET_HEADERS}, {key, value});
      if (!value) {
        if (state.headers[key]) {
          delete state.headers[key];
        }
      } else {
        state.headers[key] = value; // TODO: validate key
      }
      // TODO: change to custom headers in every request
      axios.defaults.headers.common = state.headers;
    },
    [REQUEST_RATES]: (state, data) => {
      Vue.set(state, 'rates', data.rates);
    },
  },
  actions: {
    sendAnalytics({}, {category, action, metricksList = []}) {
      if (cfg.analytics !== 'true') {
        return;
      }
      if (metricksList.indexOf('google') !== -1 || _.isEmpty(metricksList)) {
        event(category, action);
      }
      if (metricksList.indexOf('yandex') !== -1 || _.isEmpty(metricksList)) {
        ym(52229581, 'reachGoal', action);
      }
    },

    [REQUEST_RATES]: ({commit, dispatch}, {crypto, fiat}) => {
      return dispatch(HTTP_GET, {
        method: REQUEST_RATES,
        params: {crypto, fiat},
      });
    },
  },
  getters: {
    get_status: state => (current, type = 'id') => {
      let status = _.find(state.statuses, status => status.name === current || status.id === current);
      if (!status || !status[type]) {
        return false;
      }
      if (status[type]) {
        return status[type];
      }
    },
    getCommissionsCodes: state => state.crypto_fee,
    getAllRequisiteTypes: state => state.requisite_types,
    getRates: state => state.rates,
  },
});
