import {
  DEAL_CHAT_CLOSE,
  DEAL_CHAT_OPEN,
  EVENT_CLOSE,
  EVENT_COMMIT,
  EVENT_MARKET_CLOSE,
  EVENT_MARKET_OPEN,
  EVENT_OPEN,
  HTTP_DELETE,
  HTTP_ERROR,
  HTTP_ERROR_CLEAR,
  ERROR_HANDLE,
  HTTP_GET,
  HTTP_PING,
  HTTP_POST,
  HTTP_PUT,
  INC_FAILED_HTTP_REQ_COUNTER,
  RESET_FAILED_HTTP_REQ_COUNTER,
  INC_FAILED_CONNECT_REQ_COUNTER,
  RESET_FAILED_CONNECT_REQ_COUNTER,
  LOADING,
  PAGINATION,
  RESET_ERRORS,
  SOCKET_CLOSE,
  SOCKET_INIT,
  TEST_ERROR_REQUEST,
  EVENT_LOGOUT_CLEAR, EVENT_RATES_OPEN,
} from '../actions/events';
import {
  ADS_BUYERS,
  ADS_PAYMENT_SYSTEMS,
  ADS_CLOSE_MARKET,
  ADS_SELLERS,
  ADS_UPDATE_MARKET
} from '../actions/markets';
import cfg from '../../../config';
import {
  DEAL,
  DEAL_ACTION,
  DEAL_CHAT_GET_MESSAGES,
  DEAL_CHAT_MESSAGE,
  DEAL_CREATE,
  DEAL_GET_REVIEWS,
  DEAL_LISTEN,
  DEAL_SEND_REVIEW,
  DEAL_STOP_LISTEN
} from '../actions/deal';
import { NOTE_DELETE_ONE, NOTE_DELETE_USER, NOTE_LIST, NOTE_PUT } from '../actions/note';
import { DASHBOARD_AD_STATISTIC, DASHBOARD_ADS, DASHBOARD_DEAL_STATISTIC, DASHBOARD_DEALS, REQUEST_RATES } from '../actions/dashboard';
import {
  AD_ACTIVATE,
  AD_ALL,
  AD_CREATE,
  AD_DEACTIVATE,
  AD_DELETE,
  AD_EDIT,
  AD_FORM_DATA,
  AD_MARKET,
  AD_UPDATE,
  AD_USER_REVIEWS,
  AD_PRICE_TICKER,
  REQUISITE_TYPES,
  REQUISITE_VALIDATE,
  REQUISITE_ADD,
  REQUISITE_UPDATE,
  REQUISITE_DELETE,
  REQUEST_PRICE,
  RESET_TURNOVER,
  SET_STRATEGY,
  GET_OFFER_TIME,
  REQUISITE_DEALS_LIMITS_ADD,
  REQUISITE_DEALS_LIMITS_UPDATE,
  REQUISITE_DEALS_LIMITS_DELETE,
  REQUISITE_DEALS_LIMITS_RESET,
} from '../actions/ad';
import {
  APPEAL_UPDATE,
  AVATAR_DELETE,
  AVATAR_UPDATE,
  EMAIL_CONFIRM,
  EMAIL_START_CONFIRM,
  EMAIL_UPDATE,
  PASSWORD_UPDATE,
  PROFILE_MAKE_PARTNERSHIP,
  PROFILE_PARTNERSHIP_GET,
  SEARCH_USERS,
  USER_BALANCE,
  USER_GEOLOCATION,
  USER_PROFILE,
  USER_PRO_RATING,
  USER_SECURITY_LOGS,
  USER_SESSIONS_LIST,
  USER_SPOOFING_PROTECTION,
  USER_WALLET_ADDRESS,
  USER_WALLET_COMISSIONS,
  USER_WALLET_HISTORY,
  USER_WALLET_PAYING,
  USER_WALLET_PAYOUT,
  USER_WALLET_WITHDRAW,
  USER_WALLET_WITHDRAW_PAID,
  SOCIAL_EMAIL_UPDATE,
  SOCIAL_LOGIN_UPDATE,
  LOAD_DEAL,
  LOAD_SUMMARY,
  IMPORTANT_NOTIFICATION_READ,
  IMPORTANT_NOTIFICATION_LIST,
  PROFILE_REQUESTS,
  USER_WALLET_WITHDRAW_STANDARD,
  USER_WALLET_VALIDATE,
  USER_WALLET_WITHDRAW_AUTO,
  USER_WALLET_WITHDRAW_AUTO_LIST,
  USER_WALLET_WITHDRAW_AUTO_DELETE,
  PROFILE_USER_IP_LIST,
  PROFILE_USER_IP_ADD,
  PROFILE_USER_IP_DELETE,
  USER_WALLET_ADD_PAYOUT,
  USER_WALLET_DELETE_PAYOUT,
  USER_WALLET_PAYOUT_LIST,
  USER_WALLET_ADDRESS_HISTORY,
  PROFILE_KYC, PROFILE_SECURITY_OTP_SEND,
} from '../actions/user';
import {
  AUTH_LOGOUT,
  AUTH_REQUEST,
  CHECK_EMAIL_UNIQUE,
  CHECK_LOGIN_UNIQUE,
  REGISTER_REQUEST,
  RESET_EMAIL,
  RESET_PASSWORD,
  SEND_NEW_IDEA,
  AUTH_SOCIAL,
  AUTH_SIGN_IN_TOKEN,
  AUTH_SOCIAL_SET_PARTNER,
} from '../actions/auth';
import { CLIENT_ADS, CLIENT_DEALS, CLIENT_DETAILS, CLIENT_TURNOVER } from '../actions/client';
import {
  PHONE_ADD,
  PHONE_CONFIRM,
  PHONE_DELETE,
  PHONE_DELETE_CONFIRM
} from '../actions/changePhone';
import { SECURITY_REQUEST, CRYPTO_SELL_OFF, CRYPTO_SELL_ON } from '../actions/security';
import {
  FAKE_LOGIN_ON,
  FAKE_LOGIN_OFF,
  PROFILE_SETTINGS_UPDATE,
  SETTINGS_REQUEST,
  NOTIFICATION_POSITION_REQUEST, NOTIFICATION_GROUP_REQUEST
} from '../actions/settings';
import { SEND_API_ACCESS_REQUEST } from '../actions/support';
import {
  ADD_EMERGENCY_WALLET,
  CREATE_EMERGENCY,
  DELETE_EMERGENCY,
  EMERGENCY_WITHDRAW,
  GET_EMERGENCY_DATA
} from '../actions/emergency';

import axios from 'axios';
import Echo from 'laravel-echo';
import Vue from 'vue';
import auth from '../modules/auth';
import router from '../../router';
import {
  FACTOR_CONNECT,
  FACTOR_DISABLE,
  FACTOR_DISCONNECT,
  FACTOR_ENABLE,
  PRIVATE_KEY_CONNECT
} from '../actions/twoFactor';
import {
  APPLY_CLAIM,
  CREATE_CLAIM,
  DELETE_CLAIM,
  GET_INCOMING_CLAIMS,
  GET_OUTGOING_CLAIMS,
  GET_CLAIM_DATA,
  REJECT_CLAIM
} from "../actions/claims";
import {GET_GLOBAL_COMMISSIONS, GET_USER_COMMISSIONS} from "@/store/actions/commission";
import _ from "lodash";

const getUrl = (url, params) => {
  if (!url) {
    console.log('ERROR URL', url, params);
    return cfg.api
  }
  let path = url;
  _.each(params, (value, key) => {
    path = path.replace(`:${key}:`, value);
  });
  return cfg.api + path
};

let rate_timeout = null; // Нужно для отмены запросов при получении курсов

const timeoutTime = 150;

const Events = {
  server: null,
  opened: {
    public: {},
    private: {}
  },
  connect (token) {
    this.server = new Echo({
      broadcaster: 'socket.io',
      host: cfg.socket_host,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    })
  },
  disconnect () {
    if (!this.server) {
      console.log('ERROR', 'no connection to server');
      return
    }
    _.each(this.opened.private, (socket, channel) => {
      console.log('disconnect channel', channel);
      this.server.leave(channel);
    });
    this.opened.private = {}
  },
  name (event, name) {
    return `${event}__${name}`
  },
  private (channel, group, event, commit) {
    return this.open(channel, group, event, commit, 'private')
  },
  /**
   * Открывает канал
   * @param channel - канал подписки
   * @param group - группа событий
   * @param method
   * @param commit
   * @param type
   * @returns {*}
   */
  open (channel, group, method, commit, type = 'public') {
    if (!this.server) {
      console.log('ERROR', 'no connection to server');
      return
    }
    let socket;
    if (this.opened.private[channel]) {
      socket = this.opened.private[channel];
    } else if (this.opened.public[channel]) {
      socket = this.opened.public[channel];
    } else {
      socket = this.server.channel(channel);
      this.opened[type][channel] = socket
      console.log({log: 'EVENT OPEN'}, socket);
    }
    return {
      socket,
      listens: {},
      listen (event, handler) { // method - AdActivated, handler - function
        const listenName = `\\App\\Events\\${group}\\${event}`;
        if (!this.listens[listenName]) {
          this.listens[listenName] = {[method]: handler};
          socket.listen(listenName, (message) => {
            commit(EVENT_COMMIT, {
              event,
              channel,
              method,
              data: message.data
            });
            _.each(this.listens[listenName], (listenHandler, listenEvent) => {
              if (typeof listenHandler === 'function') {
                listenHandler(event, message.data, group);
              }
            });
            return this
          })
        } else {
          this.listens[listenName][method] = handler;
        }
        return this
      }
    }
  },
  close (channel) {
    if (!this.server) {
      console.log('ERROR', 'no connection to server');
      return
    }
    if (this.opened.private[channel]) {
      this.server.leave(channel);
      delete this.opened.private[channel];
      console.log({log: 'EVENT CLOSE'}, channel)
    }
    if (this.opened.public[channel]) {
      this.server.leave(channel);
      delete this.opened.public[channel];
      console.log({log: 'EVENT CLOSE'}, channel);
    }
  }

};

const reject = (response) => {
  response.data.status = response.status; //TODO поискать более изящное решение
  return Promise.reject(response.data)
};

const errorHandle = (req, commit, dispatch, method, retryFunc = false) => {
  let timeout = req + '';

  if(timeout === `Error: timeout of ${timeoutTime}ms exceeded`) {
    /* Сработал таймаут, сделать запрос наново */
    if (retryFunc) {
      retryFunc();
    }
  } else if(req.response) {
    const response = req.response;

    switch (response.status) {
      // ошибки покажут шестеренки (ака сайт недоступен)
      case 500:
        commit(HTTP_ERROR, {name: method, data: response.data, status: response.status});
        router.push({name: 'ServerError'});
        return reject(response);
      case 404:
        commit(HTTP_ERROR, {name: method, data: response.data, status: response.status});
        router.push({name: 'NotFound'});
        return reject(response);
      case 403:

        commit(HTTP_ERROR, {name: method, data: response.data, status: response.status});
        if (response.data && response.data.reason === 'no-kyc') {
          if (!router.history.current.meta.no_kyc) { // Если роут должен быть закрыт без kyc
            router.push({ name: 'AwesomeProfile' })
          }
        } else {
          router.push({name: 'NotAccess'});
        }
        return reject(response);
      case 423:
        commit(HTTP_ERROR, {name: method, data: response.data, status: response.status});
        router.push({name: 'NotAccess'});
        return reject(response);

      // Если выдает ошибку - неавторизован, то убираем всю информацию о пользователе
      case 401:
        commit(AUTH_REQUEST, {token: null});
        commit(AUTH_LOGOUT);

        router.push({
          name: 'Login',
          query: {
            next: router.currentRoute.query.next
              ? router.currentRoute.query.next
              : router.currentRoute.fullPath,
          },
        });

        return reject(response);

      // ошибки mysql (универсальный ответ с бэка) - пробуем еще запрос
      case 503:
        if (state.failedHttpRequests >= state.maxFailedHttpRequests) {
          response.data = {message: 'Service unavailable'};
          commit(HTTP_ERROR, {name: method, data: response.data, status: response.status});
          return reject(response)
        }
        dispatch(INC_FAILED_HTTP_REQ_COUNTER);
        if (retryFunc) {
          retryFunc();
        }
        break;

      default:
        return reject(response);
    }
  } else {
    commit(HTTP_ERROR, {name: method, data: req, status: undefined});
    return Promise.reject(req)
  }
};

const state = {
  path: {
    [AUTH_REQUEST]: '/auth/sign-in',
    [AUTH_SOCIAL]: '/auth/:provider:/url',
    [CHECK_LOGIN_UNIQUE]: '/auth/check-login',
    [CHECK_EMAIL_UNIQUE]: '/auth/check-email',
    [AUTH_LOGOUT]: '/auth/sign-out',
    [RESET_EMAIL]: '/reset-link',
    [RESET_PASSWORD]: '/reset-password',
    [REGISTER_REQUEST]: '/auth/sign-up',
    [SEND_NEW_IDEA]: '/idea',
    [AD_CREATE]: '/offer',
    [AD_EDIT]: '/offer/:id:',
    [AD_UPDATE]: '/offer/:id:',
    [AD_ACTIVATE]: '/offer/:id:/activate',
    [AD_DEACTIVATE]: '/offer/:id:/deactivate',
    [AD_MARKET]: '/market/:id:',
    [AD_DELETE]: '/offer/:id:',
    [AD_FORM_DATA]: '/:type:',
    [AD_ALL]: '/directory/all/json',
    [ADS_SELLERS]: '/market/sale',
    [ADS_BUYERS]: '/market/buy',
    [REQUISITE_TYPES]: '/requisite/type',
    [REQUISITE_VALIDATE]: '/requisite/:alias:/validate?requisite=:requisite:',
    [REQUISITE_ADD]: '/offer/:adId:/requisite',
    [REQUISITE_UPDATE]: '/requisite/:requisite:',
    [REQUISITE_DELETE]: '/requisite/:requisite:',
    [REQUISITE_DEALS_LIMITS_ADD]: '/requisites/deals-limits',
    [REQUISITE_DEALS_LIMITS_UPDATE]: '/requisites/deals-limits/:id:',
    [REQUISITE_DEALS_LIMITS_DELETE]: '/requisites/deals-limits/:id:',
    [REQUISITE_DEALS_LIMITS_RESET]: '/requisites/deals-limits/:id:/reset',
    [ADS_PAYMENT_SYSTEMS]: '/market/stats/payment-systems',
    [CLIENT_DETAILS]: '/user/:id:',
    [CLIENT_DEALS]: '/user/:id:/deals',
    [CLIENT_ADS]: '/user/:id:/offers',
    [CLIENT_TURNOVER]: '/user/:id:/turnover/summary',
    [AD_USER_REVIEWS]: '/user/:userId:/reviews',
    [DASHBOARD_DEALS]: '/dashboard/deals',
    [DASHBOARD_ADS]: '/dashboard/offers',
    [DASHBOARD_AD_STATISTIC]: '/stats/offer/:id:',
    [DASHBOARD_DEAL_STATISTIC]: '/stats/deals',
    [DEAL_LISTEN]: '/deal/:id:',
    [DEAL_CREATE]: '/deal',
    [DEAL_CHAT_MESSAGE]: '/chat/deal/:dealId:',
    [DEAL_CHAT_GET_MESSAGES]: '/chat/:id:',
    [DEAL_ACTION]: '/deal/:dealId:/:action:',
    [LOAD_DEAL]: '/stats/deals/simple',
    [LOAD_SUMMARY]: '/stats/deals/simple/summary',
    [USER_PROFILE]: '/profile',
    [USER_PRO_RATING]: '/profile/pro-rating',
    [USER_WALLET_ADDRESS]: '/balance/:cryptoCode:/wallet',
    [USER_WALLET_WITHDRAW]: '/balance/withdraw',
    [USER_WALLET_WITHDRAW_PAID]: '/balance/withdraw-paid',
    [USER_WALLET_WITHDRAW_STANDARD]: '/balance/withdraw-standard',
    [USER_WALLET_WITHDRAW_AUTO]: '/balance/withdraw-auto',
    [USER_WALLET_WITHDRAW_AUTO_LIST]: '/balance/:cryptoCode:/withdraw-auto',
    [USER_WALLET_WITHDRAW_AUTO_DELETE]: '/balance/withdraw-auto/disable',
    [USER_WALLET_HISTORY]: '/balance/:cryptoCode:/transactions',
    [USER_WALLET_PAYING]: '/balance/:cryptoCode:/payin',
    [USER_WALLET_PAYOUT]: '/balance/:cryptoCode:/payout',
    [USER_WALLET_COMISSIONS]: '/balance/commissions',
    [USER_WALLET_VALIDATE]: '/balance/wallet/is-address-local',
    [USER_WALLET_ADD_PAYOUT]: '/balance/payout-wallet',
    [USER_WALLET_DELETE_PAYOUT]: '/balance/payout-wallet/:id:',
    [USER_WALLET_PAYOUT_LIST]: '/balance/payout-wallet',
    [USER_WALLET_ADDRESS_HISTORY]: '/balance/wallets-history',
    [USER_SPOOFING_PROTECTION]: '/profile/spoofing-protection',
    [USER_SESSIONS_LIST]: '/profile/sessions',
    [USER_SECURITY_LOGS]: '/profile/security/logs',
    [IMPORTANT_NOTIFICATION_LIST]: '/notification',
    [IMPORTANT_NOTIFICATION_READ]: '/notification/markRead',
    [APPEAL_UPDATE]: '/profile/updateAppeal',
    [AVATAR_UPDATE]: '/profile/avatar',
    [AVATAR_DELETE]: '/profile/avatar',
    [PASSWORD_UPDATE]: '/profile/security/change-password',
    [USER_GEOLOCATION]: '/locator/info',
    [USER_BALANCE]: '/profile/balance',
    [DEAL_GET_REVIEWS]: '/profile/reviews/outcome',
    [DEAL_SEND_REVIEW]: '/deal/:dealId:/review',
    [NOTE_LIST]: '/profile/notes',
    [NOTE_PUT]: '/user/:userId:/notes',
    [NOTE_DELETE_USER]: '/user/:userId:/notes',
    [NOTE_DELETE_ONE]: '/profile/notes/:noteId:',
    [HTTP_PING]: '/ping',
    [PHONE_ADD]: '/profile/phone/add',
    [PHONE_CONFIRM]: '/profile/phone/confirm',
    [PHONE_DELETE]: '/profile/phone/delete',
    [PHONE_DELETE_CONFIRM]: '/profile/phone/complete-delete',
    [EMAIL_UPDATE]: '/profile/email-update',
    [EMAIL_CONFIRM]: '/profile/security/:type:',
    [EMAIL_START_CONFIRM]: '/profile/security/start-confirm-email',
    [SOCIAL_EMAIL_UPDATE]: '/profile/social/setEmail',
    [SOCIAL_LOGIN_UPDATE]: '/profile/social/setLogin',
    [SECURITY_REQUEST]: '/profile/security',
    [CRYPTO_SELL_ON]: '/profile/security/crypto-sell/enable',
    [CRYPTO_SELL_OFF]: '/profile/security/crypto-sell/disable',
    [FACTOR_CONNECT]: '/profile/security/2fa/connect',
    [FACTOR_ENABLE]: '/profile/security/2fa/enable',
    [FACTOR_DISABLE]: '/profile/security/2fa/disable',
    [FACTOR_DISCONNECT]: '/profile/security/2fa/disconnect',
    [PRIVATE_KEY_CONNECT]: '/profile/security/private-key/connect',
    [SEARCH_USERS]: '/user',
    [NOTIFICATION_POSITION_REQUEST]: '/profile/settings/:parameter:',
    [NOTIFICATION_GROUP_REQUEST]: '/profile/settings/:parameter:',
    [PROFILE_SETTINGS_UPDATE]: '/profile/settings/:parameter:',
    [FAKE_LOGIN_ON]: '/profile/settings/enable-fake-login',
    [FAKE_LOGIN_OFF]: '/profile/settings/disable-fake-login',
    [SETTINGS_REQUEST]: '/profile/settings',
    [PROFILE_PARTNERSHIP_GET]: '/partnership/profile',
    [PROFILE_MAKE_PARTNERSHIP]: '/partnership/sign-up',
    [PROFILE_REQUESTS]: '/partnership/requests',
    [SEND_API_ACCESS_REQUEST]: '/support/api-access-request',
    [GET_EMERGENCY_DATA]: '/balance/emergency',
    [CREATE_EMERGENCY]: '/balance/emergency/create',
    [ADD_EMERGENCY_WALLET]: '/balance/emergency/add-wallet',
    [DELETE_EMERGENCY]: '/balance/emergency/remove',
    [EMERGENCY_WITHDRAW]: '/balance/emergency/withdraw',
    [TEST_ERROR_REQUEST]: '/testError/:code:',
    [AD_PRICE_TICKER]: '/ticker/:crypto:/:fiat:',
    [AUTH_SIGN_IN_TOKEN]: '/auth/sign-in-token',
    [AUTH_SOCIAL_SET_PARTNER]: '/profile/social/setPartner',
    [REQUEST_PRICE]: '/offer/auto-price/options/:crypto:/:fiat:',
    [REQUEST_RATES]: '/offer/auto-price/rates/:crypto:/:fiat:',
    [GET_OUTGOING_CLAIMS]: '/claim/outgoing',
    [GET_INCOMING_CLAIMS]: '/claim/incoming',
    [GET_CLAIM_DATA]: '/claim/:id:',
    [CREATE_CLAIM]: '/claim',
    [REJECT_CLAIM]: '/claim/:id:/reject',
    [APPLY_CLAIM]: '/claim/:id:/apply',
    [DELETE_CLAIM]: '/claim/:id:',
    [RESET_TURNOVER]: '/requisite/:id:/reset-turnover',
    [SET_STRATEGY]: '/offer/:id:/strategy',
    [GET_OFFER_TIME]: '/offer/time',
    [GET_GLOBAL_COMMISSIONS]: '/commissions',
    [GET_USER_COMMISSIONS]: '/profile/commissions',
    [PROFILE_USER_IP_LIST]: '/profile/ip',
    [PROFILE_USER_IP_ADD]: '/profile/ip',
    [PROFILE_USER_IP_DELETE]: '/profile/ip/:id:',
    [PROFILE_KYC]: '/profile/kyc',
    [GET_USER_COMMISSIONS]: '/profile/commissions',
    [PROFILE_SECURITY_OTP_SEND] : '/profile/security/otp/send',
  },
  events: {},
  loading: {},
  loadingRequest: {},
  error: {},
  opened: [],
  closed: [],
  pagination: {},
  failedHttpRequests: 0,
  maxFailedHttpRequests: cfg.maxFailedHttpRequests,
  connectRequestsTimeout: cfg.connectRequestsTimeout,
  failedConnectRequests: {},
  maxFailedConnectRequests: cfg.maxFailedConnectRequests,
};

const getters = {
  loading: (state) => name => name ? state.loading[name] : state.loading,
  loadingRequest: (state) => name => name ? state.loadingRequest[name] : state.loadingRequest,
  errorRequest: (state) => state.error,
  events: (state) => (event, method) => {
    if (event && method) {
      return state.events[Events.name(event, method)]
    } else if (event) {
      return state.events[event] || false
    }
    return false
  }
};

const actions = {
  [EVENT_LOGOUT_CLEAR]: ({commit}) => {
    commit(EVENT_LOGOUT_CLEAR);
  },
  [INC_FAILED_HTTP_REQ_COUNTER]: ({state, commit, dispatch}) => {
    commit(INC_FAILED_HTTP_REQ_COUNTER);
  },
  [RESET_FAILED_HTTP_REQ_COUNTER]: ({state, commit, dispatch}) => {
    commit(RESET_FAILED_HTTP_REQ_COUNTER);
  },
  [INC_FAILED_CONNECT_REQ_COUNTER]: ({state, commit, dispatch}, method) => {
    commit(INC_FAILED_HTTP_REQ_COUNTER, method);
  },
  [RESET_FAILED_CONNECT_REQ_COUNTER]: ({state, commit, dispatch}, method) => {
    commit(RESET_FAILED_CONNECT_REQ_COUNTER, method);
  },
  [SOCKET_INIT]: ({state, commit, dispatch}) => {
    console.log('socket.connect', auth.state.token);
    Events.connect(auth.state.token);
  },
  [SOCKET_CLOSE]: ({state, commit, dispatch}) => {
    console.log('socket.disconnect', auth.state.token);
    Events.disconnect();
  },
  [TEST_ERROR_REQUEST]: ({state, commit, dispatch}, {code}) => {
    return dispatch(HTTP_GET, {
      method: TEST_ERROR_REQUEST,
      params: {code},
      response: false
    })
  },
  [ERROR_HANDLE]: ({state, commit, dispatch}, {req, method, response, params, data, auto, headers, cancel, retryFunc}) => {
    if (cancel === true) {
      if (!state.failedConnectRequests[method]) {
        state.failedConnectRequests[method] = 0;
      }
      if (state.failedConnectRequests[method] >= state.maxFailedConnectRequests) {
        commit(LOADING, {name: method, value: 'error', data: req});
        return errorHandle(req, commit, dispatch, method, retryFunc);
      } else {
        dispatch(INC_FAILED_CONNECT_REQ_COUNTER, method);
        dispatch(HTTP_GET, {method, response, params, data, auto});
      }
    } else {
      commit(LOADING, {name: method, value: 'error', data: req});
      return errorHandle(req, commit, dispatch, method, retryFunc);
    }
  },
  [HTTP_GET]: ({state, commit, dispatch}, {method, response, params, data, auto, timeoutOff=false,}) => {
    console.log({log: 'HTTP_GET'}, method, 'params', params, data);
    response = response || response === false ? response : method;
    commit(LOADING, {name: method, value: 'loading'});
    const source = axios.CancelToken.source();
    let cancel = false;
    let timeout = setTimeout(() => {
      if (cancel === false && timeoutOff === false) {
        cancel = true;
        source.cancel();
      }
    }, state.connectRequestsTimeout);

    return axios.get(getUrl(state.path[method], params), {...data, cancelToken: source.token}).then((resp) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (resp.data.data) {
        if (auto !== false && response !== false) {
          commit(response, resp.data.data)
        }
        commit(LOADING, {name: method, value: 'loaded', data: resp.data.data});
        if (resp.pagination) {
          commit(PAGINATION, {name: method, data: resp.pagination});
        }
      } else {
        commit(LOADING, {name: method, value: 'error', data: resp});
      }
      dispatch(RESET_FAILED_HTTP_REQ_COUNTER);
      dispatch(RESET_FAILED_CONNECT_REQ_COUNTER, method);
      return resp.data;
    }).catch(req => {
      const retryFunc = function () {
        dispatch(HTTP_GET, {method, response, params, data, auto});
      };
      return dispatch(ERROR_HANDLE, {req, method, response, params, data, auto, cancel, retryFunc});
    });
  },
  [HTTP_POST]: ({state, commit, dispatch}, {method, response, params, data, auto, headers}) => {
    response = response || response === false ? response : method;
    headers = headers || {};
    commit(LOADING, {name: method, value: 'loading'});
    return axios.post(getUrl(state.path[method], params), data, {headers}).then(resp => {
      if (resp.data && resp.data.data) {
        if (auto !== false && response !== false) {
          commit(response, resp.data.data);
        }
        commit(LOADING, {name: method, value: 'loaded', data: resp.data.data});
      } else {
        commit(LOADING, {name: method, value: 'error', data: resp});
      }
      dispatch(RESET_FAILED_HTTP_REQ_COUNTER);
      return resp.data;
    }).catch(req => {
      commit(LOADING, {name: method, value: 'error', data: req});
      return errorHandle(req, commit, dispatch, method, function () {
        dispatch(HTTP_POST, {method, response, params, data, auto, headers});
      })
    })
  },
  [HTTP_PUT]: ({state, commit, dispatch}, {method, response, params, data, auto}) => {
    console.log({log: 'HTTP_PUT'}, method, params, data);
    response = response || response === false ? response : method;
    commit(LOADING, {name: method, value: 'loading'});
    return axios.put(getUrl(state.path[method], params), data).then(resp => {
      if (resp.data && resp.data.data) {
        if (auto !== false && response !== false) {
          commit(response, resp.data.data);
        }
        commit(LOADING, {name: method, value: 'loaded', data: resp.data.data});
      } else {
        commit(LOADING, {name: method, value: 'error', data: resp});
      }
      dispatch(RESET_FAILED_HTTP_REQ_COUNTER);
      return resp.data;
    }).catch(req => {
      commit(LOADING, {name: method, value: 'error', data: req});
      return errorHandle(req, commit, dispatch, method, function () {
        dispatch(HTTP_PUT, {method, response, params, data, auto});
      })
    })
  },
  [HTTP_DELETE]: ({state, commit, dispatch}, {method, response, params, body = {}, auto}) => {
    console.log({log: 'HTTP_DELETE'}, method, params, body);
    response = response || response === false ? response : method;
    commit(LOADING, {name: method, value: 'loading'});
    return axios.delete(getUrl(state.path[method], params), body).then(resp => {
      if (resp.data && resp.data.data) {
        if (auto !== false && response !== false) {
          commit(response, resp.data.data);
        }
        commit(LOADING, {name: method, value: 'loaded', data: resp.data.data});
      } else {
        commit(LOADING, {name: method, value: 'error', data: resp});
      }
      dispatch(RESET_FAILED_HTTP_REQ_COUNTER);
      return resp.data;
    }).catch(req => {
      commit(LOADING, {name: method, value: 'error', data: req});
      return errorHandle(req, commit, dispatch, method, function () {
        dispatch(HTTP_DELETE, {method, response, params, data, auto});
      })
    })
  },
  [EVENT_OPEN]: ({commit}, {channel, group, method, event, type}) => {
    type = type || 'public';
    // TODO: сделать механизм похожий на url
    const openChannel = Events.open(channel, group, method, commit, type);
    if (Array.isArray(event)) {
      _.each(event, (eventName) => {
        openChannel.listen(eventName, (m, message, g) => {
          if (typeof method === 'function') {
            method(message, m);
          } else {
            commit(method, message);
          }
        })
      })
    } else {
      openChannel.listen(event, (m, message, g) => {
        if (typeof method === 'function') {
          method(message, m);
        } else {
          commit(method, message);
        }
      })
    }
  },
  [EVENT_CLOSE]: ({commit}, {channel}) => {
    Events.close(channel);
  },
  [EVENT_RATES_OPEN]: ({dispatch, rootState}) => {
    dispatch(EVENT_OPEN, {
      channel: `rates`,
      group: 'CryptoExchange',
      method(rate) {
        let selectedCurrency = rootState.user.selectedCurrency;
        if (rate.fiat.toLowerCase() === selectedCurrency && rate.market !== 'garantex') {
          if (rate_timeout) {
            clearTimeout(rate_timeout);
          }
          rate_timeout = setTimeout(() => {
            dispatch(REQUEST_RATES, {crypto: 'btc', fiat: selectedCurrency})
          }, 300)
        }
      },
      event: 'AutoPriceRateUpdatedEvent',
    });
  },
  [EVENT_MARKET_OPEN]: ({dispatch, commit}) => {
    const handler = (type, ad) => {
      if (ad.price) {
        dispatch(ADS_UPDATE_MARKET, ad);
      }
    };
    Events.open('market.ads', 'Ad', EVENT_MARKET_OPEN, commit).
      listen('AdPlaced', handler).
      listen('AdActivated', handler).
      listen('AdDeactivated', handler).
      listen('AdLimitChanged', handler).
      listen('AdPriceChanged', handler);
  },
  [EVENT_MARKET_CLOSE]: ({dispatch, state}) => {
    Events.close('market.ads');
    dispatch(ADS_CLOSE_MARKET);
  },
  [DEAL_LISTEN]: ({commit, dispatch}, {deal, dealId}) => { // TODO: move to module deal
    const open = () => {
      return // TODO: отключаем персональные события?
      const handler = (type, data) => {
        console.log('DEAL CHANGE', type, data);
        commit(DEAL_LISTEN, {
          dealId,
          deal: data
        })
      };
      Events.private(`deals.${dealId}`, 'Deal', DEAL_LISTEN, commit).
        listen('DealCanceled', handler).
        listen('DealDisputed', handler).
        listen('DealFinished', handler).
        listen('DealSellerRequisite', handler).
        listen('DealPaid', handler);
    };
    // если нет переданных данных о сделке
    if (!deal) {
      // загружаем дополнительно сделку
      dispatch(DEAL, {id: dealId}).then(open);
    } else {
      commit(DEAL_LISTEN, deal);
      // открываем канал на изменение сделки
      open();
    }
  },
  [DEAL_STOP_LISTEN]: ({commit, dispatch}, {dealId}) => {
    // Events.close(`deals.${dealId}`);
    commit(DEAL_STOP_LISTEN, {dealId}); // удаляет информацию о сделке из кеша
  },
  [DEAL_CHAT_OPEN]: ({commit, dispatch, state, getters}, {dealId, chatId, pagination}) => {
    if (chatId > 0) {
      console.log({log: 'DEAL_CHAT_OPEN'}, chatId);
      return dispatch(DEAL_CHAT_GET_MESSAGES, {
        chatId,
        dealId,
        pagination
      }).then(() => {
        // TODO: проверить сделку?
        const handler = (type, message) => {
          console.log({log: 'CHAT_MESSAGE'}, message);
          commit(DEAL_CHAT_MESSAGE, {
            chatId,
            dealId,
            message
          })
        };
        Events.private(`private-chat.${chatId}`, 'Chat', DEAL_CHAT_OPEN, commit).listen('MessagePlaced', handler);
      })
    }
    return Promise.resolve('empty')
  },
  [HTTP_PING]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: HTTP_PING,
      response: false
    })
  },
  [DEAL_CHAT_CLOSE]: ({commit}, {chatId}) => {
    console.log({log: 'DEAL_CHAT_CLOSE'}, chatId);
    Events.close(`chat.${chatId}`);
  }
};

const mutations = {
  [EVENT_LOGOUT_CLEAR]: (state) => {
    state.events = {};
    state.loading = {};
    state.loadingRequest = {};
    state.error = {};
    state.opened = [];
    state.closed = [];
    state.pagination = {};
    state.failedHttpRequests = 0;
    state.maxFailedHttpRequests = cfg.maxFailedHttpRequests;
    state.connectRequestsTimeout = cfg.connectRequestsTimeout;
    state.failedConnectRequests = {};
    state.maxFailedConnectRequests = cfg.maxFailedConnectRequests;
  },
  [RESET_FAILED_HTTP_REQ_COUNTER]: (state) => {
    state.failedHttpRequests = 0;
  },
  [INC_FAILED_HTTP_REQ_COUNTER]: (state) => {
    state.failedHttpRequests += 1;
  },
  [RESET_FAILED_CONNECT_REQ_COUNTER]: (state, method) => {
    state.failedConnectRequests[method] = 0;
  },
  [INC_FAILED_CONNECT_REQ_COUNTER]: (state, method) => {
    state.failedConnectRequests[method] += 1;
  },
  [LOADING]: (state, {name, value, data}) => {
    console.log({log: 'LOADING'}, name, value, data);
    Vue.set(state.loading, name, value);
    Vue.set(state.loadingRequest, name, data);
  },
  [HTTP_ERROR]: (state, {name, data, status}) => {
    if (!state.error[name]) {
      data.status = status;
      console.log({log: 'ERROR'}, name, data);
      Vue.set(state.error, name, data);
    }
  },
  [HTTP_ERROR_CLEAR]: (state) => {
    Vue.set(state, 'error', {});
  },
  [PAGINATION]: (state, {name, data}) => {
    Vue.set(state.pagination, name, data);
  },
  [RESET_ERRORS]: (state, {name, data}) => {
    if (state.loadingRequest[name]) {
      Vue.set(state.loadingRequest, name, data);
    }
  },
  [EVENT_COMMIT]: (state, {event, channel, method, data}) => {
    console.log({log: 'EVENT COMMIT'}, event, method, data);
    const name = Events.name(event, method);
    Vue.set(state.events, name, {
      channel,
      event,
      method,
      data,
      count: state.events[name] ? state.events[name].count + 1 : 1
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
