export default {
  methods: {
    btcAmountToFormat(amount) {
      return parseFloat(amount).toFixed(8);
    },

    divideDate(date) {
      return {
        date: this.$moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('L'),
        time: this.$moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('LT'),
        dateDay: this.$moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('DD MMM'),
      };
    },

    disabledDate(time) {
      const today = this.$moment();
      return this.$moment(time).isAfter(today);
    },

    noun(number, one, two, five) {
      let num = Math.abs(number);
      num %= 100;
      if (num >= 5 && num <= 20) {
        return five;
      }
      num %= 10;
      if (num === 1) {
        return one;
      }
      if (num >= 2 && num <= 4) {
        return two;
      }
      return five;
    },

    scrollToPos(position) {
      window.FinexScroll.scrollTo({y: position}, 500); // TODO: find solution without window
    },

    thousandSeparator(str) {
      let nStr = str + '';
      let x = nStr.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      let rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
      }
      return x1 + x2;
      // return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    replaceStringParams(url, data) {
      const regex = new RegExp(':(' + Object.keys(data).join('|') + ')', 'g');

      return url.replace(regex, (m, $1) => data[$1] || m);
    },

    getCryptoAmount(offer, deal) {
      return offer
        ? deal.is_sale
          ? (parseFloat(deal.crypto_amount) + parseFloat(deal.commissions.offer)).toFixed(8)
          : (parseFloat(deal.crypto_amount) - parseFloat(deal.commissions.offer)).toFixed(8)
        : deal.is_sale
          ? (parseFloat(deal.crypto_amount) + parseFloat(deal.commissions.client)).toFixed(8)
          : (parseFloat(deal.crypto_amount) - parseFloat(deal.commissions.client)).toFixed(8);
    },

    floatPlus(...numbers) {
      let count = 0;
      const num = 100000000;
      numbers.forEach(n => count += n * num);
      return parseFloat((count/num).toFixed(8));
    },
    convertStringToNumber(string) {
      return parseFloat(parseFloat(string).toFixed(8));
    },
    validate_IPv46Address(address) {
      let ipv46_regex = /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;
      return ipv46_regex.test(address)
    },
    validateEmail(email) {
      const email_regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return email_regex.test(email);
    },
  },
};
