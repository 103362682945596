import {
  AD,
  AD_ACTIVATE,
  AD_ALL,
  AD_CREATE,
  AD_DEACTIVATE,
  AD_DELETE,
  AD_EDIT,
  AD_FORM_DATA,
  AD_LOGOUT_CLEAR,
  AD_MARKET,
  AD_PRICE_TICKER,
  AD_UPDATE,
  AD_USER_REVIEWS,
  AD_VIEW_CLOSE,
  AD_VIEW_OPEN,
  DELETED_AD,
  REQUEST_BANKS,
  REQUEST_COUNTRIES,
  REQUEST_CRYPTO,
  REQUEST_CURRENCIES,
  REQUEST_PAYMENTS,
  AD_EVENTS_SUB,
  AD_EVENTS_UNSUB,
  REQUISITE_TYPES,
  REQUISITE_VALIDATE,
  REQUISITE_ADD,
  REQUISITE_UPDATE,
  REQUISITE_DELETE,
  REQUEST_PRICE,
  RESET_TURNOVER,
  SET_STRATEGY,
  GET_OFFER_TIME,
  AD_REQUISITES,
  REQUISITE_DEALS_LIMITS_ADD,
  REQUISITE_DEALS_LIMITS_UPDATE,
  REQUISITE_DEALS_LIMITS_DELETE,
  REQUISITE_DEALS_LIMITS_RESET,
} from '../actions/ad'
import Vue from 'vue';
import {EVENT_CLOSE, EVENT_OPEN, HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT} from '../actions/events';

const state = {
  ads: {},
  reviews: [],
  adsForDelete: {},
  priceTickers: {},
  requisiteTypes: [],
  errorTemplates: {
    price: 'Цена должна быть в пределах :minPrice :currency и :maxPrice :currency. На основных биржах цена :basePrice :currency.',
    botPriceSale: 'Лимит должен быть в пределах :minPrice :currency и :adPriceSale :currency',
    botPriceBuy: 'Лимит должен быть в пределах :adPriceBuy :currency и :maxPrice :currency',
  },
  autoPrice: {},
  time: []
};

const getters = {
  getAd: state => id => state.ads[id],
  getRequisiteTypes: state => state.requisiteTypes,
  getReviews: state => state.reviews,
  getForDelete: state => state.adsForDelete,
  getPriceTickers: state => state.priceTickers,
  errorTemplates: state => state.errorTemplates,
  getAutoPrice: state => state.autoPrice,
  getOfferTime: state => state.time
};

const directories = {
  loaded: false,
  requests: [],
  data: null,
};
const actions = {
  [AD_LOGOUT_CLEAR]: ({commit}) => {
    commit(AD_LOGOUT_CLEAR);
  },
  [AD_PRICE_TICKER]: ({commit, dispatch}, {crypto, fiat}) => {
    return dispatch(HTTP_GET, {
      method: AD_PRICE_TICKER,
      params: {crypto, fiat},
    });
  },
  [AD_ALL]: ({commit, dispatch}) => {
    return new Promise(resolve => {
      if (directories.data) {
        resolve(directories.data);
      } else {
        if (directories.loaded) {
          directories.requests.push(resolve);
        } else {
          directories.loaded = true;
          dispatch(HTTP_GET, {
            method: AD_ALL,
          }).then(response => {
            if (response && response.countries) {
            } else {
              try {
                response = JSON.parse(response.split(' = ')[1]);
              } catch (e) {
                console.error(e);
                response = {};

              }
            }
            let data = {};
            data.crypto = response.crypto_currencies.map(crypto => {
              return {
                ...crypto,
                short_title: crypto.short_title.toUpperCase(),
              };
            });
            data.countries = {};
            data.currencies = {};
            data.payment_systems = {};
            data.banks = {};
            if (response.countries) {
              response.countries.forEach(country => {
                if(country.currencies.length === 0 || country.payment_systems.length === 0) return;
                country.value = country.country.value;
                data.currencies[country.currency.value] = country.currency; // ВСЕ
                country.banks.forEach(bank => {
                  data.banks[bank.value] = bank; // ВСЕ
                });
                data.countries[country.value] = {
                  ...country.country,
                  main_currency: country.currency,
                  payment_systems: {},
                  currencies: {},
                };
                country.payment_systems.forEach(system => {
                  let system_data = {
                    is_bank: system.is_bank,
                    value: system.value,
                    label: system.label,
                    icon: system.icon,
                  };
                  data.payment_systems[system.value] = {...system, currencies: system.currencies}; // ВСЕ
                  data.countries[country.value].payment_systems[system.value] = system_data;

                  system.currencies.forEach(currency => {
                    data.currencies[currency.value] = currency; // ВСЕ
                    if (!data.countries[country.value].currencies[currency.value]) {
                      data.countries[country.value].currencies[currency.value] = {
                        ...currency,
                        payment_systems: {},
                      };
                    }
                    let banks = null;
                    if (system_data.is_bank) {
                      banks = {};
                      system_data.banks = {};
                      country.banks.forEach(bank => {
                        let bank_data = {
                          label: bank.label,
                          value: bank.value,
                          icon: bank.icon,
                          currencies: bank.currencies,
                        };
                        system_data.banks[bank.value] = bank_data; // ВСЕ
                        bank.currencies.forEach(bank_currency => {
                          if (currency.value === bank_currency.value) {
                            banks[bank.value] = bank_data;
                          }
                        });
                      });
                    }
                    data.countries[country.value].currencies[currency.value].payment_systems[system.value] = {
                      ...system_data,
                      banks,
                    };
                  });
                });
              });
            }

            directories.data = data;
            console.log({log: 'DIRECTORIES'}, directories.data);
            directories.requests.forEach(next => {
              next(data);
            });
            resolve(data);
          });
        }
      }
    });
  },
  [AD_UPDATE]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_PUT, {
      method: AD_UPDATE,
      params: {id},
      data,
    });
  },
  [AD_ACTIVATE]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_PUT, {
      method: AD_ACTIVATE,
      response: AD_UPDATE,
      params: {id},
    });
  },
  [AD_DEACTIVATE]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_PUT, {
      method: AD_DEACTIVATE,
      response: AD_UPDATE,
      params: {id},
    });
  },
  [AD_MARKET]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_GET, {
      method: AD_MARKET,
      response: AD_UPDATE,
      params: {id},
      data,
    });
  },
  [AD_CREATE]: ({commit, dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: AD_CREATE,
      response: AD_UPDATE,
      data,
    });
  },
  [AD_EDIT]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_PUT, {
      method: AD_EDIT,
      response: AD_UPDATE,
      params: {id},
      data,
    });
  },
  [AD_DELETE]: ({commit, dispatch}, id) => {
    return dispatch(HTTP_DELETE, {
      method: AD_DELETE,
      params: {id},
      data: {},
    });
  },
  [REQUISITE_TYPES]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: REQUISITE_TYPES,
      response: REQUISITE_TYPES,
    });
  },
  [REQUISITE_VALIDATE]: ({commit, dispatch}, {alias, requisite}) => {
    return dispatch(HTTP_GET, {
      method: REQUISITE_VALIDATE,
      response: false,
      params: {alias, requisite},
    }).catch((error) => {
      return error;
    });
  },
  [REQUISITE_UPDATE]: async ({commit, dispatch}, {requisite, data}) => {
    try {
      return await dispatch(HTTP_PUT, {
        method: REQUISITE_UPDATE,
        response: false,
        params: {requisite},
        data,
      });
    } catch (e) {
      await Promise.reject(e)
    }
  },
  [REQUISITE_ADD]: ({commit, dispatch}, {adId, data}) => {
    return dispatch(HTTP_POST, {
      method: REQUISITE_ADD,
      response: false,
      params: {adId},
      data,
    });
  },
  [REQUISITE_DELETE]: ({commit, dispatch}, {requisite}) => {
    return dispatch(HTTP_DELETE, {
      method: REQUISITE_DELETE,
      response: false,
      params: {requisite},
    });
  },
  [REQUISITE_DEALS_LIMITS_ADD]: ({dispatch}, data) => {
    return dispatch(HTTP_POST, {
      method: REQUISITE_DEALS_LIMITS_ADD,
      response: false,
      data
    });
  },
  [REQUISITE_DEALS_LIMITS_UPDATE]: ({dispatch}, {id, limit, timeout_minutes }) => {
    return dispatch(HTTP_PUT, {
      method: REQUISITE_DEALS_LIMITS_UPDATE,
      response: false,
      params: {id},
      data: {limit, timeout_minutes}
    });
  },
  [REQUISITE_DEALS_LIMITS_DELETE]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_DELETE, {
      method: REQUISITE_DEALS_LIMITS_DELETE,
      response: false,
      params: {id},
    });
  },
  [REQUISITE_DEALS_LIMITS_RESET]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_PUT, {
      method: REQUISITE_DEALS_LIMITS_RESET,
      response: false,
      params: {id},
    });
  },
  [RESET_TURNOVER]: ({commit, dispatch}, {id}) => {
    return dispatch(HTTP_PUT, {
      method: RESET_TURNOVER,
      response: false,
      params: {id}
    });
  },
  [SET_STRATEGY]: ({commit, dispatch}, {id, data}) => {
    return dispatch(HTTP_PUT, {
      method: SET_STRATEGY,
      response: AD_UPDATE,
      params: {id},
      data
    });
  },
  [AD]: ({commit, dispatch}, {id, req = false}) => {
    let obj = {
      method: AD_UPDATE,
      response: AD_UPDATE,
      params: { id },
    }
    if (req) { // Если нужно обновить только реквизиты в объявлении, без общего коммита
      obj.auto = false
      return dispatch(HTTP_GET, obj).then((response) => {
        commit(AD_REQUISITES, response)
      })
    }
    return dispatch(HTTP_GET, obj);
  },
  [AD_USER_REVIEWS]: ({commit, dispatch}, params) => {
    return dispatch(HTTP_GET, {
      data: {params: params.filter},
      method: AD_USER_REVIEWS,
      response: false,
      params: {userId: params.userId},
    }).then((response) => {
      commit(AD_USER_REVIEWS, response)
    });
  },

  // @depracated (probably)
  [AD_VIEW_OPEN]: ({commit, dispatch}, {id}) => {
    dispatch(AD_MARKET, {id}).then(() => {
      dispatch(EVENT_OPEN, {
        channel: `market.ads.${id}`,
        group: 'Ad',
        method(ad) {
          if (ad.is_deleted || !ad.is_active) {
            commit(DELETED_AD, id);
          } else {
            dispatch(AD_MARKET, {id});
          }
        },
        event: [
          'AdPlaced',
          'AdActivated',
          'AdDeleted',
          'AdDeactivated',
          'AdLimitChanged',
          'AdPriceChanged',
        ],
      });
    });
  },

  [AD_EVENTS_SUB]: ({commit, dispatch}, {id}) => {
    dispatch(EVENT_OPEN, {
      channel: `market.ads.${id}`,
      group: 'Ad',
      method(ad) {
        if (ad.is_deleted || !ad.is_active) {
          commit(DELETED_AD, id);
        } else {
          dispatch(AD_MARKET, {id});
        }
      },
      event: [
        'AdPlaced',
        'AdActivated',
        'AdDeleted',
        'AdDeactivated',
        'AdLimitChanged',
        'AdPriceChanged',
      ],
    });
  },

  [AD_EVENTS_UNSUB]: ({commit, dispatch}, {id}) => {
    dispatch(EVENT_CLOSE, {channel: `market.ads.${id}`});
  },

  [AD_VIEW_CLOSE]: ({commit, dispatch}, {id}) => {
    dispatch(EVENT_CLOSE, {channel: `market.ads.${id}`});
  },

  [REQUEST_CRYPTO]: ({dispatch}, filters) => {
    return dispatch(AD_ALL).then(data => {
      return data.crypto;
    });
  },
  [REQUEST_COUNTRIES]: ({dispatch}, {country_id} = {}) => {
    return dispatch(AD_ALL).then(data => {
      if (country_id) {
        return data.countries[country_id];
      } else {
        return Object.values(data.countries);
      }
    });
  },
  [REQUEST_CURRENCIES]: ({dispatch}, {country_id} = {}) => {
    return dispatch(AD_ALL).then(data => {
      if (country_id) {
        if (data.countries[country_id]) {
          return Object.values(data.countries[country_id].currencies);
        } else {
          return [];
        }
      } else {
        return Object.values(data.currencies);
      }
    });
  },
  [REQUEST_PAYMENTS]: ({dispatch}, {country_id, currency_id} = {}) => {
    return dispatch(AD_ALL).then(data => {
      if (country_id) {
        if (data.countries[country_id]) {
          if (currency_id) {
            if (data.countries[country_id].currencies[currency_id]) {
              return Object.values(data.countries[country_id].currencies[currency_id].payment_systems);
            }
          } else {
            return Object.values(data.countries[country_id].payment_systems);
          }
        } else {
          return [];
        }
      } else if (currency_id) {
        return Object.values(data.payment_systems).filter(system => system.currencies.find(currency => currency.value === currency_id));
      } else {
        return Object.values(data.payment_systems);
      }
    });
  },
  [REQUEST_BANKS]: ({dispatch}, {country_id, currency_id} = {}) => {
    return dispatch(AD_ALL).then(data => {
      if (!country_id) {
        return Object.values(data.banks);
      } else {
        return dispatch(REQUEST_PAYMENTS, {country_id, currency_id}).then((payments) => {
          if (!payments) {
            return [];
          }
          let payment = payments.find(payment => payment.is_bank === true);
          if (payment && payment.banks) {
            return Object.values(payment.banks).filter(bank => bank.currencies.find(currency => currency.value === currency_id));
          } else {
            return [];
          }
        });
      }
    });
  },

  /**
   * Нежелательно использовать AD_FORM_DATA для фильтрации данных
   * @param type
   * @param types
   * @param filters
   * @returns {Promise}
   */
  [AD_FORM_DATA]: async ({state, commit, dispatch}) => {
    let types = [REQUEST_COUNTRIES, REQUEST_CURRENCIES, REQUEST_PAYMENTS, REQUEST_BANKS, REQUEST_CRYPTO];
    let values = {};
    for (let i = 0; i < types.length; i++) {
      values[types[i]] = await dispatch(types[i], {});
    }
    return values;
  },
  [REQUEST_PRICE]: ({commit, dispatch}, {crypto, fiat}) => {
    return dispatch(HTTP_GET, {
      method: REQUEST_PRICE,
      params: {crypto, fiat},
    });
  },
  [GET_OFFER_TIME]: ({commit, dispatch}) => {
    return dispatch(HTTP_GET, {
      method: GET_OFFER_TIME
    });
  },
};

const mutations = {
  [REQUEST_PRICE]: (state, data) => {
    Vue.set(state, 'autoPrice', data);
  },
  [AD_PRICE_TICKER]: (state, res) => {
    Vue.set(state.priceTickers, res.ticker, {
      price: parseFloat(res.price.toFixed()),
      min_price: parseFloat(res.min_price.toFixed()),
      max_price: parseFloat(res.max_price.toFixed()),
    });

  },
  [AD_LOGOUT_CLEAR]: (state) => {
    state.ads = {};
    state.reviews = [];
    state.adsForDelete = {};
  },
  [AD_UPDATE]: (state, data) => {
    if (data && data.id) { // TODO: check
      if (!data.author) {
        const ad = {
          ...state.ads[data.id]
        }
        ad.is_active = data.is_active;
        ad.is_deleted = data.is_deleted;
        ad.is_sale = data.is_sale;
        ad.max = data.max;
        ad.min = data.min;
        ad.original_max = data.original_max;
        ad.price = data.price;
        ad.turnover = data.turnover;
        ad.requisite_strategy = data.requisite_strategy;
        Vue.set(state.ads, data.id, ad);
      } else {
        Vue.set(state.ads, data.id, data);
      }
    }
  },
  [AD_DELETE]: (state, data) => {
    if (data && data.id && state.ads[data.id]) {
      delete state.ads[data.id];
      if (state.adsForDelete[data.id]) {
        delete state.adsForDelete[data.id];
      }
    }
  },
  [REQUISITE_TYPES]: (state, data) => {
    Vue.set(state, 'requisiteTypes', data);
  },
  [DELETED_AD]: (state, id) => {
    Vue.set(state.adsForDelete, id, id);
  },
  [AD_USER_REVIEWS]: (state, data) => {
    Vue.set(state, 'reviews', data);
  },
  [AD_REQUISITES]: (state, data) => {
    Vue.set(state.ads[data.data.id], 'requisites', data.data.requisites);
  },
  [GET_OFFER_TIME]: (state, data) => {
    Vue.set(state, 'time', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
