<template lang="pug">
  header.header-desktop
    .header-desktop__inner
      .header-desktop__left
        .header-desktop__burger-container.cursor-link(
          v-if="isAuthenticated"
          @click="$emit('switchMobileMenu', true)"
        )
          .header-desktop__burger
        router-link.header-desktop__logo.cursor-link(v-else to="/")
        .header-desktop__title-section.cursor-link(v-if="backButton" @click="goBack()")
          .header-desktop__title-section-arrow
          span {{ backButtonLabel }}
        .header-desktop__title-section(v-else)
          | {{ getPageTitle }}

          .header-desktop-info__block.header-desktop-info__acc(v-if="inMarketComponent")
            SpeedCostSwitcher

      .header-desktop__row(v-if="isAuthenticated")
        component(v-if="getHeaderModule" :is="getHeaderModule")
        .header-desktop__btn(v-if="showAdCreateButton" @click="sendAnalytics('Ad', 'create', false)")
          router-link.header-btn(:to="{name: 'AdCreate', params: {step: 'insert'}}", tag="button") Новое объявление
        .header-desktop__btn(v-if="showClaimCreateButton")
          router-link.header-btn(:to="{name: 'CreateClaim'}", tag="button") Создать претензию
        .header-desktop__btn-group
          SupportPopup
          notifications
          ProfilePopup
      .header-desktop__row(v-else)
        SupportPopup
        router-link.header-btn.header-btn__sign-in(
          v-if="!$route.meta.isAuthorizationPage"
          :to="{ name: 'Login' }"
          tag="button"
        ) Войти на площадку
</template>

<script>
import { mapGetters } from 'vuex';
import SpeedCostSwitcher from '../../components/HeaderModules/SpeedCostSwitcher';
import Notifications from '../../components/HeaderModules/Notifications';
import analytics from '../../components/mixins/analytics';
import ProfilePopup from "@/components/Profile/ProfilePopup.vue";
import SupportPopup from "@/components/HeaderModules/SupportPopup.vue";

export default {
  components: {
    ProfilePopup,
    SpeedCostSwitcher,
    Notifications,
    SupportPopup
  },
  mixins: [analytics],
  data() {
    return {
      headerModule: false,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loading',
      'errorRequest',
      'getRouteData',
      'getProfile',
    ]),
    inAccountComponent() {
      return this.isAuthenticated && this.$route.name === 'Account';
    },
    inMarketComponent() {
      return this.$route.meta.speedCostSwitch;
    },
    getHeaderModule() {
      return this.$route.meta.headerModule ? this.$route.meta.headerModule : false;
    },
    getPageTitle() {
      return this.$route.meta.title ? this.$route.meta.title : '';
    },
    backButton() {
      return this.$route.meta.backButton;
    },
    backButtonLabel() {
      return this.getRouteData.path === this.$route.path
        ? this.getRouteData.title
        : this.$route.meta.backButtonTitle
          ? this.$route.meta.backButtonTitle
          : 'Назад';
    },
    showAdCreateButton() {
      return this.isAuthenticated
        && this.$route.name !== 'AdCreate'
        && this.$route.meta.adCreateButton;
    },
    showClaimCreateButton() {
      return this.isAuthenticated
        && this.$route.name === 'Claims'
        && this.$route.meta.claimCreateButton;
    },
  },
  mounted() {
    this.headerModule = this.$route.meta.headerModule ? this.$route.meta.headerModule : false;
  },
  methods: {
    goBack() {
      if (this.getRouteData.path === this.$route.path && this.getRouteData.analytics) {
        this.sendAnalytics(this.getRouteData.analytics.category, this.getRouteData.analytics.action, false);
      }
      if (this.$route.meta.backRouteName) {
        this.$router.push({name: this.$route.meta.backRouteName});
      } else if (this.getRouteData.backRouteName) {
        this.$router.push({name: this.getRouteData.backRouteName});
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
