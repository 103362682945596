<template lang="pug">
    .can-toggle(v-show="false")
      input#e(v-model="checked", type='checkbox')
      label.mb-0(for='e')
        .can-toggle__switch(data-checked='Цена', data-unchecked='Скорость')
</template>
<script>
import { mapGetters } from 'vuex';
import { ADS_FILTER_SET } from '../../store/actions/markets';

export default {
  data() {
    return {
      checked: false,
    };
  },

  computed: {
    ...mapGetters([
      'getHeaderModule',
    ]),
  },

  watch: {
    //
    checked(data) {
      //
      this.$store.dispatch('HEADER_MODULE_EDIT', {
        module: 'SpeedCostSwitcher',
        param: {currentSort: data ? 'price' : 'speed'},
      });

      //
      this.$store.dispatch(ADS_FILTER_SET, {order: data ? 'price' : 'speed'});
    },
  },

  mounted() {
    this.checked = this.getHeaderModule.SpeedCostSwitcher.currentSort === 'price';
  },

};
</script>

<style scoped>
  .can-toggle__switch {
    cursor: pointer;
  }
</style>
