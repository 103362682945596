import Vue from 'vue';
import {HTTP_GET, HTTP_POST} from "../actions/events";
import {
  ADD_EMERGENCY_WALLET,
  CREATE_EMERGENCY,
  DELETE_EMERGENCY,
  EMERGENCY_LOGOUT_CLEAR,
  EMERGENCY_WITHDRAW,
  GET_EMERGENCY_DATA,
} from "../actions/emergency";

const state = {
  emergencyData: {},
};

const getters = {
  getEmergencyData: state => state.emergencyData,
};

const actions = {
  [EMERGENCY_LOGOUT_CLEAR]: ({commit}) => {
    commit(EMERGENCY_LOGOUT_CLEAR);
  },
  [GET_EMERGENCY_DATA]: ({dispatch, commit}) => {
    return dispatch(HTTP_GET, {
      method: GET_EMERGENCY_DATA,
      response: false
    }).then((response) => {
      commit(GET_EMERGENCY_DATA, response)
    });
  },
  [CREATE_EMERGENCY]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_POST, {
      method: CREATE_EMERGENCY,
      data: data,
      response: false
    }).then((response) => {
      commit(GET_EMERGENCY_DATA, response)
    });
  },
  [ADD_EMERGENCY_WALLET]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_POST, {
      method: ADD_EMERGENCY_WALLET,
      data: data,
      response: false
    }).then((response) => {
      commit(GET_EMERGENCY_DATA, response)
    });
  },
  [DELETE_EMERGENCY]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_POST, {
      method: DELETE_EMERGENCY,
      data: data,
      response: false
    }).then((response) => {
      commit(GET_EMERGENCY_DATA, response)
    });
  },
  [EMERGENCY_WITHDRAW]: ({dispatch, commit}, data) => {
    return dispatch(HTTP_POST, {
      method: EMERGENCY_WITHDRAW,
      data: data,
      response: false
    })
  },
};

const mutations = {
  [EMERGENCY_LOGOUT_CLEAR]: (state) => {
    state.emergencyData = {};
  },
  [GET_EMERGENCY_DATA]: (state, data) => {
    Vue.set(state, 'emergencyData', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
